import React from "react";
import PropTypes from "prop-types";
import styles from "./ListHeader.module.css";

const ListHeader = ({ header, number }) => {
    return (
        <>
            <ol className={styles.listHeaderOl} start={number} >
                <li className={styles.listHeaderOlLi}>{header}</li>
            </ol>
        </>
    );
};

ListHeader.propTypes = {
    header: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
}

export default ListHeader