import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useQuery } from "react-query";

export function useCpnw() {
  const { multiFactorToken } = Auth.useContainer();
  /*
    Sample data:
    [
    {
        "facilityName": "CPNW HEALTHCARE",
        "url": null
    },
    {
        "facilityName": "CPNW MEDICAL CENTER",
        "url": null
    },
    */
  const { data: healthcarePartnersData, refetch: refetchHealthcarePartners } =
    useQuery(
      ["healthcarePartnersGet"],
      async () =>
        await fetch(environment.apiBase + `/cpnw/HealthcarePartners`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
      {
        enabled: false,
      }
    );

  /*
Sample data:
[
    {
        "facilityName": "A.T. STILL UNIVERSITY",
        "businessUnit": "DOCTOR OF OSTEOPATHIC MEDICINE",
        "url": "http://cpnw.org/Missing"
    },
    {
        "facilityName": "A.T. STILL UNIVERSITY",
        "businessUnit": "FAMILY NURSE PRACTIONER",
        "url": "http://cpnw.org/Missing"
    }
]
*/
  const {
    data: participatingProgramsData,
    refetch: refetchParticipatingPrograms,
  } = useQuery(
    ["participatingProgramsGet"],
    async () =>
      await fetch(environment.apiBase + `/cpnw/ParticipatingPrograms`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: false,
    }
  );

  const { data: smsProvidersData, refetch: refetchSMSProviders } = useQuery(
    ["smsProvidersGet"],
    async () =>
      await fetch(environment.apiBase + `/cpnw/SMSProviders`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: false,
    }
  );

  const accessCodeGet = async (accessCode) => {
    return await fetch(environment.apiBase + `/cpnw/accesscode/${accessCode}`, {
      method: "get",
    }).then((res) => res.json());
  };

  const accountRegisterPost = async ({
    accessCode,
    phoneNumber,
    email,
    password,
    mfaPreference,
    selectedSMSCarrier,
  }) => {
    return await fetch(environment.apiBase + `/cpnw/account/register`, {
      method: "POST",
      body: JSON.stringify({
        accessCode,
        phoneNumber,
        email,
        password,
        mfaPreference,
        selectedSMSCarrier,
      }),
    }).then((res) => res.json());
  };

  const accountConfirmEmailGet = async (userId, code) => {
    return await fetch(
      environment.apiBase +
        `/cpnw/account/confirmemail?userId=${userId}&code=${code}`,
      {
        method: "get",
      }
    ).then((res) => res.ok);
  };

  const accountSendCodeGet = async () => {
    return await fetch(environment.apiBase + `/cpnw/account/sendcode`, {
      method: "get",
      headers: {
        "cpnw-mfa-token": multiFactorToken,
      },
    }).then((res) => res.json());
  };

  const accountSendCodePost = async (provider) => {
    return await fetch(environment.apiBase + `/cpnw/account/sendcode`, {
      method: "post",
      headers: {
        "cpnw-mfa-token": multiFactorToken,
      },
      body: JSON.stringify({
        provider: provider,
      }),
    }).then((res) => res.json());
  };

  const accountVerifyCodePost = async (code) => {
    return await fetch(environment.apiBase + `/cpnw/account/verifycode`, {
      method: "post",
      credentials: "include",
      headers: {
        "cpnw-mfa-token": multiFactorToken,
      },
      body: JSON.stringify({
        code: code,
      }),
    }).then((res) => res.json());
  };
  const accountResetPasswordPost = async (payload) => {
    return await fetch(environment.apiBase + `/cpnw/account/ResetPassword`, {
      method: "post",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((res) => res.json());
  };

  return {
    healthcarePartnersData,
    refetchHealthcarePartners,

    participatingProgramsData,
    refetchParticipatingPrograms,

    smsProvidersData,
    refetchSMSProviders,

    accessCodeGet,
    accountRegisterPost,
    accountConfirmEmailGet,
    accountSendCodeGet,
    accountSendCodePost,
    accountVerifyCodePost,
    accountResetPasswordPost,
  };
}
export const CpnwContainer = createContainer(useCpnw);
