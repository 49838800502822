import { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ResetPasswordForm.module.css";
import { InputBox, Button, HeaderSection } from "@cpnw/ui";
/**
 * Interactive component.
 * Form used to reset password on dashboard.
 */
const ResetPasswordForm = ({ tokenError, loading, onResetPassword }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstSubmit, setFirstSubmit] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setFirstSubmit(true);

    const errorEmail = validateEmail(email);
    const errorPassword = validatePassword(password);
    const errorPasswordConfirm = validateConfirmationPassword(
      password,
      confirmPassword
    );
    setErrorEmail(errorEmail);
    setErrorPassword(errorPassword);
    setErrorPasswordConfirm(errorPasswordConfirm);

    if (errorEmail || errorPassword || errorPasswordConfirm) {
      return;
    }

    if (onResetPassword && typeof onResetPassword == "function") {
      onResetPassword({
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      });
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setErrorEmail(null);
      setErrorPassword(null);
      setErrorPasswordConfirm(null);

      setFirstSubmit(false);
    }
  };

  const validateEmail = (email) => {
    let error = null;
    if (!email || email.trim() === "") {
      error = "Email is required";
    } else if (!RG_Email.test(email)) {
      error = "Email is not valid";
    }

    return error;
  };
  const validatePassword = (pass) => {
    let error = null;
    if (!password || password.trim() === "") {
      error = "Password is required";
    } else if (!RG_Password.test(password)) {
      error =
        "At least 10 characters including at least: an uppercase character, a lowercase character, a number and a special character (! @ # $ & * _ ?). ";
    }
    return error;
  };
  const validateConfirmationPassword = (pass, confirmPassword) => {
    let error = null;
    if (pass !== confirmPassword) {
      error = "Confirm password is mismatch";
    }
    return error;
  };
  return (
    <div className={styles.resetPasswordForm}>
      <HeaderSection header={"Reset Password."} />
      <ul>
        {tokenError && (
          <li className={styles.tokenError}>
            {tokenError === "The provided code is empty or expired."
              ? "Invalid Token"
              : tokenError}
          </li>
        )}
      </ul>
      <div className={styles.resetPasswordContainer}>
        <form onSubmit={(e) => onSubmit(e)}>
          <div>
            <InputBox
              ref={emailRef}
              value={email}
              setValue={(e) => {
                setEmail(e.target.value);
                firstSubmit && setErrorEmail(validateEmail(e.target.value));
              }}
              label={"Email"}
            />
            {errorEmail && <div className={styles.errorInfo}>{errorEmail}</div>}
          </div>
          <div>
            <InputBox
              type={"password"}
              ref={passwordRef}
              value={password}
              setValue={(e) => {
                setPassword(e.target.value);
                firstSubmit &&
                  setErrorPassword(validatePassword(e.target.value));
              }}
              label={"Password"}
            />
            {errorPassword && (
              <div className={styles.errorInfo}>{errorPassword}</div>
            )}
          </div>
          <div>
            <InputBox
              type={"password"}
              ref={confirmPasswordRef}
              value={confirmPassword}
              setValue={(e) => {
                setConfirmPassword(e.target.value);
                firstSubmit &&
                  setErrorPasswordConfirm(
                    validateConfirmationPassword(password, e.target.value)
                  );
              }}
              label={"Confirm password"}
            />
            {errorPasswordConfirm && (
              <div className={styles.errorInfo}>{errorPasswordConfirm}</div>
            )}
          </div>
          <Button
            disabled={loading}
            className={styles.submitBtn}
            text={"RESET"}
            onClick={(e) => onSubmit(e)}
          />
        </form>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  /**
   * Function called when Reset Password is clicked
   */
  onResetPassword: PropTypes.func,
};

const RG_Email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const RG_Password =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$&*_]).{10,}$/;
export default ResetPasswordForm;
