import React from "react";
import styles from "./WelcomeInfoBox.module.css";
import classNames from "classnames";

const WelcomeInfoBox = () => {
    return (
        <div className={styles.welcomeInfoBox}>
            <div className={styles.row}>
                <div className={classNames(styles.container, styles.column)}>
                    <h1 className={styles.lineAfter}>Welcome to CPNW</h1>
                    <p>Thank you for visiting our new website. It features an innovative secure app, designed to
                        accelerate student onboarding and streamline the placement of well-prepared, successful
                        healthcare professionals throughout the Pacific Northwest. It’s another example of
                        CPNW’s commitment to becoming the premier clinical placement organization in America.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default WelcomeInfoBox;
