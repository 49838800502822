import { useState, useEffect } from "react";
import useScript from "react-script-hook";

const FreshDeskWidget = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useScript({
    src: "https://assets.freshservice.com/widget/freshwidget.js",
    onload: () => {
      setScriptLoaded(true);
    },
  });

  useEffect(() => {
    if (scriptLoaded) {
      window.FreshWidget.init("", {
        queryString:
          "&widgetType=popup&formTitle=Technical++Support&submitThanks=Your+request+has+been+sent+to+our+Technical+Support+Help+Desk.+%0D%0A%0D%0ATechnical+support+is+staffed+by+one+individual+Monday+through+Friday+from+10+am+to+6pm.+++Responses+are+sent+in+the+order+they+are+received.+%0D%0A%0D%0APlease+allow+24+hours+for+a+response+Monday+through+Friday.+",
        utf8: "✓",
        widgetType: "popup",
        buttonType: "text",
        buttonText: "Technical Support",
        buttonColor: "white",
        buttonBg: "#1292a6",
        alignment: 4,
        offset: "500px",
        formHeight: "500px",
        submitThanks:
          "Your request has been sent to our Technical Support Help Desk. \r\n\r\nTechnical support is staffed by one individual Monday through Friday from 10 am to 6pm. Responses are sent in the order they are received. \r\n\r\nPlease allow 24 hours for a response Monday through Friday. ",
        url: "https://support.cpnw.org",
      });

      return () => {
        // remove the widget

        // FreshWidget.destroy() removes the button and the dialog but it also deletes
        // window.FreshWidget, so the widget can't be used again w/o reloading the
        // script. Work around this by restoring window.FreshWidget.
        const freshWidget = window.FreshWidget;
        freshWidget.destroy();
        window.FreshWidget = freshWidget;
      };
    }
  }, [scriptLoaded]);

  // nothing is rendered via react
  return null;
};

export default FreshDeskWidget;
