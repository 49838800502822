import React from "react";
import PropTypes from "prop-types";
import styles from "./SlideButton.module.css";
import classNames from "classnames";

const SlideButton = ({ label, onClick }) => {
    return (
        <button type="button" onClick={onClick} className={classNames(styles.slideButtonComponent, styles.button)}>{label}</button>
    );
};

SlideButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
};

export default SlideButton;
