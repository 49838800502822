import React from "react";
import InputExample from "../InputExample";
import styles from "./ProgramInfoSection.module.css";
import ListItem from "../ListItem";


const ProgramInfoSection = () => {
    let listItems = [
        "Technical support will contact each designated Facility Account Coordinator by email to schedule online training. Coordinator training may include creation of the Facility Account Coordinator account, instructions on the role and responsibilities of the Facility Account Coordinator, and the User access codes for the Facility Account Coordinator\'s assigned program(s).",
        "It is the responsibility of each Facility and its Facility Account Coordinators to authorize applications to use the Services only to eligible individuals. This is a clinical learning experience onboarding and management application. Individuals not eligible for clinical placement must not be authorized to apply for an account.",
        "ALL Access codes should be provided directly to those eligible to apply for accounts. The access codes should never be published on any public website or document.",
        "Facility Account Coordinators are required to maintain user confidentiality under all FERPA or HIPPA guidelines that apply to their organization.",
        "At no time should a Facility Account Coordinator share their access information.",]


    return (
        <>
            <div className={styles.htmlText}>
                <fieldset className={styles.fieldsetSection}>
                    <legend className={styles.fieldsetLegend}>
                        Program Information
                    </legend>
                    <p>
                        CPNW accounts are organized according to degree/program. You must request an account for each
                        degree/program. Just click the <span className={styles.smallTextColor}>+Add Program </span> link at the bottom of the form to add
                        additional account requests. You must identify an account coordinator and a backup
                        coordinator for each program. Coordinators may manage
                        more than one program. If you don't currently have a backup coordinator, you must specify your primary coordinator as the backup.
                    </p>
                    <small className={styles.smallTextColor}> **All fields are required.</small>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <InputExample name={"Educational Program Name"} />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <InputExample name={"Degree Awarded"} />
                        </div>
                    </div>
                
                {listItems.map((item, index)=>{return<ListItem listContent={item} key={index}/>})}
                
                </fieldset>
                
            </div>
        </>
    )
}

export default ProgramInfoSection;