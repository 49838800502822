import React from 'react';
import styles from './ArchivedAccountPage.module.css';

export default function ArchivedAccountPage(){

return (
    <>
        <div className={styles.archive_banner}>
            <h4>Archived:</h4>
            <p>Your account has been archived. Please contact your program's Clinical Coordinator.</p>
        </div>
    </>
);
};