import React from "react";
import styles from "./Pagination.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";

export const Pagination = ({ page, lastPage, onPageChange, disableNext, disabled }) => {

    let pages = [];
    for (var ctr = 1; ctr <= lastPage; ctr++) {
        pages.push(ctr);
    }

    return (
        <>
            {!disabled ?
                <ul className={classNames(styles.pagination)}>
                    <li>
                        <a onClick={page != 1 ? () => onPageChange(page - 1) : () => {}}>«</a>
                    </li>

                    {pages.map(pageNumber => {
                        return (
                            <li className={classNames(pageNumber == page ? styles.onPage : "")} key={pageNumber}>
                                <a onClick={() => onPageChange(pageNumber)}>{pageNumber}</a>
                            </li>
                        )
                    })}
                    {!disableNext ?
                        <li>
                            <a onClick={page != lastPage ? () => onPageChange(page + 1) : () => {}}>»</a>
                        </li>
                        : ""}
                </ul>
                : ""}
        </>
    )
}

export default Pagination;

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    disableNext: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
}