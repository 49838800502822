import styles from "./DashboardPage.module.css";

const DashboardPage = ({ children }) => {
  return (
    <div className={styles.dashboardPage}>
        <div className={styles.content}>{children}</div>
    </div>
  );
}

export default DashboardPage;
