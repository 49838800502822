import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./SlideInfo.module.css";

const SlideInfo = ({ header, subHeader }) => {
    return (
        <>
            <h2 className={classNames(styles.slideInfoComponent, styles.header)}>{header}</h2>
            <p className={classNames(styles.slideInfoComponent, styles.subHeader)}>{subHeader || ''}</p>
        </>
    );
};

SlideInfo.propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
};
export default SlideInfo;
