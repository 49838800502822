import styles from "./PlacementsPage.module.css";
import PlacementList from "components/PlacementList";
import { StaticRoutes } from "../routes";
import { PageNav, PageHeader, HeaderSection } from "@cpnw/ui";

export function PlacementsPage() {
  const southRegionList = PlacementList({
    items: [
      {
        label: "American Lake VA",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2022-2023_grids/amLakeVA.pdf?638514477635375008",
      },
      {
        label: "VMFH Franciscan Hospice",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/franciscanhospice.pdf",
      },
      {
        label: "VMFH St. Anthony Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stAnthony.pdf?638514477635375008",
      },
      {
        label: "VMFH St. Clare Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stClare.pdf?638514477635375008",
      },
      {
        label: "VMFH St. Elizabeth Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stElizabeth.pdf?638514477635375008",
      },
      {
        label: "VMFH St. Francis Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stFrancis.pdf?638514477635375008",
      },
      {
        label: "VMFH St. Joseph Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stJoseph.pdf?638514477635375008",
      },
      {
        label: "VMFH St. Michael Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/stMichael.pdf?638514477635375008",
      },
      {
        label: "Mason General",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/masonGen.pdf?638514477635375008",
      },
      {
        label: "MultiCare Mary Bridge Children's Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/maryBridge.pdf?638514477635375008",
      },
      {
        label: "MultiCare Adult Day Health",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/mcAdultHealth.pdf?638514477635375008",
      },
      {
        label: "MultiCare Allenmore",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/allenmore.pdf?638514477635375008",
      },
      {
        label: "MultiCare Auburn Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/mcAuburn.pdf?638514477635375008",
      },
      {
        label: "MultiCare Capital Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/mcCapital.pdf?638514477635375008",
      },
      {
        label: "MultiCare Covington",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/mcCovington.pdf?638514477635375008",
      },
      {
        label: "MultiCare Good Samaritan",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/goodSam.pdf?638514477635375008",
      },
      {
        label: "MultiCare Tacoma General",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/tacomaGeneral.pdf?638514477635375008",
      },
      {
        label: "Providence Centralia",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/provCentralia.pdf?638514477635375008",
      },
      {
        label: "Providence St. Peter Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/provStPete.pdf?638514477635375008",
      },
      {
        label: "Western State Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/southRegion/2023-2024_grids/westernState.pdf?638514477635375008",
      },
    ],
    header: "South Region",
  });

  const northRegionList = PlacementList({
    items: [
      {
        label: "EvergreenHealth",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/evergreenHealth.pdf?638514546429008243",
      },
      {
        label: "Harborview Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/harborview.pdf?638514546429008243",
      },
      {
        label: "Harborview Medical Center - AACS",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/harborviewAACS.pdf?638514546429008243",
      },
      {
        label: "MultiCare Navos Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/mcNavos.pdf?638514546429008243",
      },
      {
        label: "VMFH St. Anne Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/stAnne.pdf?638514546429008243",
      },
      {
        label: "Overlake Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/overlake.pdf?638514546429008243",
      },
      {
        label: "Providence Regional Medical Center Everett",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/provEverett.pdf?638514546429008243",
      },
      {
        label: "Providence Regional Medical Center Pacific",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/provEverettPacific.pdf?638514546429008243",
      },
      {
        label: "Seattle Children's Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/seattleChildrens.pdf?638514546429008243",
      },
      {
        label: "Seattle VA Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/seattleVA.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: Ballard",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swBallard.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: Cherry Hill",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swCherryHill.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: Edmonds",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swEdmonds.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: First Hill",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swFirstHill.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: Issaquah",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swIssaquah.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center: Mill Creek",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swMillCreek.pdf?638514546429008243",
      },
      {
        label: "Swedish Medical Center:OR",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/swOR.pdf?638514546429008243",
      },
      {
        label: "Valley Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/vMedicalCenter.pdf?638514546429008243",
      },
      {
        label: "Virginia Mason Franciscan Health",
        src: "https://cpnw.blob.core.windows.net/documents/northRegion/2023-2024_grids/virginiaMason.pdf?638514546429008243",
      },
    ],
    header: "North Region",
  });

  const eastRegionList = PlacementList({
    items: [
      {
        label: "Eastern State Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/easternState.pdf?638514546429008243",
      },
      {
        label: "MultiCare Deaconess Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/deaconess.pdf?638514546429008243",
      },
      {
        label: "MultiCare Valley Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/valleyHospital.pdf?638514546429008243",
      },
      {
        label: "Northwest Specialty Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/nwSpecialty.pdf?638514546429008243",
      },
      {
        label: "Odessa Memorial Healthcare Center",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/odessa.pdf?638514546429008243",
      },
      {
        label: "Providence Holy Family Hospital",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/provHolyFamily.pdf?638514546429008243",
      },
      {
        label: "Providence Sacred Heart Medical Center",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/provSacredHeart.pdf?638514546429008243",
      },
      {
        label: "St. Luke's",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/stLukes.pdf?638514546429008243",
      },
      {
        label: "VA Spokane",
        src: "https://cpnw.blob.core.windows.net/documents/eastRegion/2023-2024_grids/vaSpokane.pdf?638514546429008243",
      },
    ],
    header: "East Region",
  });
  
  return (
    <div className={styles.placementsPage}>
      <PageHeader name="Placements" />
      <div className={styles.contentContainer}>
        <div className={styles.nav}>
          <a className={styles.a} href={StaticRoutes.AboutUs}>
            About Us
          </a>
          <PageNav
            links={[
              {
                name: "Healthcare Partners",
                src: StaticRoutes.AboutUsHealthcarePartners,
              },
              {
                name: "Participating Programs",
                src: StaticRoutes.AboutUsParticipatingPrograms,
              },
              {
                name: "Nursing Placement",
                src: StaticRoutes.AboutUsNursingPlacement,
              },
            ]}
          />
        </div>
        <div className={styles.mainContent}>
          <HeaderSection header="2023 - 2024 Clinical Placements" />
          <div className={styles.row}>
            <div>{southRegionList}</div>
            <div>{northRegionList}</div>
          </div>
          <div className={styles.row}>
            <div>{eastRegionList}</div>
            <div className={styles.gridTut}>
              <h2>The Placement Grid</h2>
              <p>
                <a href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fcpnw.blob.core.windows.net%2Fdocuments%2FdocDownloads%2FStatewide%2FClinicalPlacementGrid.pptx&wdOrigin=BROWSELINK">
                  Click here{" "}
                </a>
                for a tutorial on reading the Placement Grid.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlacementsPage;
