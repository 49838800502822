import React from "react";
import TermHeader from "../TermHeader";
import ListHeader from "../ListHeader";
import ListItem from "../ListItem";
import StepHeader from "../StepHeader";
import styles from "./PrivacyPolicySection.module.css"

const PrivacyPolicySection = () => {
    let protectionLawsContent = [
        "CPNW is a nonprofit organization incorporated in the State of Washington. CPNW’s revenues do not exceed state statutory thresholds for application of privacy protection laws, where they apply. None of CPNW’s revenues are derived from the sale of personal information or personally identifiable information, or any equivalent categories of information.",
        "The following types of state consumer privacy protection laws do not apply to CPNW:",
        "State consumer privacy protection laws which do not apply to nonprofit organizations.",
        "State consumer privacy protection laws apply to organizations which meet or exceed specified revenue thresholds, as those thresholds are specified of the date of this Privacy Policy.",
        "State consumer privacy protection laws which apply to organizations which sell personal information, personally identifiable information, or equivalent categories of information.",
    ]

    let piiDisclosureContent = [
        "CPNW collects and uses PII subject to this Privacy Policy as follows:",
        "CPNW collects Subscriber PII from the Subscriber as uploaded by the Subscriber and from third parties relevant to the Subscriber’s clinical placement. CPNW may use and disclose Subscriber PII for purposes authorized by the Subscriber’s Consent. Please see our Consent Form.",
        "CPNW collects personally identifiable information in the course of User registration or in communications with CPNW about account administration (“Account Administration PII”). CPNW may use and disclose Account Administration PII to manage and administer the Services, and to fulfill CPNW’s legal responsibilities.",
        "CPNW may track and collect information about to administer the User’s accounta User’s transactions or activities involving the Services (“Account Activity PII”). Users may not opt-out of such tracking as it may be used to ensure the security and proper functioning of the Services and the security of PII. CPNW may use and disclose Account Activity PII to manage and administer the Services, and to fulfill CPNW’s legal responsibilities.",
        "CPNW will disclose PII when required to do so by law, for example, in response to a court order or a subpoena or other legal obligation, in response to a law enforcement agency’s request, or in special cases when we have reason to believe that disclosing this information is necessary to identify, contact, or bring legal action against someone who may be causing injury to or interference (either intentionally or unintentionally) to another party.",
        "CPNW will not use or disclose any PII for any marketing purposes.",
        "CPNW will not sell any PII or otherwise disclose PII to a third party for any valuable monetary or non-monetary consideration. The disclosure of PII as part of the Services as provided in the User’s consent is not a sale of that PII.",
    ]
    let piiAmendmentContent = [
        "All Users have direct access to their account registration information through their accounts and are responsible for entering, correcting and updating their account registration information as appropriate. Certain Account Administration PII, such as User email, or change in program of study may be amended by request to CPNW at support@cpnw.org, subject to CPNW’s verification of the amended information with the applicable Facility. Records of amended Account Administration PII may be maintained by CPNW if reasonably necessary for administration of the Services or to ensure CPNW is able to fulfill legal obligations.",
        "Account Activity PII is not available for User access or amendment.",
        "Subscribers have direct access to their Subscriber PII through their accounts and are responsible for entering, correcting and updating their Subscriber PII as appropriate. When updating PII, the User must include only true, accurate, and complete information, as the amended information may be relied upon for clinical placement purposes. Users may not amend Subscriber PII received from third-party sources. In the event a Subscriber wishes to amend Subscriber PII the Subscriber must contact the information source and/or the Facility/ies which have received it and request that they amend it. Records of amended Subscriber PII may be maintained by CPNW if reasonably necessary for administration of the Services or to ensure CPNW is able to fulfill legal obligations.",
    ]
    let piiDisposalContent = [
        "CPNW may maintain PII subject to this Privacy Policy as reasonably necessary for CPNW to provide the Services and ensure CPNW is able to fulfill its legal obligations. PII from inactive accounts may be archived and maintained by CPNW to ensure it is able to fulfill its legal obligations.",
        "Users may not request that their PII be deleted or destroyed. CPNW will ensure the appropriate destruction of PII upon CPNW’s reasonable determination retention is no longer appropriate.",
    ]

    const between = (x, min, max) => {
        return x >= min && x <= max;
    }


    return (
        <div>
            <TermHeader header="Privacy Policy" />
            <p>CPNW is committed to honoring the privacy of individuals who use the Services. This Privacy Policy is therefore intended to make you aware of the ways your personally identifiable information collected through the Services is used and to explain to you how we guard your information. We hope this policy will help you make informed decisions about sharing information with us. CPNW may receive, maintain, use and disclose Subscriber PII and Account Administration PII (collectively, “PII”) as follows.</p>

            <ListHeader number={1} header={"Legal Status of PII."} />
            <p className={styles.subHeadParagraph}>CPNW provides the Services to Subscribers as individuals, and obtains Account Administration PII about Facility Account Coordinators solely for purposes of creating and administering their account on behalf of the Facility in support of the Services. CPNW will protect and administer PII in accordance with its Terms of Use and Privacy Policy.</p>
            <ListHeader number={2} header={"Application of State Consumer Privacy Protection Laws."} />
            {protectionLawsContent.map((item, index) => {
                if (index == 0) {
                    return (<p key={index} className={styles.subHeadParagraph}>{item}</p>)
                }
                if (index == 1) {
                    return (<p key={index} className={styles.subSubParagraph}>{item}</p>)
                }
                return (<ul><ListItem listContent={item} key={index} /></ul>)
            })}
            <ListHeader number={3} header={"Use and Disclosure of PII"} />
            {piiDisclosureContent.map((item, index) => {
                if (index == 0) {
                    return (<p key={index} className={styles.subHeadParagraph}>{item}</p>)
                }
                return (<ul><ListItem key={index} listContent={item} /></ul>)
            })}
            <ListHeader number={4} header={"Access to and Amendment of PII"} />
            <div className={styles.listAlign}>
                {piiAmendmentContent.map((item, index) => {
                    if (index == 0) {
                        return (<div className={styles.liAlignment}><ListItem key={index} listContent={item} /></div>)
                    }
                    return (<ListItem key={index} listContent={item} />)
                })}
            </div>
            <ListHeader number={5} header={"Safegaurds for Protection of PII"} />
            <p className={styles.subHeadParagraph}>CPNW uses reasonable and appropriate safeguards to protect PII obtained subject to this Privacy Policy. In particular, all information provided through the Services using forms or secure messaging is encrypted during transmission and at rest. All activity on the CPNW Web application is monitored and recorded to safeguard against misuse or unauthorized activity. CPNW has implemented policies and procedures to restrict access to PII from the Services to authorized individuals, and the servers used to maintain and process PII are operated by third parties under contract to ensure they are kept in a secure environment. CPNW may change the safeguards implemented from time to time as necessary or appropriate to respond to changes in technology, operational needs, security threats, and vulnerabilities, legal requirements, and other factors affecting the protection of personal information.</p>
            <ListHeader number={6} header={"Retention and Disposal of Pii"} />
            {piiDisposalContent.map((item, index) => {
                if (index == 0) {
                    return (<p key={index} className={styles.subHeadParagraph}>{item}</p>)
                }
                if (index == 1) {
                    return (<p key={index} className={styles.subSubParagraph}>{item}</p>)
                }
            })}

        </div>
    )
}

export default PrivacyPolicySection;