import { useEffect } from "react";
import NursingPlacementItem from "../../../../components/NursingPlacementItem";
import map from "../../../../assets/map.png"; // Uploaded from CPNW site
import styles from "./NursingPlacementPage.module.css";
import { StaticRoutes } from "../../../routes";

const NursingPlacement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.NursingPlacement}>
      <h1>Nursing Placement</h1>
      <div className={styles.item}>
        <NursingPlacementItem
          firstParagraph={
            <p>
              CPNW offers clinical placement management and tracking services
              for nationally-accredited nursing programs throughout the Pacific
              Northwest.
            </p>
          }
          secondParagraph={
            <p>
              We welcome nurse educators to contact us about eligibility
              requirements for nursing program participation at
              support@cpnw.org.
            </p>
          }
          thirdParagraph={
            <p>
              <strong>
                <a href={StaticRoutes.PlacementsPage}>Click Here</a>
              </strong>{" "}
              for placement schedules of member programs.
            </p>
          }
          img={map}
        />
      </div>
    </div>
  );
};

export default NursingPlacement;
