export const UserRegState = {
    /// <summary> Registration Process email confirm. </summary>
    AwaitingEmailConfirmation: 0,
    /// <summary> Send email to student to that effect. </summary>
    AwaitingBioCompletion: 1,
    /// <summary> User hasn't consented yet. </summary>
    AwaitingConsent: 2,
    /// <summary> Waiting for facility coordinator to approve. </summary>
    PendingApproval: 3,
    /// <summary> Returned for some reason. </summary>
    FixBio: 4,
    /// <summary> Complete rejected - user needs to be deleted. </summary>
    Rejected: 5,
    /// <summary> Registration complete and student is approved. </summary>
    Approved: 6,
    /// <summary> Student has been inactivated. </summary>
    Inactive: 7,
    /// <summary> Waiting for student to pay through PayPal. </summary>
    AwaitingSelfPay: 8,
    Archived: 9
  };