import React from "react";
import PropTypes from "prop-types";
import PlacementItem from "../PlacementItem";
import styles from "./PlacementList.module.css";

export default function PlacementList({ items, header }) {
  return (
    <div className={styles.placementListComponent}>
      <h1>{header}</h1>
      <ul>
        {items.map((item, index) => (
            <PlacementItem key={index} label={item.label} src={item.src} />
        ))}
      </ul>
    </div>
  );
};

PlacementList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ).isRequired,
  header: PropTypes.string.isRequired,
};