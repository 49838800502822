import React from "react";
import PropTypes from "prop-types";
import styles from "./InputExample.module.css";


const InputExample = ({ name }) => {
    return (
        <div className={styles.container}>
            <input type="text" placeholder={name} className={styles.disabled} disabled={true} />
        </div>
    )
}

InputExample.propTypes = {
    name: PropTypes.string.isRequired
}

export default InputExample;