import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { StaticRoutes } from "pages/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery } from "react-query";

export function useAccount() {
  const { isLoggedIn, setToken, setMultiFactorToken } = Auth.useContainer();
  const history = useHistory();

  const login = async ({ email, password }) => {
    return await fetch(environment.apiBase + `/cpnw/account/login`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        let { token, next } = res;

        setToken(null);
        setMultiFactorToken(null);

        if (token) {
          if (next == "code") {
            setMultiFactorToken(token);
            history.push(StaticRoutes.AccountSendCode);
          } else {
            setToken(token);

            if (!window.location.host.includes("localhost")) {
              if (next == "ConfirmEmail") {
                history.push(StaticRoutes.AccountConfirmEmail);
              }
              else{
                window.location.href = environment.dashboardAppUrl;
              }
            } else {
              localStorage.setItem("cpnw-token", token);

              if (next == "ConfirmEmail") {
                history.push(StaticRoutes.AccountConfirmEmail);
              }
            }
          }

          return true;
        }
        return false;
      });
  };

  const logout = async () => {
    return await fetch(environment.apiBase + `/cpnw/account/logout`, {
      method: "get",
      credentials: "include",
    }).then((res) => {
      setToken(null);
      setMultiFactorToken(null);
      localStorage.removeItem("cpnw-token");
      window.location.href = environment.homeUrl;
    });
  };

  const forgotPassword = async (email) => {
    return await fetch(environment.apiBase + `/cpnw/account/ForgotPassword`, {
      method: "Post",
      body: JSON.stringify(email),
    });
  };

  const requestConfirmationEmail = async () => {
    return await await makeAuthedRequest(`/cpnw/account/ConfirmationEmail`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return true
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    });
  };

  const { data: meData, refetch: refetchMeData } = useQuery(
    ["MeGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/me`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: isLoggedIn,
    }
  );

  return {
    login,
    logout,
    forgotPassword,
    meData,
    requestConfirmationEmail,
  };
}
export const AccountContainer = createContainer(useAccount);
