import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StaticRoutes } from "pages/routes";
import { Auth } from "auth";
import { CpnwContainer } from "hooks/useCpnw";
import { Button, SelectBox } from "@cpnw/ui";
import Preloader from "../../images/preloader.gif";
import styles from "./SendCodePage.module.css";

export function SendCodePage() {
  const history = useHistory();
  const { multiFactorToken } = Auth.useContainer();

  const { accountSendCodeGet, accountSendCodePost } =
    CpnwContainer.useContainer();
  
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!multiFactorToken) window.location.href = StaticRoutes.AccountLogin;

    accountSendCodeGet().then((data) => {
      if (data && data.MFAPreference) {
        setProvider(data.MFAPreference);
      } else setProvider("Email");

      setLoading(false);
    });
  }, []);

  const onSendCode = async () => {
    setLoading(true);

    let result = null;
    try {
      result = await accountSendCodePost(provider);
    } catch (error) {}

    if (result && result.success) {
      history.push(StaticRoutes.AccountVerifyCode);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className={styles.container}>
      {loading && !provider ? (
        <img
          className={styles.loading}
          src={Preloader}
          height={35}
          width={35}
        />
      ) : !error ? (
        <>
          <h2>Send Authentication Code</h2>
          <label>
            Select where you would like the authentication code sent.
          </label>
          <SelectBox
            id="multiFactor"
            value={provider}
            onChange={(e) => setProvider(e)}
            options={options}
            disabled={loading}
          />
          <div>
            <Button
              className={styles.submit}
              text={loading && provider ? "SUBMITTING ..." : "SUBMIT"}
              onClick={() => onSendCode()}
              disabled={loading}
            />
          </div>
        </>
      ) : (
        <>
          <h1>Error</h1>
          <h5>An error occurred while processing your request.</h5>
        </>
      )}
    </div>
  );
}
const options = ["Email", "Text Message"];

export default SendCodePage;
