import React from "react";
import PropTypes from "prop-types";
import styles from "./StepHeader.module.css";

const StepHeader = ({ stepNum, header }) => {

    return (
        <p className={styles.stepHeadStyle}>Step {stepNum}: {header} </p>
    )
}

StepHeader.propTypes = {
    stepNum: PropTypes.number.isRequired,
    header: PropTypes.string.isRequired
}

export default StepHeader;