import { useEffect } from "react";
import styles from "./Healthcare.module.css";
import fa_handshake_o from "../../../../assets/fa-handshake-o-AA9B73.svg";

const Healthcare = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.healthCare}>
      <h1>Become A Healthcare Partner</h1>
      <div className={styles.description}>
        <div>
          <p>
            Clinical Placements Northwest seeks like-minded healthcare partners.
            Partnership entails a commitment to actively participate in our work
            as well as a financial commitment to support the organization.
          </p>
          <p>
            We invite and encourage interested organizations to contact us for a
            conversation. Please use the Get In Touch form below and we'll
            schedule a time to talk.
          </p>
        </div>
        <div className={styles.imgContainer}>
          <img src={fa_handshake_o} width={131} height={112} />
        </div>
      </div>
    </div>
  );
};

export default Healthcare;
