import React, { useState, useEffect, useRef } from 'react';
import styles from "./Carousel.module.css";
import classNames from "classnames";
import SlideButton from '../../components/SlideButton';
import SlideInfo from '../../components/SlideInfo';

const Carousel = ({ slides }) => {
  const settings = {
    interval: 10 * 1000,
  }
  const [current, setCurrent] = useState(0);
  const imageSlides = useRef([]);
  const bubbles = useRef([]);
  const intervalRef = useRef(null);

  let isClick = false;

  const nextImage = () => {
    let newIndex = current === slides.length - 1 ? 0 : current + 1;
    goToImage(newIndex);
  };

  const prevImage = () => {
    let newIndex = current === 0 ? slides.length - 1 : current - 1;
    goToImage(newIndex);
  };

  const jumpImage = (e) => {
    isClick = true;
    let jumpIndex = parseInt(e.target.id);
    goToImage(jumpIndex);
  };

  const goToImage = (index) => {
    if (index === current) return;
    if (intervalRef.current) clearInterval(intervalRef.current);
    let direction = index > current ? "left" : isClick ? "right" : "left";
    imageSlides.current[current].className = classNames(styles.slideImage, (direction == 'left' ? styles.leftOut : styles.rightOut));
    imageSlides.current[index].className = classNames(styles.slideImage, (direction == 'left' ? styles.leftIn : styles.rightIn));

    isClick = false;
    updateBubbles(index);
    setCurrent(index);
    startAutoplay();
  };

  const updateBubbles = (highlight) => {
    bubbles.current[current].style.borderColor = "gray";
    bubbles.current[current].style.backgroundColor = "gray";
    bubbles.current[highlight].style.borderColor = "white";
    bubbles.current[highlight].style.backgroundColor = "white";
  };

  useEffect(() => {
    bubbles.current[current].style.borderColor = "white";
    bubbles.current[current].style.backgroundColor = "white";

    startAutoplay();
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [current]);

  const startAutoplay = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      nextImage();
    }, settings.interval);
  };

  return (
    <div className={styles.galleryContainer}>
      {/* <span className={styles.buttonPrev} onClick={prevImage}>chevron_left</span>
      <span className={styles.buttonNext} onClick={nextImage}>chevron_right</span> */}
      <div className={styles.galleryTrack}>
        {slides.map((slide, index) => (
          <div
            key={index}
            ref={(el) => (imageSlides.current[index] = el)}
            className={styles.slideImage}
            style={{
              position: 'absolute',
              overflow: 'hidden',
              height: 100 + '%',
              ...(index === 0 ? { transform: "translateX(0%)" } : { transform: "translateX(100%)" })
            }}
          >
            <div className={classNames(styles.slideContent)} style={{ position: 'absolute' }}>
              <SlideInfo header={slide.header} subHeader={slide.subHeader} />
              <SlideButton label={slide.linkLabel} onClick={() => { window.location.pathname = slide.link; }} />
            </div>
            <ImageComponent src={slide.backgroundImage} height={window.innerHeight} width={window.innerWidth}/>
          </div>
        ))}
      </div>
      <div className={styles.galleryFooter}>
        {slides.map((slide, index) => (
          <div
            key={index}
            ref={(el) => (bubbles.current[index] = el)}
            className={styles.bubbleOuter}
            onClick={jumpImage}
            id={index}
          >
            <div className={styles.bubbleInner} id={index}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

function ImageComponent({ src, height, width }) {
  const imgRef = useRef(null);

  useEffect(() => {
    const centerY = (image) => {
      const img = imgRef.current;
      img.style.top = `${(height - img.clientHeight) / 2}px`;
    };

    const centerX = (image) => {
      const img = imgRef.current;
      img.style.left = `${(width - img.clientWidth) / 2}px`;
    };

    const center = (image) => {
      centerY(image);
      centerX(image);
    };

    const aspectRatio = (image) => {
      if (!image.naturalHeight && !image.naturalWidth) {
        const img = new Image();
        img.src = image.src;
        image.naturalHeight = img.height;
        image.naturalWidth = img.width;
      }
      return image.naturalHeight / image.naturalWidth;
    };

    const scale = (image, imageAspectRatio) => {
      imageAspectRatio = imageAspectRatio || aspectRatio(image);
      const containerAspectRatio = height / width;
      const img = imgRef.current;

      if (containerAspectRatio > imageAspectRatio) {
        img.style.height = `${height}px`;
        img.style.width = `${height / imageAspectRatio}px`;
      } else {
        img.style.height = `${width * imageAspectRatio}px`;
        img.style.width = `${width}px`;
      }
    };

    const img = new Image();
    img.src = src;
    img.onload = () => {
      center(img);
      scale(img);
    };
  }, [src, height, width]);

  return <img ref={imgRef} src={src}/>;
}

export default Carousel;
