import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';
import PropTypes from "prop-types"
import styles from "./ProgramList.module.css";
import { SearchBar, ProgramLink } from "@cpnw/ui";
import Pagination from '../../components/Pagination';

export default function ProgramList({programs}){
    const [programSearchResults, setProgramSearchResults] = useState([]);
    const [dropdownAtIndex, setDropdownAtIndex] = useState();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationPageAmmount, setPaginationPageAmmount] = useState(0);

    const handleSearch = useCallback((value) => {
        const results = programs.filter(item => item.school.toLowerCase().includes(value.toLowerCase()));
        setProgramSearchResults(results);

        // closes dropdown/resets dropdown
        setDropdownAtIndex(null);

        //reset current page when user types in search bar
        setCurrentPage(1);
        // set pagination page ammout based on search results, this includes the initial mount
        setPaginationPageAmmount(Math.ceil(results.length/10));

    },[programs]);

    useEffect(()=>{
        if (programs) {
            setProgramSearchResults(programs);
            // closes dropdown/resets dropdown
            setDropdownAtIndex(null);

            //reset current page when user types in search bar
            setCurrentPage(1);
            // set pagination page ammout based on search results, this includes the initial mount
            setPaginationPageAmmount(Math.ceil(programs.length / 10));
        }
    },[programs])

    //Link Dropdown
    const toggleDropdown = useCallback((index) => {
        //Callback was added to allow for the onclick dropdown to only occur on the click of the school(link) prop.
        if(index === dropdownAtIndex){
            //if the clicked index = current index then set state to null (dropdown closed)
            setDropdownAtIndex(null)
        } else{
            //if the clicked index != current index then set state to new index
            setDropdownAtIndex(index)
        }
    }, [dropdownAtIndex]);

    //Pagionation Page Change
    const handlePageChange = (e) => {
        setCurrentPage(e);
        toggleDropdown(null);
    }

return (
    <div className={styles.program_list_wrapper}>
        <div className={styles.program_search_wrapper}>
            <SearchBar placeHolder='Search Programs' onInput={handleSearch}/>
        </div>
            {/* Mapping over the search results to display a list of all items */}
                {/* The slice values include the first index, and the last index of the current items being shown */}
                {/* Limits 10 items per page */}
            {programSearchResults.length > 0 && programSearchResults.slice( ((currentPage - 1) *10), (((currentPage - 1) * 10) +10) ).map((item, index) => (
                <div key={index} className={styles.list_container} >
                        {/* The isOpened State is being managed by the current index state. */}
                        {/* if the dropdownAtIndex index matches the current index, the isOpened State is true resulting in the dropdown being opened */}
                            {/* if the dropdownAtIndex index does not match the index, the state is false resulting in the dropdown being closed */}
                            {/* This is done so that only one dropdown can be opened at a time. */}
                    <ProgramLink school={item.school} programs={item.programs} isOpened={dropdownAtIndex === index} onClickProp={() => toggleDropdown(index)}/>
                </div>
            ))}
            {/* If no results upon search */}
            {programSearchResults.length === 0 ? <div className={styles.list_container}><p>No matching results could be found.</p></div>: ""}

        <div className={styles.pagination_wrapper}>
            <Pagination page={currentPage} lastPage={paginationPageAmmount} onPageChange={handlePageChange} disableNext={currentPage === paginationPageAmmount} disabled={paginationPageAmmount<2} />
        </div>
    </div>
);
};

ProgramList.propTypes = {
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        school: PropTypes.string.isRequired,
        programs: PropTypes.array.isRequired,
      })
    ).isRequired
};