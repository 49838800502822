import React, { useState, useMemo, useEffect } from "react";
import styles from "./RegisterPage.module.css";
import classNames from "classnames";
import RegisterForm from "../../components/RegisterForm";
import { CpnwContainer } from "hooks/useCpnw";
import { HeaderSection, StepItem } from "@cpnw/ui";
import { StaticRoutes } from "../routes";

export function RegisterPage() {
  const { accountRegisterPost, accessCodeGet, smsProvidersData, refetchSMSProviders } =
    CpnwContainer.useContainer();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [facilityAccess, setFacilityAccess] = useState(null);

  useEffect(()=>{
    refetchSMSProviders()
  },[])

  const smsProviders = useMemo(() => {
    if (smsProvidersData) return smsProvidersData.map((m) => m.carrier);
    else return [];
  }, [smsProvidersData]);

  const onGetFacilityAccess = async (accessCode) => {
    setErrors({});
    setLoading(true);
    const facilityAccess = await accessCodeGet(accessCode);
    setLoading(false);

    if (facilityAccess && facilityAccess.accessType) {
      setFacilityAccess(facilityAccess);
    } else {
      setFacilityAccess(null);
      setErrors({
        facilityAccess: "Access Code is not valid - contact your organization.",
      });
    }
  };

  const onRegister = async (data) => {
    setErrors({});

    setLoading(true);
    let result = await accountRegisterPost(data);

    if (result && result.Id)
      window.location.href = StaticRoutes.ConfirmEmailPending;
    else {
      if (
        ["Username already exists", "Email already exists"].some(
          (m) => m == result?.error?.message
        )
      )
        setErrors({ email: "Already used." });
      else {
        //TODO: toast message
      }
      setLoading(false);
    }
  };

  return (
    <section className={styles.registerPage}>
      <HeaderSection header={"Register"} />
      <div className={styles.row}>
        <div className={classNames(styles.column, styles.firstColumn)}>
          <h3 className={styles.h3}>Getting Started</h3>
          <h5 className={styles.h5}>
            <a href="https://cpnw.blob.core.windows.net/documents/docDownloads/Statewide/regInstructions.pdf?638537475462634264">CLICK HERE TO DOWNLOAD STEP BY STEP INSTRUCTIONS BEFORE YOU BEGIN.</a>
          </h5>
          <p>
            We recommend using a desktop or laptop computer for your
            registration. Older smartphones and tablets may cause errors.
          </p>
          <div className="register-process" style={{ paddingLeft: 25 + "px" }}>
            <ol className={styles.number}>
              <StepItem
                step={1}
                header="Enter the access code provided by your organization."
                info="Enter it exactly. The code connects you with your program of study and school."
              />
              <StepItem
                step={2}
                header="Enter your email address, which will be your account username."
                info="Once confirmed, it cannot be changed."
              />
              <StepItem
                step={3}
                header="Create and confirm your password."
                info="It must be a minimum of 10 characters and include at least:"
                list={[
                  "one capital letter,",
                  "one lower-case letter,",
                  "one number,",
                  "one special character (! @ # $ & * _ ?).",
                ]}
              />
              <StepItem
                step={4}
                header="Set up your two-step authentication preferences."
                info="You will be required to use two-step authentication every time you login."
              />
              <StepItem
                step={5}
                header="For printable registration instructions,"
                info="download step-by-step instructions"
                infoLink={{
                  url: "https://cpnw.blob.core.windows.net/documents/docDownloads/Statewide/regInstructions.pdf?638477018911319440",
                  text: "here.→",
                }}
              />
            </ol>
          </div>
        </div>
        <div className={styles.column}>
          <RegisterForm
            loading={loading}
            smsProviders={smsProviders}
            facilityAccess={facilityAccess}
            onVerifyAccessCode={(accessCode) => onGetFacilityAccess(accessCode)}
            onSubmit={(data) => onRegister(data)}
            errorResponse={{
              facilityAccess: errors.facilityAccess,
              email: errors.email,
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default RegisterPage;
