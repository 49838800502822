import React from "react";
import styles from "./UseSection.module.css";
import TermHeader from "../TermHeader";
import ListHeader from "../ListHeader";
import ListItem from "../ListItem";
import StepHeader from "../StepHeader";
import RequestExampleSection from "../RequestExampleSection";
import ProgramInfoSection from "../ProgramInfoSection";
import classNames from "classnames";

const UseSection = () => {
    let descriptionSectionBullets = [
        "Clinical Passport documents, instructions to be completed and submitted for verification.",
        "Health and safety requirements unique to each clinical site.",
        "Training modules required by the affiliated clinical locations in advance of a clinical learning experience.",
        "A record of successfully completed training modules that can be downloaded in PDF format as proof that training requirements have been met.",
        "Bi-monthly screening of two federal excluded provider lists. Each Facility will have access to a bi-monthly check of all students and faculty. Screening against these lists are federal and state requirements. Healthcare providers are prohibited from employing or contracting with excluded persons. Those that do are subject to significant fines and will not be paid by government healthcare programs for any services provided directly or indirectly by excluded individuals or organization. As of the date of these Terms of Use the data sources used are:",
        "Office of Inspector General (“OIG”) list of Excluded Individuals and Entities (“LEIE”).",
        "General Services Administration (“GSA”) System for Award Management (“SAM”).",
        "User administrative interface for Facility Account Coordinators. This interface will include:",
        "Access to an account review, approval, deactivate, and archive interface. Coordinators must verify that User requests are affiliated with their organization and eligible to open an Account.",
        "Access to limited identifying information to confirm request is accurate and legitimate. Information must match registration/employment records at the affiliated Facility approving accounts.",
        "Access to reports of compliance requirement completion for each program in the applicable Facility.",
        "Access to an on-demand bi-monthly report that checks students/faculty against the OIG and SAM excluded providers\’ lists.",
    ]

    let userRegistrationBullets = [
        "An eligible individual (“Applicant”) must obtain a registration access code from the CPNW affiliated Facility where their account application will be made.",
        "The applicant must identify their user type and the program/unit they will be part of at the affiliated Facility."
    ]

    let stepOneBullets = [
        "The applicant accesses the CPNW application webpage by going to www.cpnw.org and clicking on the registration link at the top of the webpage.",
        "The applicant will be redirected to the registration webpage and will start the application process by inputting their access code in the registration form.",
        "The applicant will tab out of the access code field and verify they have input the correct code by viewing the registration message above the access code input box. The message should state Facility, program/unit, and User type. It will also state the User fee, if any, that will be collected as the final step in the process. If any information is incorrect, the User should contact their Facility for the correct code.",
        "The applicant completes the information required on the registration webpage, including a valid email and a password meeting CPNW requirements. This email address and password will serve as the account login. (The email address used during registration will be the permanent username and cannot be changed after registration except by special request to CPNW from the Facility Account Coordinator. If an incorrect email is used, the Facility Account Coordinator must decline the request and the applicant must start the registration process from the beginning.)",
        "The applicant also submits a secondary text-phone or email for confirmation of the request. An account authentication code will be transmitted to text-phone or email. At every login the User will be required to request and input an authentication code as a second step in the login process.",
        "The applicant must then submit their information, thus creating their username and password.",
        "After creation of their username and password, the applicant must verify their email by accessing a link sent to them at the email used on the registration form.",
    ]

    let stepTwoBullets = [
        "After confirmation of their email, the applicant will be directed to the login form at www. cpnw.org. The applicant must log in using the just-created username and password and the two-step authentication. Every login will require the two-step process.",
        "Step 1. Enter username and password",
        "Step 2. Enter verification code",
        "After login, the applicant must review and accept the User consent form. Once accepted, the applicant will be directed to the User profile page.",
        "The applicant must complete the required biographical and contact fields. ALL INFORMATION SUBMITTED MUST BE TRUE, CORRECT, AND COMPLETE.",
        "CPNW will transmit the account request to the Facility associated with the applicant for verification of identity.",
        "A Facility Account Coordinator must confirm the applicant\’s identification information and user type. If the Facility Account Coordinator does not confirm all information, the application will not be processed further and the applicant is notified electronically. Applicants can correct their identification information. This process will repeat until the Facility Account Coordinator confirms all information.",
        "If an applicant has used the wrong access code, school, or email address, the Facility Account Coordinator must decline the application. Selecting the decline option deletes the entire incorrect application record.",
        "When all information is complete, CPNW will transmit an email to the applicant that the account has been approved. The applicant, who is now a Subscriber and User, may then sign in to the Services with the username and password created in the account application process.",
        "If an applicant is a self-pay user, the account approval message will contain a link for electronic payment. Following payment, the applicant is a Subscriber and may then login to the Services with the username and password created in the account application process.",
    ]

    let userSusBullets = [
        "A User\’s access privileges may be modified or suspended by CPNW in the event of a security incident or emergency, or proven or suspected material violation of the Terms of Use and Privacy Policy by the User.",
        "A User\’s access privileges shall be suspended immediately if the User leaves the Facility for any reason. It is the responsibility of the Facility Account Coordinator to maintain security by suspending access when Users are not actively attending school or employed by the Facility.",
        "A User account may be terminated upon written request to CPNW from the User.",
        "If CPNW reasonably determines that there has been a material violation of the Terms of Use and Privacy Policy by the User with notice to the User.",

    ]

    let facilityAccountSusBullets = [
        "A Facility’s access privileges will be suspended in the event there is no activity on the Facility’s account for twelve months or more, with notice to the primary contact at the Facility.",
        "A Facility’s access privileges will be suspended in the event CPNW reasonably determines that there has been a material violation of the Terms of Use and Privacy Policy by the Facility, or one of its Facility Account Coordinators, with notice to the primary contact at the Facility.",
        "A Facility account can be terminated in the event there is no activity on the Facility’s account for twelve months or more, with notice to the primary contact for the Facility.",
        "A Facility account can be terminated in the event CPNW reasonably determines that there has been a material violation of the Terms of Use and Privacy by the Facility or one of its Users, with notice to the primary contact for the Facility.",
    ]

    let informedUserContent = [
        "Please read these Terms of Use and all other applicable policies carefully. We need your help in making the Services a safe and effective tool, and protecting them against misuse. Your violation of these Terms of Use or other applicable policies may be grounds for suspension or termination of your account.",
        "If you are a Facility Account Coordinator, or if you are a using a Facility’s information systems to access the Services, you may be subject to additional obligations and requirements under the Facility’s policies. It is your responsibility to know about and comply with any such policies.",
    ]

    let correctInformationContent = [
        "Subscribers are a primary source of the information used to provide the Services. Facilities will depend upon the information the Subscriber provides and otherwise makes available or confirms. Each User, you must provide true, accurate, and complete information when registering for an account, and each Subscriber must provide true, accurate and complete information which may be used to make clinical placement decisions or provide you with the Services. If you are a Facility Account Coordinator, you must confirm the truth, accuracy, and completeness of the information you review on behalf of your Facility. It is your responsibility to confirm the Subscriber has submitted the correct name, student/faculty/employee ID, user type, and program of study.",
        "CPNW will not verify and is not responsible for the truth, accuracy, and completeness of any information provided for purposes of the Services. It is the responsibility of the individual Users to accurately supply the required information. A failure to provide true, accurate, or complete information, or a false confirmation of information, may be considered a fraud in the clinical placement process, and result in loss of Subscriber placement, access to the Services, or other negative consequences.",
    ]

    let maintainAndProtectContent = [
        "The Services are intended to be accessible and all content available through all standard commercial Web browsers operating on standard personal computers, through standard commercially-available Internet access. The quality of content presentation and download speeds may vary depending on the software, equipment, and Internet connections used.",
        "You may be able to use devices other than a personal computer to access the Services, such as smartphones and cell phone, some personal digital assistants (“PDAs”), or other devices. If you do choose to use another kind of device to access the Services, you must be responsible for protecting it in accordance with these Terms of Use. Any requirement for personal computer protection in these Terms of Use applies equally to any other device you may use to access the Services.",
        "In order to use the Services, you are required to create and maintain an online account. Your personal computer or other device may store and automatically upload your username and password for account access. You may also download information from the Services to your personal computer or other device. If someone else uses your personal computer or other device, he or she may be able to use these functions to gain access to your account or PII. CPNW cannot prevent or be responsible for protecting your personal computer or other device against unauthorized use. You are solely responsible for protecting the personal computers or other devices you use against unauthorized use to access your account.",
        "In order to use the Services, you are required to create and maintain an online account. Your personal computer or other device may store and automatically upload your username and password for account access. You may also download information from the Services to your personal computer or other device. If someone else uses your personal computer or other device, he or she may be able to use these functions to gain access to your account or PII. CPNW cannot prevent or be responsible for protecting your personal computer or other device against unauthorized use. You are solely responsible for protecting the personal computers or other devices you use against unauthorized use to access your account.",
        "If you use a personal computer or other device provided by or shared with your employer, parent, spouse, roommate, or other person, that other party may have the legal right to view all information stored on it. CPNW cannot prevent or be responsible for such activities. If you use a personal computer or other device which another person has the legal right to control, you access your CPNW account and the Services at your own risk.",
        "Whenever you are done using the Services, you should log off the Services, and you should not leave your computer unattended when it is logged on to the Services. Someone else may be able to view the screen or use the computer or device. If you leave a Services session logged-in when someone else can see your screen or use your computer, he or she may be able to gain access to your account or PII. CPNW cannot log you off your computer or protect your personal computer against unauthorized access. You are solely responsible for protecting the personal computers or other devices you use against unauthorized use to access your account.",
        "Computer viruses, worms, spyware, and other malicious software on your computer or other device can be used to gather passwords and personal information from that computer or device, often without the user’s knowledge. CPNW cannot prevent or be responsible for detecting or removing malicious software on computers or devices you use to access the Services. Affordable antivirus and antispyware software is readily available for personal computers and most other devices. You should maintain and regularly use current antivirus and antispyware software on any personal computer or device you use to access the Services.",
        "Unauthorized individuals (“hackers”) and malicious software can sometimes gain access to computers through Internet connections, unless the computer has a working firewall. Unauthorized access of this kind may not be easily detected and may allow passwords and personal information to be gathered without the computer user’s knowledge. CPNW does not provide and cannot be responsible for implementing a firewall for your computer. Firewall software is standard on many personal computers and affordable firewall software for personal computers is readily available. You should maintain a working firewall on any computer you use to access the Services.",
        "If you are using a wireless network to access the Services, unauthorized individuals may be able to intercept your connection unless your network is appropriately secured. Unauthorized access of this kind may not be easily detected and may allow passwords and personal information to be gathered without the computer user’s knowledge. Home and business wireless networks may be secured easily, but many wireless networks which are available for public use are not secured. You should not access the Services through an unsecured wireless network. If you do not know whether a wireless network you wish to use is secured, you should not use it.",
        "Computer criminals may sometimes send e-mails which appear to come from legitimate organizations. These e-mails typically ask you to provide personal information or may ask you to log on to a Web site using a link in the e-mail, and enter some personal information to “verify”, “correct”, or “update” your account, or something similar. This practice is known as “phishing,” and is intended to get personal information from unwary users to use for identity theft and other fraud.",
        "Once the account creation process is complete, CPNW WILL NEVER SEND YOU E-MAILS ASKING FOR A CREDIT CARD OR OTHER FINANCIAL ACCOUNT NUMBER, YOUR SOCIAL SECURITY NUMBER, YOUR PERSONAL INFORMATION, OR PASSWORD. IF YOU RECEIVE AN E-MAIL THAT CLAIMS TO BE FROM CPNW, WHICH ASKS YOU FOR ANY FINANCIAL OR PERSONAL INFORMATION, PLEASE DO NOT PROVIDE THAT INFORMATION.",
        `CPNW may send you e-mails with a link to the CPNW website, but will not send you any e-mail asking you to click a link for any other party’s website, or to access messages or correspondence. If you receive any e-mail which claims or appears to be from CPNW, and you are suspicious of it for any reason or wish to verify its validity, please notify us here:`,
    ]

    let protectYourPasswordContent = [
        "Access to your account and to your personally identifiable Subscriber or Account registration information is password-protected. If your password is easy to guess, or if you share your password or someone else sees or steals it, someone else may be able to get access to your account. You must use a strong password, and protect it from other people.",
        "CPNW relies upon your password in allowing access to your account and cannot prevent unauthorized access if your password is compromised. You are responsible for all activity taking place under your password unless we have been notified that it is compromised. Please notify us at once if you believe your password has been compromised, and we will help you change it. You can contact us about possible password compromise: support@cpnw.org",
    ]

    let abuseCPNWServicesContent = [
        "In order to keep the Services working efficiently and appropriately, you are not permitted to:",
        "Use any other person’s username or password.",
        "Use the Services in any way that could damage, disable, overburden, or impair the Services or interfere with any other party’s use of the Services.",
        "Attempt to gain unauthorized access to any information, documents, records, accounts, devices, systems or networks connected to the Services through hacking, password cracking, IP spoofing or any other means.",
        "Restrict or inhibit anyone else from using the Services.",
    ]

    const between = (x, min, max) => {
        return x >= min && x <= max;
    }

    return (
        <>
            <TermHeader header="Terms of Use" />
            <p >
                Please read these Terms of Use and all other applicable policies carefully. If you have any questions about these Terms of Use or any other policies which apply to your use of the Services,
                please contact us at <a className={styles.link} href="mailto:support@cpnw.org">support@cpnw.org</a>.
            </p>

            <ListHeader number={1} header={"Description of the services"} />
            <p className={styles.subParagraph}>
                CPNW online Services are accessed by Users through a password protected dashboard at <a className={styles.link} href="https://cpnw.org">www.cpnw.org</a>. Only Users who are invited by a Facility may create an account on the system. Services consist of those identified below and future enhancements. Additional terms will be
                made available at the time of release of new services. Those additional terms shall become part of
                your agreement with us if you use those services.
            </p>

            <ul>
                {descriptionSectionBullets.map((item, index) => {
                    if (between(index, 5, 6) || between(index, 8, (descriptionSectionBullets.length - 1))) {
                        return <ul ><ListItem listContent={item} key={index} /></ul>
                    }
                    return <ListItem listContent={item} key={index} />
                })}
            </ul>

            <ListHeader number={2} header={"Account Registration"} />
            <p className={styles.olSubParagraph}>
                Registration procedures are different for Users and Facility Account Coordinators.
            </p>

            <ListItem listContent={"User Registration"} isStrong={true} />
            <p className={styles.subParagraph}>
                In order to create a User account, the following procedures apply:
            </p>

            {userRegistrationBullets.map((item, index) => {
                return <ul><ListItem listContent={item} key={index} /></ul>
            })}

            <StepHeader stepNum={1} header={"Creation of Username and Password"} />
            {stepOneBullets.map((item, index) => {
                return <ul><ListItem listContent={item} key={index} /></ul>
            })}

            <StepHeader stepNum={2} header={"First Login and Creation of User Profile"} />
            <ul>
                <p>
                    The account request is not complete until the applicant completes the consent and their profile.
                </p>
            </ul>
            <ul>
                {stepTwoBullets.map((item, index) => {
                    if (between(index, 1, 2)) {
                        return <ul><ListItem listContent={item} key={index} /></ul>
                    }
                    return <ListItem listContent={item} key={index} />
                })}
            </ul>
            <ListItem listContent={"Facility Account Coordinator Registration"} isStrong={true} />
            <p className={styles.subParagraph}>
                To establish a Facility Account Coordinator, the affiliated Facility shall submit a facility account
                request using the online form located at the following link: <a className={styles.link} href="https://cpnw.org/Schools">https://cpnw.org/Schools</a>. The request should include information for every program that will need access and shall designate
                primary and secondary coordinators for each program. CPNW will then create an account code
                for each program/unit at the Subscriber Facility.
            </p>

            <p className={styles.subParagraph}>
                Below is an example of the Account Request form:
            </p>
            <ul><ul>
                <RequestExampleSection />
                <ProgramInfoSection />
            </ul></ul>

            <ListHeader number={3} header={"Account suspension/termination"} />
            <ListItem listContent={"User Suspension/Termination"} isStrong={true} />
            {userSusBullets.map((item, index) => {
                return (
                    <ul>
                        <ListItem listContent={item} key={index} />
                    </ul>)
            })}

            <ListItem listContent={"Facility Account Suspension/Termination"} isStrong={true} />
            {facilityAccountSusBullets.map((item, index) => {
                return (
                    <ul>
                        <ListItem listContent={item} key={index} />
                    </ul>
                )
            })}

            <ListHeader number={4} header={"User Responsibilities"} />
            <p className={styles.olSubParagraph}>Protecting privacy and security is everyone’s responsibility. Users must therefore be responsible for the following:</p>

            <ListItem listContent={"Be an Informed User"} isStrong={true} />
            {informedUserContent.map((item, index) => {
                return <p className={classNames(styles.subParagraph)} key={index}>{item}</p>
            })}

            <ListItem listContent={"Provide Correct Information"} isStrong={true} />
            {correctInformationContent.map((item, index) => {
                return <p className={styles.subParagraph} key={index}>{item}</p>
            })}

            <ListItem listContent={"Maintain and Protect Your Own Equipment"} isStrong={true} />
            {maintainAndProtectContent.map((item, index) => {
                if (index != (maintainAndProtectContent.length - 1)) {
                    return <p className={styles.subParagraph} key={index}>{item}</p>
                }
                return <p className={styles.subParagraph} key={index}>{item} <a className={styles.link} href="mailto:support@cpnw.org">support@cpnw.org</a></p>
            })}

            <ListItem listContent={"Protect Your Password"} isStrong={true} />
            {protectYourPasswordContent.map((item, index) => {
                return <p className={styles.subParagraph} key={index}>{item}</p>
            })}

            <ListItem listContent={"Do Not Abuse CPNW Services"} isStrong={true} />
            {abuseCPNWServicesContent.map((item, index) => {
                if (index == 0) {
                    return <p className={styles.subParagraph} key={index}>{item}</p>
                }
                return <ul><ListItem listContent={item} key={index} /></ul>
            })}

            <ListItem listContent={"Information Is Not Guarenteed"} isStrong={true} />
            <p className={styles.subParagraph}>While CPNW seeks to provide useful, high-quality information through the Services, CPNW cannot guarantee that any information is accurate, complete, current, error-free, or free of viruses, worms or other malicious software. Information is provided “as is,” without warranties of any kind, express or implied, including but not limited to warranties of fitness and suitability for any particular purpose.</p>

            <ListItem listContent={"Linked Websites Are Not Endorsed"} isStrong={true} />
            <p className={styles.subParagraph}>CPNW seeks to link only to trustworthy, valuable websites, but provides website links for your convenience only. A link to a website does not constitute an endorsement of the linked site, its content, or any party affiliated with the linked website. CPNW makes no warranties or representations, express or implied, about the accuracy, completeness, currency, relevance, appropriateness, or availability of any linked site, the information contained in them, or any products or services they offer. CPNW shall not be liable, directly or indirectly, for any damages, losses, or harm incurred in connection with the use of a linked website.</p>

            <ListItem listContent={"Services Avaliability and Limitations"} isStrong={true} />
            <p className={styles.subParagraph}>CPNW will attempt to keep the Services available at all times, but availability may be interrupted from time to time for maintenance, repair, upgrade, or other activities, or due to natural disaster or other emergency. CPNW is not responsible for and cannot control the availability of third party communications services necessary to connect to the Services.</p>

        </>
    )

}
export default UseSection