import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, InputBox } from "@cpnw/ui";
import styles from "./LoginForm.module.css";

/**
 * Interactive component.
 * Form used when logging in.
 */
const LoginForm = ({ loading, invalidLoginText, onLogin }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [errors, setErrors] = useState([]);

  const validate = (email, password) => {
    const errors = [];

    if (!email || email.trim() == "") {
      errors.push(["email", "Email is required"]);
    } else if (!RG_Email.test(email)) {
      errors.push(["email", "Email is not valid"]);
    }

    if (!password || password.trim() == "") {
      errors.push(["password", "Password is required"]);
    } else if (!RG_Password.test(password)) {
      errors.push([
        "password",
        "At least 10 characters including at least: an uppercase character, a lowercase character, a number and a special character (! @ # $ & * _ ?). ",
      ]);
    }

    return errors;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const errors = validate(email, password);
    setErrors([...errors]);

    if (errors.length > 0) {
      return;
    }

    if (onLogin && typeof onLogin == "function") {
      onLogin({
        email: email,
        password: password,
      });
    }
  };

  const getError = (field) => {
    const error = errors.find((m) => m[0] == field);
    return error ? error[1] : null;
  };

  return (
    <div className={styles.loginForm}>
      <span className={styles.header}>LOGIN</span>
      <span>
        {invalidLoginText ? (
          <ul>
            <li>{invalidLoginText}</li>
          </ul>
        ) : (
          <br />
        )}
      </span>
      <form className={styles.form} onSubmit={(e) => onSubmit(e)}>
        <input className={styles.submitHidden} type="submit" hidden />
        <div className={styles.formFields}>
          <InputBox ref={emailRef} placeholder={"Email *"} />
          <span>{getError("email")}</span>
        </div>
        <div className={styles.formFields}>
          <InputBox
            ref={passwordRef}
            placeholder={"Password *"}
            type={"password"}
          />
          <span>{getError("password")}</span>
        </div>
        <Button
          className={styles.submitBtn}
          text={"LOGIN"}
          onClick={(e) => onSubmit(e)}
          disabled={loading}
        />
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  /**
   * Function called when login is clicked.
   */
  onLogin: PropTypes.func,

  /**
   * Reason for invalid login.
   */
  invalidLoginText: PropTypes.string,
};

const RG_Email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const RG_Password =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$&*_]).{10,}$/;

export default LoginForm;
