import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CpnwContainer } from "hooks/useCpnw";
import { StaticRoutes } from "pages/routes";
import Preloader from "../../images/preloader.gif";
import { Button } from "@cpnw/ui";
import styles from "./ConfirmedEmailPage.module.css";
import { AccountContainer } from "hooks/useAccount";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function ConfirmedEmailPage({}) {
  const history = useHistory()
  const { accountConfirmEmailGet } = CpnwContainer.useContainer();
  const { requestConfirmationEmail } = AccountContainer.useContainer();

  const { search } = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    let userId = searchParams.get("userId");
    let code = searchParams.get("code");

    if (userId?.trim() != "" && code?.trim() != "") {
      accountConfirmEmailGet(userId, code).then((ok) => {
        setLoading(false);
        setError(!ok);
      });
    } else {
      setLoading(false);
      setError(true);
    }
  }, [search]);

  return (
    <div className={styles.container}>
      {loading ? (
        <img
          className={styles.loading}
          src={Preloader}
          height={35}
          width={35}
        />
      ) : !error ? (
        <>
          <h1>Email Confirmed</h1>
          <h5>Thank you confirming your email.</h5>
          <div>
            <Button
              onClick={() => (window.location.href = StaticRoutes.AccountLogin)}
              text="COMPLETE REGISTRATION"
            />
          </div>
        </>
      ) : (
        <>
          <h1>Error</h1>
          <h5>An error occurred while processing your request.</h5>
          <h5>Possibly Using an Expired Confirmation Email.</h5>
          <div className={styles.center}>
            Click{" "}
            <a
              onClick={() => {
                requestConfirmationEmail().then(()=>{
                  history.push(StaticRoutes.ConfirmEmailPending)
                })
              }}
            >
              here
            </a>{" "}
            to request a new confirmation email.
          </div>
        </>
      )}
    </div>
  );
}

export default ConfirmedEmailPage;
