import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./NursingPlacementItem.module.css";

/**
 * View component
 * Item with info about a nursing placement page
 */
export function NursingPlacementItem({
  img,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
}) {
  return (
    <div className={classNames(styles.NursingPlacementItem)}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <div className={styles.info}>
            {firstParagraph || "First Paragraph"}
          </div>
          <div className={styles.info}>
            {secondParagraph || "Second Paragraph"}
          </div>
          <div className={styles.info}>
            {thirdParagraph || "Third Paragraph"}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img className={styles.img} src={img} width={200} height={142} />
        </div>
      </div>
    </div>
  );
}

NursingPlacementItem.propTypes = {
  /**
   * First paragraph for the item.
   */
  firstParagraph: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Second paragraph for the item.
   */
  secondParagraph: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Third paragraph for the item.
   */
  thirdParagraph: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Src for the image on the right.
   */
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
