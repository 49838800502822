import React from "react";
import styles from './DownloadTermsSection.module.css';
import TermHeader from "../TermHeader/TermHeader";
import { Button } from "@cpnw/ui";

const DownloadTermsSection = ({ }) => {

    const downloadTerms = () => {
        window.open(`https://cpnw.blob.core.windows.net/public-documents/Terms%20of%20Use%20and%20Privacy%20Policy.pdf`, "_blank")
    }
    const paragraphList = [
        "Clinical Placements Northwest, a Washington nonprofit corporation, (“CPNW”) is committed to promoting the optimal clinical learning environment.",
        "CPNW provides preclinical learning, clinical placement, and related services (“Services”) to individual students and faculty members (“Subscribers”) affiliated with healthcare providers and higher education organizations which have agreements with CPNW (“Facilities”). Subscribers may use the Services to have CPNW maintain personally identifiable information about themselves (“Subscriber PII”) and distribute it to Facilities for purposes authorized by these Terms of Use and Consent.        ",
        "Facilities access the Services through individual users authorized by the Facility and CPNW to act as their “Facility Account Coordinators.” Facility Account Coordinators must also create accounts for access to the Services as provided in these Terms of Use.",
    ]
    return (
        <div className={styles.downloadDiv}>
            <TermHeader header="CPNW Services Terms of Use and Privacy Policy" />
            <div className={styles.ButtonRow}>
                <Button onClick={downloadTerms} text={"Download"} />
            </div>
            {paragraphList.map((args, i) => {
                return (
                    <p key={i} className={styles.downloadPStyles}>{args}</p>
                )
            })}
        </div>
    )
}

export default DownloadTermsSection;