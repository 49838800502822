import React, { useState } from "react";
import { AccountContainer } from "hooks/useAccount";
import LoginForm from "components/LoginForm";
import ResetPasswordForm from "components/ResetPasswordForm";
import styles from "./AccountLogin.module.css";
import environment from "environment";

const AccountLogin = () => {
  const { login, forgotPassword } = AccountContainer.useContainer();
  const [loading, setLoading] = useState(false);
  const [invalidLoginText, setInvalidLoginText] = useState(null);

  const onLogin = async (data) => {
    let success = false;
    setLoading(true);
    try {
      success = await login(data);
      // window.location.href = window.location.href;
    } catch {}

    if (!success) setInvalidLoginText("Invalid login attempt.");
    else setInvalidLoginText(null);
    
    setLoading(false);
  };
  const onResetPassword = (data) => {
    console.log("email", data);
    forgotPassword(data)
  };
  return (
    <div className={styles.accountLogin}>
      <h1>Member Login</h1>
      <div className={styles.container}>
        <div className={styles.loginForm}>
          <LoginForm
            loading={loading}
            onLogin={(data) => onLogin(data)}
            invalidLoginText={invalidLoginText}
          />
        </div>
        <div className={styles.resetForm}>
          <ResetPasswordForm
            onResetPassword={(data) => onResetPassword(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountLogin;
