import { useEffect } from "react";
import { GetStartedItem } from "@cpnw/ui";
import fa_graduation_cap from "../../../../assets/fa-graduation-cap.svg";
import fa_users from "../../../../assets/fa-users-AA9B73.svg";
import fa_hospital_o from "../../../../assets/fa-hospital-o-007E8D.svg";
import styles from "./GetStarted.module.css";

const GetStarted = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.getStarted}>
      <h1>Connecting with us is just steps away.</h1>
      <div className={styles.getStarted_subContent}>
        <div className={styles.item}>
          <GetStartedItem
            header={"Schools"}
            subHeader={"Become a CPNW program!"}
            img={fa_graduation_cap}
            info={
              <p>
                We welcome the nationally-accredited educational programs that
                have placement agreements with our healthcare partners. Click on
                the <a href="../GetStarted/Schools">Schools</a> link on the left
                for information on subscribing.
              </p>
            }
          />
        </div>
        <div className={styles.item}>
          <GetStartedItem
            header={"Students"}
            subHeader={"Connect through your school!"}
            img={fa_users}
            info={
              <p>
                Your school will manage your CPNW account, including specific
                requirements of your program. To learn general details about how
                things work on our website, click on the{" "}
                <a href="../GetStarted/Students">Students</a> link on the left.
              </p>
            }
          />
        </div>
        <div className={styles.item}>
          <GetStartedItem
            header={"Healthcare"}
            subHeader={"We welcome new partners!"}
            img={fa_hospital_o}
            info={
              <p>
                Improving and streamlining clinical learning hinges on strong
                relationships with like-minded partners. Find more on how you
                can join us by clicking on the{" "}
                <a href="../GetStarted/Healthcare">Healthcare</a> link on the
                left.
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
