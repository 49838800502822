import styles from "./GetStartedPage.module.css";
import { StaticRoutes } from "../routes";
import { PageNav } from "@cpnw/ui";

const GetStartedPage = ({ children }) => {
  return (
    <div className={styles.getStartedPage}>
      <div className={styles.contentContainer}>
        <div className={styles.nav}>
          <PageNav
            header={"Get Started"}
            headerTo={StaticRoutes.GetStarted}
            links={[
              {
                name: "Schools",
                src: StaticRoutes.GetStartedSchools,
              },
              {
                name: "Students",
                src: StaticRoutes.GetStartedStudents,
              },
              {
                name: "Healthcare",
                src: StaticRoutes.GetStartedHealthcare,
              },
            ]}
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

export default GetStartedPage;
