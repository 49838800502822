import * as React from 'react';
import {useLocation, useHistory} from 'react-router-dom';

export const createSearchParams = (init = '') =>
  new URLSearchParams(
    typeof init === 'string' ||
    Array.isArray(init) ||
    init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key];
          return memo.concat(
            Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
          );
        }, [])
  );

const useSearchParams = (defaultInit) => {
  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit));

  const location = useLocation();
  const searchParams = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    }

    return searchParams;
  }, [location.search]);

  const history = useHistory();
  const setSearchParams = React.useCallback(
    (nextInit, navigateOptions) => {
      const path = '?' + createSearchParams(nextInit);
      if (navigateOptions?.replace) {
        history.replace(path, navigateOptions?.state);
      }
      history.push(path, navigateOptions?.state);
    },
    [history]
  );

  return [searchParams, setSearchParams];
};

export default useSearchParams;