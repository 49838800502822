import React, { useEffect, useMemo } from "react";
import styles from "./ParticipatingProgramsPage.module.css";
import { InfoSection, HeaderSection, DirectorHeader } from "@cpnw/ui";
import ProgramList from "../../../../components/ProgramList";
import { CpnwContainer } from "hooks/useCpnw";

export default function ParticipatingProgramsPage() {
    const {participatingProgramsData, refetchParticipatingPrograms} = CpnwContainer.useContainer()

    useEffect(()=>{
      refetchParticipatingPrograms()
    },[])

    const programs = useMemo(()=>{
        if(participatingProgramsData){
            return participatingProgramsData.reduce((p, c)=>{
                var school = p.find(s=>s.school == c.facilityName)
                if(school){
                    school.programs.push(c.businessUnit)
                }
                else{
                    school = {
                        school: c.facilityName,
                        programs:[c.businessUnit]
                    }
                    p.push(school)
                }
                return p
            }, [])
        }
        return []
    }, [participatingProgramsData])

  return (
    <section className={styles.participating_programs_wrapper}>
      <div className={styles.participating_programs_header}>
        <InfoSection
          header="Participating Programs"
          subHeader="Explore our extensive list of schools and programs"
          paragraphs={[
            "CPNW partners with an extensive list of over 200 education programs throughout the Pacific Northwest with a goal to enhance clinical education by standardizing the pre-clinical and onboarding experiences. When you explore our list of schools, click on the name to see programs within each facility. Or search for a specific school with the search tool.",
          ]}
        />
      </div>
      <div className={styles.participating_programs_list_wrapper}>
        <ProgramList
            programs={programs}
        />
        {/* <ProgramList
                programs={[
                    { school: "BALL STATE UNIVERSITY SCHOOL OF NURSING", programs: ["ADMIN",]},
                    { school: "BLUE MOUNTAIN COMMUNITY COLLEGE", programs: ["ADMIN",]},
                    { school: "BRANDMAN UNIVERSITY", programs: ["SOCIAL WORK", "NURSING-RN TO BSN", "PSYCHOLOGY"]},
                    { school: "CAPELLA UNIVERSITY", programs: ["NURSING-MSN", "SOCIAL WORK", "PUBLIC HEALTH", "DOCTOR OF NURSING PRACTICE"]},
                    { school: "CLACKAMANS COMMUNITY COLLEGE", programs: ["ADMIN",]},
                    { school: "COLUMBIA GORGE COMMUNITY COLLEGE", programs: ["ADMIN",]},
                    { school: "CONCORDIA UNIVERSITY", programs: ["ADMIN", "BACKELOR OF SCIENCE - NURSING"]},
                    { school: "CONCORDIA UNIVERSITY WISCONSIN", programs: ["OCCUPATIONAL THERAPY", "Doctor of Physical Therapy"]},
                    { school: "CREIGHTON UNIVERSITY", programs: ["NURSING-BACHELOR OF SCIENCE", "NURSING-MSN", "Nursing-Doctor Nursing Practice", "Pharmacy-Doctorate"]},
                    { school: "GEORGETOWN UNIVERSITY SCHOOL OF NURSING & ALLIED HEALTH", programs: ["Nursing-Masters", "NURSE MIDWIFERY/WOMENS HEALTH NURSE PRACTITIONER"]},
                    //Page 2
                    { school: "GRACELAND UNIVERSITY", programs: ["Nursing-MSN"]},
                    { school: "GRAND CANYON UNIVERSITY", programs: ["Nursing-MSN", "Master of Science in Professional Counseling", "NURSING - RN TO BSN", "HEALTH INFORMATICS - MS", "NURSING-BACHELOR OF SCIENCE", 'NURSING-DOCTOR NURSING PRACTICE', "MASTER OF PUBLIC HEALTH", "MS - HEALTH ADMINISTRATION", "NUTRITION AND DIETETICS"]},
                    { school: "INDIANA STATE UNIVERSITY", programs: ["FAMILY NURSE PRACTITIONER", "PHYSICIAN ASSISTANT", "GENETIC COUNSELING", "Nursing-Bachelor of Science", "Nursing-RN to BSN", "Nursing-LPN to BSN"]},
                    { school: "ITT/BRECKENRIDGE PORTLAND COLLEGE", programs: ["ADMIN",]},
                    { school: "JOHNS HOPKINS UNIVERSITY", programs: ["ADMIN", "DOCTOR OF NURSING PRACTICE"]},
                    { school: "KAPLAN UNIVERSITY", programs: ["Medical Assisting", "Medical Office Administration", "Health Information Tech", "Nursing-Bachelor of Science"]},
                    { school: "LIBERTY UNIVERSITY", programs: ["NURSING - MSN"]},
                    { school: "LINFIELD COLLEGE", programs: ["NURSING - BSN"]},
                    { school: "MARYVILLE UNIVERSITY", programs: ["DNP-FNP", "NURSE PRACTITIONER"]},
                    { school: "MT HOOD COMMUNITY COLLEGE", programs: ["ADMIN",]},
                    //Page 3
                    { school: "OREGON HEALTH AND SCIENCE UNIVERSITY", programs: ["MEDICAL LABORATORY SCIENCE", "PHYSICIAN-SCIENTIST PROGRAM"]},
                    { school: "PHILADELPHIA UNIVERSITY", programs: ["ADMIN",]},
                    { school: "PORTLAND COMMUNITY COLLEGE", programs: ["Magnetic Resonance Imaging"]},
                    { school: "RUSH UNIVERSITY", programs: ["Nursing-Doctor Nursing Practice"]},
                    { school: "SAMFORD UNIVERSITY", programs: ["NURSING-MSN TO FNP"]},
                    { school: "SOUTH UNIVERSITY", programs: ["ADULT-GERONTOLOGY NURSE PRACTITIONER", "FAMILY NURSE PRACTITIONER"]},
                    { school: "SUMNER COLLEGE", programs: ["ADMIN", "REGISTERED NURSING", "PRACTICAL NURSING", "NURSING - ASSOCIATES DEGREE"]},
                    { school: "UNIVERSITY OF ARIZONA", programs: ["COLLEGE OF NURSING - DNP", "COLLEGE OF PHARMACY", "ADMIN"]},
                    { school: "UNIVERSITY OF CINCINNATI", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF GREAT FALLS", programs: ["ADMIN",]},
                    //Page 4
                    { school: "UNIVERSITY OF MISSOURI", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF NORTHERN COLORADO", programs: ["Nursing-Bachelor of Science", "Pharmacy-Doctorate"]},
                    { school: "UNIVERSITY OF NORTH DAKOTA", programs: ["Nursing-Bachelor of Science", "Communications Science Disorders", "Occupational Therapy", "DOCTOR OF MEDICINE", "MASTERS OF SOCIAL WORK"]},
                    { school: "UNIVERSITY OF PITTSBURGH", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF MT MARY'S", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF SAN FRANCISCO", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF SOUTH ALABAMA", programs: ["ADULT GERON CNS", "FAMILY PSYCHIATRIC MENTAL HEALTH NURSE PRACTIONER", "NURSE PRACTITIONER", "Nursing-MSN", "Nursing-Advanced Practice"]},
                    { school: "VANDERBILT UNIVERSITY", programs: ["Nursing-MSN", "WOMENS HEALTH NURSE PRACTITIONER"]},
                    { school: "VILLANOVA UNIVERSITY", programs: ["ADMIN",]},
                    { school: "WALDEN UNIVERSITY", programs: ["MASTER SOCIAL WORK", "PSYCHIATRIC-MENTAL HEALTH NURSE PRACTITIONER", "PUBLIC HEALTH NURSING", "ADULT GERONTOLOGY ACUTE CARE NURSE PRACTITIONER", "LEADERSHIP AND MANAGEMENT", "EDUCATION", "FAMILY NURSE PRACTITIONER", "ADULT GERONTOLOGY PRIMARY CARE NURSE PRACTITIONER", "INFORMATICS", "Nursing-Doctor Nursing Practice"]},
                    //Page 5
                    { school: "WESTERN UNIVERSITY OF HEALTH SCIENCES", programs: ["doctorate Physical Therapy", "Ostepathic Medicine", "Nursing-Masters", "EMERGENCY NURSE PRACTITIONER"]},
                    { school: "BATES TECHNICAL COLLEGE", programs: ["CERTIFIED MEDICAL ASSISTING", "Biomedical Equip Tech", "NURSING-PRACTICAL NURSE", "Occupational Therapy Asst.", "Phlebotomy Tech", "Administrative Medical Assistant", "Emergency Medical Tech", "Nursing-LPN Refresher"]},
                    { school: "BELLEVUE COLLEGE", programs: ["RADIATION AND IMAGING SCIENCES", "NURSING-ASSOCIATE DEGREE", "NURSING-BACHELOR OF SCIENCE", "Nursing-RN to BSN", "NURSING-RNR REFRESHER", "Radiologic Tech", "Radiation Therapy", "DIAGNOSTIC ULTRASOUND TECH", "Nuclear Medicine Tech", "NEURODIAGNOSTIC TECH", "ALLIED HEALTH/FIELD STUDIES", "NURSING ASSISTANT", "Medical Dosimetry", "HEALTHCARE INFORMATICS", "MEDICAL ASSISTANT - MC"]},
                    { school: "CENTRALIA COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "MEDICAL ASSISTANT", "Emergency Medical Tech", "Chemical Dependancy", "MEDICAL ADMINISTRATIVE ASSISTANT"]},
                    { school: "CLOVER PARK TECHNICAL COLLEGE", programs: ["Nursing-Practical Nurse", "Nursing-Associate Degree", "Pharmacy Tech", "Surgical Tech", "Medical Assistant", "Medical Laboratory Technology", "Health Unit Coordinator", "Central Service Tech", "CERTIFIED NURSING ASSITANT - VMFH", "MEDICAL ASSITANT - VMFH"]},
                    { school: "EVERETT COMMUNITY COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "MEDICAL ASSISTING", "NURSING-MSN"]},
                    { school: "GONZAGA UNIVERSITY", programs: ["NURSING-BACHELOR OF SCIENCE", "Nursing-Masters", "Nursing-MSN", "BioMed", "NURSING-DOCTOR ANESTHESIA PRACTICE"]},
                    { school: "GRAY'S HARBOR COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "Nursing-Practical Nurse"]},
                    { school: "GREEN RIVER COLLEGE", programs: ["NURSING-PRACTICAL NURSE", "Occupational Therapy Assistant" , "PHYSICAL THERAPIST ASSISTANT", "Emergency Medical Tech"]},
                    { school: "HIGHLINE COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "Respiratory Care", "Medical Assistant", "MEDICAL ASSISTANT - VMFH"]},
                    //Page 6
                    { school: "LAKE WA INSTITUTE OF TECHNOLOGY", programs: ["Physical Therapist Assistant", "NURSING-ASSOCIATE DEGREE", "OCCUPATIONAL THERAPY ASSISTANT", "MEDICAL ASSISTING", "NA-C - EH SPONSORED", "MEDICAL ASSISTING - EH SPONSORED", "BEHAVIORAL HEALTHCARE", "PUBLIC HEALTH"]},
                    { school: "LEWIS CLARK STATE COLLEGE", programs: ["SOCIAL WORK", "Pharmacy Technology", "NURSING-BACHELOR OF SCIENCE", "Radiographic Science", "Nursing-RN to BSN", "Nursing-PN to BSN", "Nursing-Practical Nurse"]},
                    { school: "LOWER COLUMBIA COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "Nursing-PN to RN"]},
                    { school: "NORTH IDAHO COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "NURSING-PRACTICAL NURSE", "Physical Therapist Assistant", "Radiologic Tech", "Pharmacy Tech", "Medical Laboratory Technology", "Medical Assistant", "Dental Hygiene", "SURGICAL TECHNOLOGY", "MEDICAL ADMINISTRATIVE ASSISTANT", "INBRE"]},
                    { school: "SEATTLE CENTRAL NORTH", programs: ["Emergency Department Tech", "PHARMACY TECHNICIAN", "Health Technology Management", "Nursing-Practical Nurse", "NURSING-ASSOCIATE DEGREE"]},
                    { school: "NORTHWEST UNIVERSITY", programs: ["NURSING-BACHELOR OF SCIENCE", "EXERCISE SCIENCE", "DOCTOR OF PSYCHOLOGY"]},
                    { school: "OLYMPIC COLLEGE", programs: ["CERTIFIED NURSING ASSISTANT - VMFH", "MEDICAL ASSISTANT - VMFH", "VETERAN - LPN TO BSN", "NURSING ASSISTANT, CERTIFIED", "PHYSICAL THERAPY ASSISTANT", "PRACTICAL NURSE", "CERTIFIED NURSING ASSISTANT - VMFH", "NURSING-ASSOCIATE DEGREE", "MEDICAL ASSISTING"]},
                    { school: "PACIFIC LUTHERAN UNIVERSITY", programs: ["Social Work", "PSYCHOLOGY", "NURSING-BACHELOR OF SCIENCE", "Nursing-RNR Refresher", "Nursing-MSN", "Nursing-Doctor Nursing Practice", "Kinesiology", "ACCELLERATED NURSING-BACHELOR OF SCIENCE"]},
                    { school: "PENINSULA COLLEGE - PORT ANGELES", programs: ["ADMIN",]},
                    { school: "PIERCE COLLEGE", programs: ["Health Information Tech", "NURSING-ASSOCIATE DEGREE", "Emergency Medical Services", "Business Information Tech"]},
                    //Page 7
                    { school: "RENTON TECHNICAL COLLEGE", programs: ["Anesthesia Tech", "Central Service Tech", "Medical Assistant", "Pharmacy Tech", "Phlebotomy Tech", "Surgical Tech", "Ophthalmic Assistant", "Nurse Assistant", "Massage Therapy Practitioner", "Dental Assistant", "NURSING-ASSOCIATE DEGREE"]},
                    { school: "SEATTLE CENTRAL COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "SURGICAL TECHNOLOGY", "Respiratory Care", "STERILE PROCESSING", "REGISTERED NURSE"]},
                    { school: "SEATTLE PACIFIC UNIVERSITY", programs: ["NURSING-DNP", "MUSIC THERAPY", "Nursing-MSN", "Dietetics", "MARRIAGE AND FAMILY THERAPY", "CLINICAL PSYCHOLOGY", "Nursing-Bachelor of Science"]},
                    { school: "SEATTLE UNIVERSITY", programs: ["NURSING-BSN", "Nursing-Advanced Practice", "NURSING-MSN", "Diagnostic Ultrasound Tech", "Social Work", "Theology and Ministry", "Masters in Psychology", "NURSING-DNP", "KINESIOLOGY", "SCHOOL OF THEOLOGY AND MINISTRY", "ADULT -GERO ACUTE CARE NURSE PRACTITIONER", "MASTER OF DIVINITY"]},
                    { school: "SHORELINE COMMUNITY COLLEGE", programs: ["CNA", "REGISTERED NURSE", "HEALTH INFORMATICS & INFORMATION MANAGEMENT", "PHLEBOTOMY", "Medical Lab Tech", "Nursing-Associate Degree"]},
                    { school: "SNOHOMISH COUNTY HEALTH DISTRICT", programs: ["ADMIN",]},
                    { school: "SOUTH PUGET SOUND COMMUNITY COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "MEDICAL ASSISTANT"]},
                    { school: "SEATTLE CENTRAL SOUTH COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "Medical Office Professional"]},
                    { school: "SPOKANE COMMUNITY COLLEGE", programs: ["RCIS", "BIOMEDICAL EQUIPMENT REPAIR", "Echocardiography", "NURSING-ASSOCIATE DEGREE", "HEALTH INFORMATION MANAGEMENT", "Radiology Tech", "Pharmacy Tech", "Surgical Tech", "ULTRASONOGRAPHY", "Phlebotomy Tech", "Vascular Tech", "Emergency Medical Tech", "Respiratory Care", "Invasive Cardiovascular Tech", "DIAGNOSTIC MEDICAL SONOGRAPHY", "MEDICAL ASSISTANT - ROCKWOOD", "MEDICAL ASSISTANT"]},
                    { school: "TACOMA COMMUNITY COLLEGE", programs: ["Medical Billing", "EMERGENCY MEDICAL TECH", "NURSING-ASSOCIATE DEGREE", "HEALTH INFORMATION MANAGEMENT", "PARAMEDIC", "DIAGNOSTIC MEDICAL SONOGRAPHY", "Radiologic Science", "RESPIRATORY THERAPY"]},
                    //Page 8
                    { school: "UNIVERSITY OF WASHINGTON MEDICAL CENTER", programs: ["ADMIN",]},
                    { school: "UNIVERSITY OF WASHINGTON-TACOMA", programs: ["Social Work", "Nursing-RN to BSN", "HEALTHCARE LEADERSHIP", "Nursing-Masters"]},
                    { school: "WASHINGTON STATE UNIVERSITY-SPOKANE", programs: ["College of Pharmacy", "Nutrition Exercise Physiology", "NURSING-BACHELOR OF SCIENCE", "NURSING-RN TO BSN", "Nursing-MN/DNP", "NURSING-RNR REFRESHER", "Speech Language Pathology-Masters", "DOCTOR OF MEDICINE", "DOCTOR OF PHARMACY", "PHARMACY - MC"]},
                    { school: "WASHINGTON STATE UNIVERSITY-TRICITIES", programs: ["NURSING-BACHELOR OF SCIENCE", "NURSING-RN TO BSN", "Nursing-MN/DNP", "Nursing-PHD"]},
                    { school: "WASHINGTON STATE UNIVERSITY-YAKIMA", programs: ["NURSING-MN/DNP", "NURSING-RN TO BSN", "NURSING-BACHELOR OF SCIENCE"]},
                    { school: "SAINT MARTIN UNIVERSITY", programs: ["BACHELOR OF SOCIAL WORK", "MASTER OF ARTS IN COUNSELING", "NURSING-BSN"]},
                    { school: "CLINICAL PLACEMENTS NORTHWEST", programs: ["TEST", "Clinical Placements Northwest", "REGISTRATION TUTORIAL", "ADMIN"]},
                    { school: "BIG BEND COMMUNITY COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE"]},
                    { school: "WASHINGTON STATE UNIVERSITY-VANCOUVER", programs: ["Nursing-MN/DNP", "Nursing-RN to BSN"]},
                    { school: "WENATCHEE VALLEY COLLEGE", programs: ["NURSING-ASSOCIATE DEGREE", "Radiography", "Medical Laboratory Technology"]},
                    //Page 9
                    { school: "WALLA WALLA COMMUNITY COLLEGE", programs: ["Nursing-Associate Degree"]},
                    { school: "CENTRAL WASHINGTON UNIVERSITY", programs: ["PUBLIC HEALTH", "FOOD SCIENCE AND NUTRITION", "Paramedicine", "Nutritional Exercise", "FAMILY AND CHILD LIFE", "CLINICAL PHYSIOLOGY"]},
                    { school: "EASTERN WASHINGTON UNIVERSITY", programs: ["THERAPEUTIC RECREATION", "HEALTH SERVICES ADMINISTRATION", "CHILDRENS STUDIES", "EXERCISE SCIENCE", "NURSING - BACHELOR OF SCIENCE", "Communication Sciences Disorders-Masters", "Career Services", "Physical Therapy", "OCCUPATIONAL THERAPY", "SOCIAL WORK"]},
                    { school: "UNIVERSITY OF WISCONSIN OSHKOSH", programs: ["DOCTOR OF PHYSICAL THERAPY", "Nursing-Bachelor of Science"]},
                    { school: "WHITWORTH UNIVERSITY", programs: ["Athletic Training"]},
                    { school: "PIMA MEDICAL INSTITUTE-RENTON", programs: ["Medical Assisting", "Medical Admin Assistant", "Phlebotomy Tech", "Occupational Therapy Assistant", "Respiratory Therapy", "Pharmacy Tech", "MEDICAL ASSISTING - MC"]},
                    { school: "PIMA MEDICAL INSTITUTE-SEATTLE", programs: ["MEDICAL ASSISTING - MC", "MEDICAL ASSISTING (EH)", "Medical Assisting", "Medical Admin Assistant", "Physical Therapy Assistant", "Radiography", "Pharmacy Tech"]},
                    { school: "CHARTER COLLEGE", programs: ["Medical Assistant", "Pharmacy Tech"]},
                    { school: "UNIVERSITY OF IOWA", programs: ["Pharmacy-Doctorate", "PHYSICAL THERAPY"]},
                    { school: "NORTHERN ARIZONA UNIVERSITY", programs: ["Physical Therapy-Doctorate", "PHYSICIAN ASSISTANT"]},
                    //Page 10
                    { school: "UNIVERSITY OF LA VERNE", programs: ["Child Life-Masters"]},
                    { school: "BOISE STATE UNIVERSITY", programs: ["Social Work-Masters", "DOCTOR OF NURSING PRACTICE IN LEADERSHIP", "Adult Gerontology Nurse Practitioner", "GENETIC COUNSELING"]},
                    { school: "SIMMONS COLLEGE", programs: ["NURSING-FNP", "Nursing-MSN", "Social Work-Masters"]},
                    { school: "UNIVERSITY OF PUGET SOUND", programs: ["Occupational Therapy", "Psychology", "Physical Therapy", "Bioethics", "Master of Education", "MASTER OF PUBLIC HEALTH"]},
                    { school: "PACIFIC UNIVERSITY", programs: ["AUDIOLOGY", "COMMUNICATION SCIENCES AND DISORDERS", "PHYSICIAN ASSISTANT", "Occupational Therapy", "Physical Therapy", "Pharmacy"]},
                    { school: "UNIVERSITY OF WASHINGTON-BOTHELL", programs: ["Nursing-RN to BSN", "Community Health", "NURSING-MN"]},
                    { school: "NOVA SOUTHEASTERN UNIVERSITY", programs: ["Occupational Therapy", "Speech Language Pathology-Masters", "Osteopathic Medicine", "Child Life Specialist"]},
                    { school: "WHATCOM COMMUNITY COLLEGE", programs: ["Physical Therapy Assistant"]},
                    { school: "UNIVERSITY OF WASHINGTON-SEATTLE", programs: ["Pharmacy-Doctorate", "Social Work", "SPEECH LANGUAGE PATHOLOGY", "AUDIOLOGY", "PHYSICIAN ASSISTANT", "Physical Therapy", "Medicine", "Nursing-Doctor Nursing Practice", "Occupational Therapy-Masters", "WOUND MANAGEMENT EDUCATION", "Medical Laboratory Science", "NURSING-BACHELOR OF SCIENCE", "NURSING-ACCELERATED BSN", "Master of Health Administration", "BS HEATH INFORMATICS & HEALTH INFORMATION MANAGEMENT", "BACHELOR OR HEALTH INFORMATICS &HEALTH INFORMATION MANAGEMENT", "GENETIC COUNLSELING", "PHARMACY DOCTORATE - MC", "FAMILY MEDICINE CLERKSHIP", "SCHOLARSHIP FOR UNPAID PUBLIC SECTOR INTERNSHIPS"]},
                    { school: "CLARKSON UNIVERSITY", programs: ["Physical Therapy"]},
                    //Page 11
                    { school: "UNIVERSITY OF MISSOURI-COLUMBIA", programs: ["Medicine"]},
                    { school: "UNIVERSITY OF PORTLAND", programs: ["NURSING-BACHELOR OF SCIENCE", "Nursing-Masters", "Nursing-Doctor Nursing Practice", "NURSING-ASSOCIATE DEGREE"]},
                    { school: "BASTYR UNIVERSITY", programs: ["Master of Public Health", "MIDWIFERY", "Dietetic Internship"]},
                    { school: "WEBER STATE UNIVERSITY", programs: ["Medical Laboratory Sciences-AAS", "Medical Laboratory Sciences-BS", "NUCLEAR MEDICINE", "RADIATION THERAPY"]},
                    { school: "IDAHO STATE UNIVERSITY", programs: ["NURSING-BSN", "MEDICAL LABORATORY SCIENCE", "HEALTH INFORMATION TECHNOLOGY", "OCCUPATIONAL THERAPY ASSISTANT", "PHYSICIAN ASSISTANT", "NURSING-ADN", "DOCTOR OF PHYSICAL THERAPY", "Pharmacy-Doctorate", "DOCTOR OF AUDIOLOGY"]},
                    { school: "EMORY UNIVERSITY", programs: ["Wound Ostomy Continence"]},
                    { school: "UNIVERSITY OF MARY HARDIN-BAYLOR", programs: ["Exercise Physiology-Masters"]},
                    { school: "EASTERN IOWA COMMUNITY COLLEGES", programs: ["Cancer Information Management"]},
                    { school: "MUSIC FOR HEALING & TRANSITION", programs: ["Music Healing"]},
                    { school: "SCOTT COMMUNITY COLLEGE", programs: ["Cancer Information Management"]},
                    //Page 12
                    { school: "GEORGE FOX UNIVERSITY", programs: ["Physical Therapy", "PHYSICIAN ASSISTANT"]},
                    { school: "FRONTIER NURSING UNIVERSITY", programs: ["NURSING MIDWIFERY", "NURSE PRACTIONER", "Nursing-Masters", "Nursing-Doctor Nursing Practice"]},
                    { school: "UNIVERSITY OF NEW ENGLAND", programs: ["Social Work", "Medical Assistant", "DOCTOR OF OSTEOPATHIC MEDICINE", "NUTRITION"]},
                    { school: "NORTHWESTERN UNIVERSITY", programs: ["GENETIC COUNSELING", "Physial Therapy"]},
                    { school: "MARTINSBURG COLLEGE", programs: ["Surgical Instrument Processing"]},
                    { school: "WICKS EDUCATIONAL ASSOCIATES", programs: ["Wound Ostomy Continence"]},
                    { school: "MIDWESTERN UNIVERSITY-IL", programs: ["Pharmacy-Doctorate", "Cardiovascular Science"]},
                    { school: "UNIVERSITY OF CENTRAL FLORIDA", programs: ["Healthcare Informatics", "Health Information Management", "Health Services Administration"]},
                    { school: "PRIORITY NUTRITION CARE LLC", programs: ["Nutrition Care", "Dietetics"]},
                    { school: "NATIONAL MEDICAL EDUCATION TRAINING CENTER", programs: ["Emergency Medical Tech"]},
                    //Page 13
                    { school: "UNIVERSITY OF MISSOURI-KANSAS CITY", programs: ["Pharmacy-Doctorate"]},
                    { school: "PACIFIC NORTHWEST UNIVERSITY", programs: ["Osteopathic Medicine"]},
                    { school: "CLARK COLLEGE", programs: ["Phlebotomy Tech"]},
                    { school: "TOURO UNIVERSITY CALIFORNIA", programs: ["Osteopathic Medicine", "PHYSICIANS ASSISTANT"]},
                    { school: "GRAND VALLEY STATE UNIVERSITY", programs: ["Occupational Therapy", "PHYSICAL THERAPY"]},
                    { school: "UNIVERSIDAD RAFAEL LANDIVAR", programs: ["International Electives"]},
                    { school: "WESTERN WASHINGTON UNIVERSITY", programs: ["Speech Language", "KINESIOLOGY", "COMMUNITY HEALTH", "AUDIOLOGY", "RN-BSN"]},
                    { school: "UNIVERSITY OF HAWAII", programs: ["Pharmacy"]},
                    { school: "SPOKANE FALLS COMMUNITY COLLEGE", programs: ["PHYSICAL THERAPIST ASSISTANT", "Occupational Therapy Assistant"]},
                    { school: "LIFE FLIGHT NETWORK", programs: ["NURSING", "Paramedic"]},
                    //Page 14
                    { school: "UNIVERSITY OF OREGON", programs: ["Communication Disorders Sciences"]},
                    { school: "INLAND NORTHWEST HEALTH SERVICES", programs: ["Paramedic"]},
                    { school: "TACOMA FIRE DEPARTMENT", programs: ["Emergency Medical Tech", "Paramedic"]},
                    { school: "SOUTHERN NEW HAMPSHIRE UNIVERSITY", programs: ["Nursing-MSN"]},
                    { school: "MEDICAL UNIVERSITY OF SOUTH CAROLINA", programs: ["Pharmacy"]},
                    { school: "UNIVERSITY OF SOUTHERN CALIFORNIA", programs: ["Ocupational Therapy", "Social Work-Masters", "PHYSICAL THERAPY", "PHARMACY"]},
                    { school: "CHAMBERLAIN COLLEGE OF NURSING", programs: ["Nursing-MSN", "SOCIAL WORK", "RN-BSN"]},
                    { school: "UNIVERSITY OF MONTANA", programs: ["RADIOLOGIC TECHNOLOGY", "SPEECH LANGUAGE PATHOLOGY", "RESPIRATORY CARE", "DOCTOR OF PHYSICAL THERAPY", "Pharmacy-Doctorate", "Physical Therapy"]},
                    { school: "OREGON INSTITUTE OF TECHNOLOGY", programs: ["Nuclear Medicine Tech", "Respiratory Care", "Sleep Health-Polysomnographic Technology", "Radiologic Science", "VASCULAR TECHNOLOGY", "ECHOCARDIOGRAPHY", "PHYSICIAN-SCIENTIST PROGRAM", "RADIOLOGIC SCIENCE - DEGREE COMPLETION", "MRI CERTIFICATE"]},
                    { school: "UTAH STATE UNIVERSITY", programs: ["AUDIOLOGY", "SPEECH LANGUAGE PATHOLOGY", "Dietetics"]},
                    //Page 15
                    { school: "ST LOUIS COLLEGE", programs: ["Experiential Education"]},
                    { school: "UNIVERSITY OF HOUSTON", programs: ["DIETETIC"]},
                    { school: "ROCKY MOUNTAIN UNIVERSITY", programs: ["Physical Therapy"]},
                    { school: "GEORGE WASHINGTON UNIVERSITY", programs: ["Nursing-MSN"]},
                    { school: "FRANKLIN PIERCE UNIVERSITY", programs: ["Physical Therapy"]},
                    { school: "UNIVERSITY OF KANSAS", programs: ["Audiology"]},
                    { school: "JEFFERSON HEALTHCARE", programs: ["Nursing-RN", "FAMILY BIRTH CENTER"]},
                    { school: "CHARTER COLLEGE-NEVADA", programs: ["Pharmacy Tech"]},
                    { school: "THE SAGE COLLEGES", programs: ["Dietetic Internship"]},
                    { school: "DUKE UNIVERSITY", programs: ["Doctorate Physical Therapy", "Nursing-MSN", "WOMENS HEALTH NURSE PRACTITIONER", "FAMILY NURSE PRACTICIONER", "ADULT ACUTE CARE NURSE PRACTITIONER"]},
                    //Page 16
                    { school: "KEITH AND ASSOCIATES DISTANCE", programs: ["Dietetic Internship"]},
                    { school: "CAMPBELL UNIVERSITY", programs: ["Osteopathic Medicine"]},
                    { school: "EASTERN MICHIGAN UNIVERSITY", programs: ["Dietetics"]},
                    { school: "MCNEIL ISLAND FIRE DEPARTMENT", programs: ["Emergency Medical Tech"]},
                    { school: "INDEPENDENCE UNIVERSITY", programs: ["Respiratory Therapy"]},
                    { school: "EDMONDS COMMUNITY COLLEGE", programs: ["Phlebotomy Tech", "PRACTICAL NURSING", "PHARMACY TECH", "EKG/Monitor Technician", "Acute Care Nursing Assistant", "NURSING ASSISTANT", "ADDICTION STUDIES"]},
                    { school: "DEVRY UNIVERSITY INC", programs: ["Health Information Tech", "Technical Management"]},
                    { school: "STEPHENS COLLEGE", programs: ["Health Information Tech"]},
                    { school: "HERITAGE UNIVERSITY", programs: ["Physician Assistant", "BSN"]},
                    { school: "OAKWOOD UNIVERSITY", programs: ["Dietetic Internship"]},
                    //Page 17
                    { school: "UNIVERSITY OF CINCINNATI", programs: ["Nursing-MSN", "NURSING-DOCTOR NURSING PRACTICE", "POST MASTERS", "CANCER REGISTRY MANAGEMENT", "MEDICAL LABORATORY SCIENCE"]},
                    { school: "BRADLEY UNIVERSITY", programs: ["DNP-FNP", "Nursing-MSN"]},
                    { school: "UC SAN DIEGO EXTENSION", programs: ["Lactation Consultant"]},
                    { school: "WESTERN GOVERNORS UNIVERSITY", programs: ["Health Information Management", "Nursing-MSN", "HEALTH SERVICES COORDINATION", "BACHELOR OF SCIENCE, NURSING"]},
                    { school: "MEDICAL UNIVERSITY OF SOUTH CAROLINA-COLLEGE OF HEALTH PROFESSIONS", programs: ["CARDIOVASCULAR PERFUSION", "Occupational Therapy", "PHYSICAL THERAPY"]},
                    { school: "SOUTH COLLEGE", programs: ["Doctor of Physical Therapy"]},
                    { school: "JEFFERSON COLLEGE OF NURSING", programs: ["Nursing-Doctor Nursing Practice"]},
                    { school: "WIDENER UNIVERSITY", programs: ["Social Work-Masters"]},
                    { school: "ROCKY MOUNTAIN COLLEGE", programs: ["Physician Assistant"]},
                    { school: "REGIS UNIVERSITY", programs: ["Neonatal Nurse Practitioner", "DOCTOR OF PHYSICAL THERAPY", "FAMILY NURSE PRACTITIONER", "NURSING - MS", "PHARMACY DOCTORATE", "PSYCHIATRIC MENTAL HEALTH NURSE PRACTITIONER"]},
                    //Page 18
                    { school: "MONTANA STATE UNIVERSITY", programs: ["NURSING - DNP", "Dietetic Internship"]},
                    { school: "BOSTON UNIVERSITY", programs: ["Social Work-Masters"]},
                    { school: "IOWA STATE UNIVERSITY", programs: ["Dietetics"]},
                    { school: "BELMONT UNIVERSITY", programs: ["Doctor of Physical Therapy", "OCCUPATIONAL THERAPY"]},
                    { school: "SOUTHEASTERN LOUISANA UNIVERSITY", programs: ["Child Life-Bachelors"]},
                    { school: "MARYWOOD UNIVERSITY", programs: ['Dietetic Internship']},
                    { school: "SOUTH DAKOTA STATE UNIVERSITY", programs: ["Nursing-Bachelor of Science", "Nursing-RN", "Nursing-LPN"]},
                    { school: "ALBANY COLLEGE OF PHARMACY", programs: ["Pharmacy-Doctorate"]},
                    { school: "NEBRASKA METHODIST COLLEGE", programs: ["Occupational Therapy", "BSN-DNP"]},
                    { school: "CONDENSED CURRICULUM INTERNATIONAL", programs: ["PHLEBOTOMY TECHNICIAN", "EKG TECHNICIAN", "Medical Assistant"]},
                    //Page 19
                    { school: "NATIONAL INSTITUTE OF FIRST ASSISTIG, INC", programs: ["REGISTERED NURSE FIRST ASSISTING"]},
                    { school: "MARSHAL UNIVERSITY", programs: ["REGISTERED NURSE FIRST ASSISTING"]},
                    { school: "UNIVERSITY OF THE PACIFIC", programs: ["Doctor of Audiology"]},
                    { school: "UNIVERSITY OF VICTORIA", programs: ["Nursing-Bachelor of Science"]},
                    { school: "THE OHIO STATE UNIVERSITY", programs: ["Genetic Counseling"]},
                    { school: "LABOURE COLLEGE", programs: ["Intraoperative Neuromonitoring"]},
                    { school: "UNIVERSITY OF ALABAMA AT BIRMINGHAM", programs: ["Genetic Counseling", "DIETITIAN EDUCATION PROGRAM", "NURSING-MSN"]},
                    { school: "ALTIERUS CAREER COLLEGE", programs: ["Medical Assistant", "Pharmacy Technician", "Medical Administrative Assistant"]},
                    { school: "SAINT XAVIER UNIVERSITY", programs: ["Nursing-Masters"]},
                    { school: "WESTERN OREGON UNIVERSITY", programs: ["COMMUNITY HEALTH", "EXCERCISE SCIENCE"]},
                    //Page 20
                    { school: "UNIVERSITY OF MICHIGAN", programs: ["Genetic Counseling"]},
                    { school: "WEB WOC", programs: ["Wound Ostomy Continence"]},
                    { school: "CPNW EDUCATION", programs: ["ADMIN", "TESTING"]}, //--Testing???
                    { school: "EDINBORO UNIVERSITY OF PENNSYLVANIA", programs: ["SOCIAL WORK-MASTERS", "SPEECH PATHOLOGY"]},
                    { school: "ANTIOCH UNIVERSITY SEATTLE", programs: ["CLINICAL PSYCHOLOGY-D", "CLINICAL PSYCHOLOGY-MA"]},
                    { school: "AZUSA PACIFIC UNIVERSITY", programs: ["Psychology - Child Life"]},
                    { school: "WEST VIRGINIA SCHOOL OF OSTEPATHIC MEDICINE", programs: ["Osteopathic Medicine"]},
                    { school: "DAEMEN COLLEGE", programs: ["Dietetic Internship"]},
                    { school: "ARIZONA STATE UNIVERSITY", programs: ["Doctor of Behavioral Health", "SOCIAL WORK-MASTERS", "AUDIOLOGY", "MASTERS- NURSING"]},
                    { school: "UNIVERSITY OF WASHINGTON SCHOOL OF SOCIAL WORK", programs: ["Social Work-Masters", "Social Work-Bachelor"]},
                    //Page 21
                    { school: "WELLNESS WORKDAYS DIETETIC INTERNSHIP, INC", programs: ["Dietetic Internship"]},
                    { school: "MILGARD SCHOOL OF BUSINESS, UW TACOMA", programs: ["Bachelor of Arts in Business Administration"]},
                    { school: "UNIVERSITY OF IDAHO", programs: ["ATHLETIC TRAINING", "EXERCISE SPORT AND HEALTH SCIENCES", "DIETETICS"]},
                    { school: "UNIVERSITY OF WISCONSIN SCHOOL OF MEDICINE", programs: ["Doctor of Medicine"]},
                    { school: "SABA UNIVERSITY SCHOOL OF MEDICINE", programs: ["Doctor of Medicine"]},
                    { school: "CARDIAC AND VASCULAR INSTITUTE OF ULTRASOUND", programs: ["Vascular Ultrasound"]},
                    { school: "PURDUE UNIVERSITY GLOBAL", programs: ["Nursing-MSN", "FAMILY NURSE PRACTITIONER", "DOCTOR OF PHARMACY", "NURSING - BSN"]},
                    { school: "RUTGERS UNIVERSITY", programs: ["Nutrition and Dietetics"]},
                    { school: "PUGET SOUND SKILLS CENTER", programs: ["NURSING ASSISTANT CERTIFIED"]},
                    { school: "MEDICAL UNIVERSITY OF SOUTH CAROLINA USC", programs: ["Pharmacy-Doctorate"]},
                    //page 22
                    { school: "EDWARD VIA COLLEGE OF OSTEPATHIC MEDICINE", programs: ["OSTEOPATHIC MEDICINE"]},
                    { school: "EMT AND FIRE TRAINING INC", programs: ["Emergency Medical Tech"]},
                    { school: "LOMA LINDA UNIVERSITY", programs: ["DOCTORATE OF PHYSICAL THERAPY"]},
                    { school: "KECK GRADUATE INSTITUTE", programs: ["GENETIC COUNSELING"]},
                    { school: "UNIVERSITY OF WASHINGTON/SEATTLE CHILDRENS HOSPITAL", programs: ["PEDIATRIC RESIDENCY PROGRAM"]},
                    { school: "NORTH IDAHO WORKFORCE TRAINING", programs: ["NURSING CERTIFICATION", "PHLEBOTOMY EXTERNSHIP", "CNA - KH"]},
                    { school: "BRIGHAM YOUNG UNIVERSITY-IDAHO", programs:["NURSING - BACHELORS", "HEALTH ADMINISTRATION"]},
                    { school: "A.T. STILL UNIVERSITY", programs: ["DOCTOR OF OSTEOPATHIC MEDICINE", "PHYSICAL THERAPY", "AUDIOLOGY", "FAMILY NURSE PRACTIONER", "OCCUPATIONAL THERAPY"]},
                    { school: "CARRINGTON COLLEGE", programs: ["MASSAGE THERAPY", "DENTAL ASSISTING", "MEDICAL ASSISTING -MC-ROCKWOOD", "MEDICAL RADIOGRAPHY", "MEDICAL ASSISTING", "MEDICAL BILLING AND CODING", "PHARMACY"]},
                    { school: "OBAFEMI AWOLOWO UNIVERSITY", programs: ["Medicine and Surgery"]},
                    //page 23
                    { school: "HORIZON MEDICAL INSTITUTE", programs: ["SURGICAL TECH", "MEDICAL ASSISTING", "PHLEBOTOMY TECHNICIAN"]},
                    { school: "DREXEL UNIVERSITY", programs: ["NURSING-MSN", "NURSE PRACTITIONER"]},
                    { school: "WASHINGTON UNIVERSITY SCHOOL OF MEDICINE", programs: ["AUDIOLOGY AND COMMUNICATION SCIENCES"]},
                    { school: "ST GEORGES UNIVERSITY SCHOOL OF MEDICINE", programs: ["DOCTOR OF MEDICINE"]},
                    { school: "UNIVERSITY OF PROVIDENCE", programs: ["SURGICAL TECHNOLOGY", "MEDICAL ASSISTANT", "HEALTHCARE ADMINISTRATION", "NURSING", "PHARMACY TECHNICIAN", "NURSING-MSN"]},
                    { school: "OHSU SCHOOL OF NURSING", programs: ["NURSE-MIDWIFERY"]},
                    { school: "QUINNIPIAC UNIVERSITY", programs: ["NURSING-BACHELOR OF SCIENCE", "NURSING-MASTERS OF SCIENCE", "NURSING-DNP"]},
                    { school: "INDIANA UNIVERSITY", programs: ["DOCTORATE IN AUDIOLOGY"]},
                    { school: "UNIVERSITY OF MEDICINE AND HEALTH SCIENCES", programs: ["DOCTOR OF MEDICINE"]},
                    { school: "COLUMBIA UNIVERSITY", programs: ["MASTER OF SOCIAL WORK"]},
                    //page 24
                    { school: "TOMS FAC", programs: ["ADMIN"]},
                    { school: "WASHINGTON STATE UNIVERSITY-PULLMAN", programs: ["ATHLETIC TRAINING", "KINESIOLOGY"]},
                    { school: "EASTERN VIRGINIA MEDICAL SCHOOL", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "BE WELL SOLUTIONS", programs: ["DIETETIC INTERNSHIP"]},
                    { school: "WASHINGTON UNIVERSITY", programs: ["DOCTOR OF PHYSICAL THERAPY", "PHYSICAL THERAPY"]},
                    { school: "PENN FOSTER COLLEGE", programs: ["Medical Assistant"]},
                    { school: "BAYLOR UNIVERSITY", programs: ["DOCTOR OF PHYSICAL THERAPY", "COMMUNICATION SCIENCE AND DISORDERS", "DOCTOR OF NURSE PRACTICE", "OCCUPATIONAL THERAPY", "FAMILY NURSE PRACTITIONER"]},
                    { school: "NORTH ARKANSAS COLLEGE", programs: ["MEDICAL LABORATORY TECHNOLOGY"]},
                    { school: "UNION COLLEGE", programs: ["PHYSICIAN ASSISTANT MASTERS", "OCCUPATIONAL THERAPY ASSISTANT"]},
                    { school: "PIMA MEDICAL INSTITUTE-TUSCON", programs: ["RADIOGRAPHY"]},
                    //page 25
                    { school: "MIDWESTERN UNIVERSITY-AZ", programs: ["OSTEOPATHIC MEDICINE", "SPEECH LANGUAGE PATHOLOGY", "PHARMACY"]},
                    { school: "THE UNIVERSITY OF TENNESEE", programs: ["PHYSICIAN EMBA"]},
                    { school: "THE UNIVERSITY OF FINDLAY", programs: ["DOCTOR PHYSICAL THERAPY"]},
                    { school: "MID-PLAINS COMMUNITY COLLEGE", programs: ["EMERGENCY MEDICAL SERVICES"]},
                    { school: "LOYOLA UNIVERSITY OF NEW ORLEANS", programs: ["NURSING-MASTERS"]},
                    { school: "UNITED STATES UNIVERSITY", programs: ["MASTER OF SCIENCE IN NURSING", "FAMILY NURSE PRACTITIONER"]},
                    { school: "NORTH DAKOTA STATE UNIVERSITY", programs: ["LPN-BSN"]},
                    { school: "EMORY & HENRY COLLEGE", programs: ["DOCTOR OF PHYSICAL THERAPY"]},
                    { school: "GALVESTON COLLEGE", programs: ["COMPUTED TOMOGRAPHY", "MRI"]},
                    { school: "ASPEN UNIVERSITY", programs: ["NURSING-MSN", "DOCTOR OF NURSING PRACTICE"]},
                    //page 26
                    { school: "WEST COAST ULTRASOUND INSTITUTE", programs: ["CARDIOVASCULAR SONOGRAPHY"]},
                    { school: "WESTERN NEW MEXICO UNIVERSITY", programs: ["MASTER OF SOCIAL WORK"]},
                    { school: "SPARROW HEALTHCARE EDUCATION", programs: ["ADMIN", "NURSING ASSISTANT - CERTIFIED"]},
                    { school: "SETON HILL UNIVERSITY", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "DIVINE CNA TRAINING", programs: ["CERTIFIED NURSING ASSISTANT"]},
                    { school: "EMORY UNIVERSITY SCHOOL OF NURSING", programs: ["ACCELERATED BACHELOR OF SCIENCE NURSING", "DISTANCE ACCELERATED BSN"]},
                    { school: "PSHMC SCHOOL OF MEDICAL LABORATORY SCIENCE", programs: ["MEDICAL LABRATORY SCIENCES"]},
                    { school: "WEST PIERCE FIRE & RESCUE 31", programs: ["EMT-BASIC"]},
                    { school: "UNIVERSITY OF MARY", programs: ["NURSING-BACHELOR", "FNP"]},
                    { school: "UNIVERSITY OF SOUTH ALABAMA", programs: ["PSYCHIATRIC MENTAL HEALTH NURSE PRACTITIONER", "PHYSICAL THERAPY"]},
                    //page 27
                    { school: "FOX IN THE KITCHEN GTN", programs: ["Dietetic Internship"]},
                    { school: "UNIVERSITY OF JAMESTOWN", programs: ["NURSING-RN-BSN"]},
                    { school: "UNIVERSITY OF ARKANSAS", programs: ["NURSING - LPN-BSN"]},
                    { school: "GULF COAST DIETETIC INTERNSHIP", programs: ["DIETETIC INTERNSHIP"]},
                    { school: "BURRELL COLLEGE OF OSTEOPATHIC MEDICINE", programs: ["OSTEOPATHIC MEDICINE"]},
                    { school: "UNIVERSITY OF PHOENIX", programs: ["NURSING-MASTER OF SCIENCE"]},
                    { school: "GALLAUDET UNIVERSITY", programs: ["AUDIOLOGY"]},
                    { school: "UNIVERSITY OF MASSACHUSETTS", programs: ["DOCTOR NURSE PRACTITIONER"]},
                    { school: "SAN JOAQUIN VALLEY COLLEGE", programs: ["MEDICAL ASSISTANT"]},
                    { school: "CHATHAM UNIVERSITY", programs: ["DOCTOR NURSE PRACTICE", "NUSING-MSN"]},
                    //page 28
                    { school: "WASHINGTON STATE DEPARTMENT OF HEALTH", programs: ["DIRECTOR OF NURSING EDUCATION"]},
                    { school: "EDGEWOOD COLLEGE", programs: ["CLINICAL NURSE SPECIALIST"]},
                    { school: "UNIVERSITY OF WASHINGTON SCHOOL OF PUBLIC HEALTH", programs: ["PUBLIC HEALTH-GLOBAL HEALTH"]},
                    { school: "ROCKY VISTA UNIVERSITY LLC", programs: ["DOCTOR OF OSTEOPATHIC MEDICINE"]},
                    { school: "GEISINGER COMMONWEALTH", programs: ["SCHOOL OF MEDICINE"]},
                    { school: "NORTHWEST NAZARENE UNIVERSITY", programs: ["FAMILY NURSE PRACTIONER", "RN TO BSN", "MASTERS SCIENCE NURSING LEADERSHIP AND EDUCATION"]},
                    { school: "UNIVERSITY OF WISCONSIN-LA CROSSE", programs: ["CLINICAL EXERCISE PHYSILOGY"]},
                    { school: "MERIDIAN INSTITUTE OF SURGICAL ASSISTING", programs: ["SURGICAL FIRST ASSIST"]},
                    { school: "AMERICAN COLLEGE OF EDUCATION", programs: ["BACHELOR OF SCIENCE IN HEALTHCARE ADMINISTRATION"]},
                    { school: "INSTITUTE OF HEALTH SCIENCES", programs: ["NEURODIAGNOSTIC TECHNOLOGY"]},
                    //page 29
                    { school: "BELLIN COLLEGE", programs: ["OCCUPATIONAL MANUAL PHYSICAL THERAPY"]},
                    { school: "UNIVERSITY OF DENVER", programs: ["GRADUATE SCHOOL OF SOCIAL WORK"]},
                    { school: "COLORADO STATE UNIVERSITY", programs: ["OCCUPATIONAL THERAPY"]},
                    { school: "UNIVERSITY OF UTAH", programs: ["GRADUATE PROGRAM IN GENETIC COUNSELING"]},
                    { school: "MCPHS UNIVERSITY", programs: ["GRADUATE NURSING PROGRAM", "PHARMACY"]},
                    { school: "ANDREWS UNIVERSITY", programs: ["DOCTOR OF PHYSICAL THERAPY"]},
                    { school: "UWT", programs: ["NURSING-BACHELOR", "PHYSICAL THERAPY"]},
                    { school: "SAINT JOSEPHS COLLEGE", programs: ["NURSING-MSN"]},
                    { school: "EMERSON COLLEGE", programs: ["COMMUNICATION SCIENCE DISORDERS"]},
                    { school: "WASHBURN UNIVERSITY", programs: ["MAGNETIC RESONANCE IMAGINE"]},
                    //page 30
                    { school: "SPRING ARBOR UNIVERSITY", programs: ["NURSING - MASTERS"]},
                    { school: "UNIVERSITY OF FLORIDA", programs: ["MASTER OF PUBLIC HEALTH"]},
                    { school: "EAST CAROLINA UNIVERSITY", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "WSU CONTACT TRACING", programs: ["CONTACT TRACING"]},
                    { school: "BETHEL UNIVERSITY", programs: ["PHYSICIAN ASSISTANT", "NURSE MIDWIFERY"]},
                    { school: "DES MOINES UNIVERSITY", programs: ["PHYSICIAN ASSISTANT", "DOCTOR OF OSTEOPATHIC MEDICINE"]},
                    { school: "ELON UNIVERSITY", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "UNIVERSITY OF ST AUGUSTINE FOR HEALTH SCIENCES", programs: ["OCCUPATIONAL THERAPY", "NURSING", "DOCTOR OF PHYSICAL THERAPY"]},
                    { school: "FORT HAYS STATE UNIVERSITY", programs: ["NURSING - MSN"]},
                    { school: "AMERICAN SENTINEL UNIVERSITY", programs: ["NURSING"]},
                    //page 31
                    { school: "UNIVERSITY OF WISCONSIN-RIVER FALLS", programs: ["CLINICAL EXERCISE PHYSIOLOGY"]},
                    { school: "HEALTH CARE APPRENTICESHIP CONSORTIUM", programs: ["CERTIFIED NURSING ASSISTANT"]},
                    { school: "SAMUEL MERRITT UNIVERSITY", programs: ["PHYSICAL THERAPY"]},
                    { school: "PHLEBOTOMY CAREER TRAINING", programs: ["EKG"]},
                    { school: "CROWDER COLLEGE", programs: ["EMS EDUCATION", "PARAMEDIC"]},
                    { school: "NORTHSHORE SCHOOL DISTRICT", programs: ["MEDICAL PROFESSIONS ACADEMY"]},
                    { school: "NIGHTINGALE NURSING COLLEGE", programs: ["NURSING - BSN"]},
                    { school: "KOOTENAI COUNTY EMS SYSTEM", programs: ["EMS"]},
                    { school: "DRAKE UNIVERSITY", programs: ["PHARMACY"]},
                    { school: "BRANDMAN UNIVERSITY", programs: ["SOCIAL WORK"]},
                    //page 32
                    { school: "UNIVERSITY OF WASHINGTON SCHOOL OF MEDICINE-SPOKANE", programs: ["PSYCHIATRY"]},
                    { school: "UNIVERSITY OF NORTH CAROLINA WILMINGTON", programs: ["MSN NURSE EDUCATOR"]},
                    { school: "BAY PATH UNIVERSITY", programs: ["GENETIC COUNSELING"]},
                    { school: "CLARKSON COLLEGE", programs: ["NURSING MSN-DNP", "PHYSICAL THERAPY"]},
                    { school: "BLOSSOM HEALTH CARE LLC", programs: ["CNA TRAINING"]},
                    { school: "LOUISANA STATE UNIVERSITY", programs: ["MASTER OF SOCIAL WORK"]},
                    { school: "ROSEMAN UNIVERSITY OF HEALTH SCIENCES", programs: ["PHARMACY"]},
                    { school: "SHENANDOAH UNIVERSITY", programs: ["NONTRADITIONAL DOCTOR OF PHARMACY"]},
                    { school: "UNIVERSITY OF LYNCHBURG", programs: ["PA MEDICINE"]},
                    { school: "HEALTH PROFESSIONALS INSTITUTE", programs: ["NURSING ASSISTANT", "EKG TECHNICIAN PTROGRAM"]},
                    //page 33
                    { school: "EASTERN KENTUCKY UNIVERSITY", programs: ["PSYCHIATRIC MENTAL HEALTH NURSE PRACTITIONER", "RURAL HEALTH FAMILY NURSE PRACTITIONER", "NURSING"]},
                    { school: "LACTATION EDUCATION RESOURCES", programs: ["PATHWAY THREE CLINICAL INTERNSHIP PROGRAM"]},
                    { school: "PURDUE UNIVERSITY COLLEGE OF PHARMACY", programs: ["DOCTOR OF PHARMACY"]},
                    { school: "UNIVERSITY OF SOUTH CAROLINA", programs: ["ATHLETIC TRAINING", "PHARMACY"]},
                    { school: "NEW YORK UNIVERSITY", programs: ["SPEECH LANGUAGE PATHOLOGY"]},
                    { school: "RADFORD UNIVERSITY", programs: ["FAMILY NURSE PRACTITIONER"]},
                    { school: "LAKE ERIE COLLEGE OF OSTEPATHIC MEDICINE", programs: ["SCHOOL OF PHARMACY"]},
                    { school: "UNIVERSITY OF PITTSBURGH", programs: ["OCCUPATIONAL THERAPY", "PHARMACY"]},
                    { school: "UNIVERSITY OF DELAWARE", programs: ["PSYCHIATRIC-MENTAL HEALTH NURSE PRACTITIONER"]},
                    { school: "UW PEN TEST", programs: ["PEN TEST"]},
                    //page 34
                    { school: "KOOTENAI TECHNICAL EDUCATION CAMPUS", programs: ["HEALTH PROFESSIONS", "CERTIFIED MEDICAL ASSITANT"]},
                    { school: "IDAHO COLLEGE OF OSTEPATHIC MEDICINE", programs: ["DOCTOR OF OSTEOPATHIC MEDICINE"]},
                    { school: "CORNELL UNIVERSITY", programs: ["HEALTH ADMINISTRATION"]},
                    { school: "DAKOTA COLLEGE AT BOTTINEAU", programs: ["MEDICAL ASSISTANT"]},
                    { school: "SOFIA UNIVERSITY", programs: ["COUNSELING PSYCHOLOGY"]},
                    { school: "UNIVERSITY OF AKRON", programs: ["SPEECH-LANGUAGE PATHOLOGY"]},
                    { school: "EASTERN NEW MEXICO UNIVERSITY", programs: ["COMMUNICATIVE DISORDERS"]},
                    { school: "ARIZONA HEART FOUNDATION", programs: ["CARDIAC SONOGRAPHY"]},
                    { school: "TOURO UNIVERSITY NEVADA", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "UNIVERSITY OF NORTHERN COLORADO", programs: ["NUTRITION AND DIETETICS"]},
                    //page 35
                    { school: "SMITH COLLEGE SCHOOL FOR SOCIAL WORK", programs: ["SOCIAL WORK"]},
                    { school: "MARQUETTE UNIVERSITY", programs: ["OCCUPATIONAL THERAPY"]},
                    { school: "UNIVERSITY OF COLORADO", programs: ["ADULT GERONTOLOGICAL PRIMARY CARE NURSE PRACTITIONER"]},
                    { school: "MERCY COLLEGE OF HEALTH SCIENCES", programs: ["MEDICAL ASSISTING - VMFH"]},
                    { school: "UNION INSTITUTE AND UNIVERSITY", programs: ["DIETETICS"]},
                    { school: "PORTLAND STATE UNIVERSITY", programs: ["SPEECH AND HEARING SCIENCES"]},
                    { school: "UNIVERSITY OF NEBRASKA MEDICAL CENTER", programs: ["CLINICAL PERFUSION EDUCATION"]},
                    { school: "UNIVERSITY OF KENTUCKY", programs: ["SOCIAL WORK"]},
                    { school: "SAINT MARYS COLLEGE", programs: ["DOCTOR OF NURSING PRACTICE"]},
                    { school: "UEI COLLEGE", programs: ["MEDICAL ASSISTING", "MEDICAL ASSISTING - MC", "MEDICAL ASSISTING - MCINDIGO"]},
                    //page 36
                    { school: "BODYMECHANICS MASSAGE SCHOOL", programs: ["MASSAGE THERAPY"]},
                    { school: "BELLINGHAM TECHNICAL COLLEGE", programs: ["RADIOLOGIC TECHNOLOGY PROGRAM"]},
                    { school: "MISSOURI STATE UNIVERSITY", programs: ["DOCTOR OF NURSE ANESTHESIA PRACTICE", "SOCIAL WORK"]},
                    { school: "LE MOYNE COLLEGE", programs: ["PHYSICIAN ASSISTANT"]},
                    { school: "DENVER COLLEGE OF NURSING", programs: ["NURSING"]},
                    { school: "REGIS COLLEGE", programs: ["PSYCHIATRIC MENTAL HEALTH NURSE PRACTITIONER"]},
                    { school: "EASTSIDE NURSE AIDE TRAINING", programs: ["CERTIFIED NURSING ASSISTANT"]},
                    { school: "MEDICAL SCHOOL FOR INTERNATIONAL HEALTH AT BEN GURION UNIVERSITY", programs: ["MD INTERNATIONAL HEALTH"]},
                    { school: "CAREERSTEP", programs: ["PHLEBOTOMY"]},
                    { school: "IDAHO MEDICAL ACADEMY", programs: ["ADVANCED EMERGENCY MEDICAL TECHNICIAN"]},
                    //page 37
                    { school: "WILLIAM CAREY UNIVERSITY", programs: ["OSTEOPATHIC MEDICINE"]},
                    { school: "UNIVERSITY OF INDIANAPOLIS", programs: ["NURSING - BACHELOR OF SCIENCE"]},
                    { school: "KANSAS CITY UNIVERSITY", programs: ["DOCTOR OF OSTEOPATHIC MEDICINE"]},
                    { school: "UNIVERSITY OF COLORADO COLORADO SPRINGS", programs: ["NURSING - DOCTORATE"]},
                    { school: "THOMAS JEFFERSON UNIVERSITY", programs: ["MIDWIFERY & WOMENS HEALTH"]},
                    { school: "UNIVERSITY OF NEVADA, RENO", programs: ["APRN"]},
                    { school: "UNIVERSITY OF TEXAS - EL PASO", programs: ["DOCTOR OF PHYSICAL THERAPY"]},
                    { school: "PROVIDENCE HEALTH TRAINING", programs: ["PARAMEDIC"]},
                    { school: "PULSE RADIOLOGY EDUCATION", programs: ["MAGNETIC RESONANCE IMAGING"]},
                    { school: "OHIO STATE UNIVERSITY", programs: ["BIOETHICS"]},
                ]}
            /> */}
      </div>
    </section>
  );
}
