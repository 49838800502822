import { useState, useRef } from "react";
import classNames from "classnames";
import styles from "./Schools.module.css";
import { InstructionItem, InputBox, TextArea, AddButton, Button } from "@cpnw/ui";

const Schools = () => {
  const refs = useRef([]);
  const [errors, setErrors] = useState([]);
  const [info, setInfo] = useState(createDefaultInfo());

  const onSubmit = (e) => {
    e.preventDefault();

    info.programs.map((m) => (m.isTouched = true));
    setInfo({ ...info });

    let { errors, focusField } = validate();

    setErrors([...errors]);
    if (errors.length > 0) {
      if (focusField && refs.current && refs.current[focusField]) {
        refs.current[focusField].focus();
      }

      return;
    }

    //TODO POST API HERE
    console.log("info", info);
    //Reset Forms
    setInfo({ ...createDefaultInfo() });
    //Redirect to Home
    window.location.pathname = "/";
  };

  const onAddExtraPrograms = () => {
    info.programs = [...info.programs, createDefaultProgram()];
    setInfo({ ...info });
  };

  const onChange = (field, value, elementAt, elementAtField) => {
    if (field == "primaryContactPhone") {
      info[field] = normalizePhone(value, info[field]);
    } else if (isNaN(elementAt) || elementAt < 0) {
      info[field] = value;
    } else {
      info[field][elementAt][elementAtField] = value;
      info[field][elementAt].isTouched = true;
    }

    setInfo({ ...info });
  };

  const validateWhenEdit = (field, value, elementAt, elementAtField) => {
    let error = null;

    if (field == "state") {
      if (!value) {
        error = ["state", "State must be 2 letters and is required."];
      } else if (value.trim().length > 2) {
        error = [
          "state",
          "The field State must be a string with a maximum length of 2.",
        ];
      }
    } else if (field == "primaryContactEmail") {
      if (!value) {
        error = ["primaryContactEmail", "Primary Contact Email is required."];
      } else if (!RG_Email.test(value)) {
        error = [
          "primaryContactEmail",
          "The Primary Contact Email field is not a valid e-mail address.",
        ];
      }
    } else if (field == "programs" && elementAtField == "coordinatorEmail") {
      if (!value) {
        error = [
          `programs[${elementAt}].coordinatorEmail`,
          "The Primary Contact Email is required.",
        ];
      } else if (!RG_Email.test(value)) {
        error = [
          `programs[${elementAt}].coordinatorEmail`,
          "The Primary Contact Email field is not a valid e-mail address.",
        ];
      }
    } else if (
      field == "programs" &&
      elementAtField == "backupCoordinatorEmail"
    ) {
      if (!value) {
        error = [
          `programs[${elementAt}].backupCoordinatorEmail`,
          "Backup Coordinator Email is required.",
        ];
      } else if (!RG_Email.test(value)) {
        error = [
          `programs[${elementAt}].backupCoordinatorEmail`,
          "The Backup Coordinator Email field is not a valid e-mail address.",
        ];
      }
    }

    let currentErrors = [];
    if (isNaN(elementAt) || elementAt < 0) {
      currentErrors = errors.filter((m) => m[0] != field);
    } else {
      currentErrors = errors.filter(
        (m) => m[0] != `${field}[${elementAt}].${elementAtField}`
      );
    }

    if (error) setErrors([...currentErrors, error]);
    else setErrors([...currentErrors]);
  };

  const validate = () => {
    let errors = [];
    let focusField = null;

    if (!info.eduFacilityName) {
      errors.push([
        "eduFacilityName",
        "Educational Facility Name is required.",
      ]);
      focusField = focusField || "eduFacilityName";
    }

    if (!info.eduFacilityMailing) {
      errors.push([
        "eduFacilityMailing",
        "Facility Mailing Address is required.",
      ]);
      focusField = focusField || "eduFacilityMailing";
    }

    if (!info.city) {
      errors.push(["city", "City is required."]);
      focusField = focusField || "city";
    }

    if (!info.state) {
      errors.push(["state", "State must be 2 letters and is required."]);
      focusField = focusField || "state";
    } else if (info.state.trim().length > 2) {
      errors.push([
        "state",
        "The field State must be a string with a maximum length of 2.",
      ]);
      focusField = focusField || "state";
    }

    if (!info.zipcode) {
      errors.push(["zipcode", "Zipcode is required."]);
      focusField = focusField || "zipcode";
    }

    if (!info.primaryContactName) {
      errors.push(["primaryContactName", "Primary Contact Name is required."]);
      focusField = focusField || "primaryContactName";
    }

    if (!info.primaryContactEmail) {
      errors.push([
        "primaryContactEmail",
        "Primary Contact Email is required.",
      ]);
      focusField = focusField || "primaryContactEmail";
    } else if (!RG_Email.test(info.primaryContactEmail)) {
      errors.push([
        "primaryContactEmail",
        "The Primary Contact Email field is not a valid e-mail address.",
      ]);
      focusField = focusField || "primaryContactEmail";
    }

    if (!info.primaryContactPhone) {
      errors.push([
        "primaryContactPhone",
        "Primary Contact Phone is required.",
      ]);
      focusField = focusField || "primaryContactPhone";
    }

    info.programs.map((m, index) => {
      if (index > 0) return;

      if (!m.eduProgramName) {
        errors.push([
          `programs[${index}].eduProgramName`,
          "Educational Program Name is required.",
        ]);
        focusField = focusField || `programs[${index}].eduProgramName`;
      }
      if (!m.degreeAwarded) {
        errors.push([
          `programs[${index}].degreeAwarded`,
          "Degree Awarded is required.",
        ]);
        focusField = focusField || `programs[${index}].degreeAwarded`;
      }
      if (!m.coordinatorName) {
        errors.push([
          `programs[${index}].coordinatorName`,
          "Primary Coordinator Name is required.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorName`;
      }
      if (!m.coordinatorEmail) {
        errors.push([
          `programs[${index}].coordinatorEmail`,
          "Primary Coordinator Email is required.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorEmail`;
      } else if (!RG_Email.test(m.coordinatorEmail)) {
        errors.push([
          `programs[${index}].coordinatorEmail`,
          "The Primary Coordinator Email field is not a valid e-mail address.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorEmail`;
      }

      if (!m.backupCoordinatorName) {
        errors.push([
          `programs[${index}].backupCoordinatorName`,
          "Backup Coordinator Name is required.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorName`;
      }
      if (!m.backupCoordinatorEmail) {
        errors.push([
          `programs[${index}].backupCoordinatorEmail`,
          "Backup Coordinator Email is required.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorEmail`;
      } else if (!RG_Email.test(m.backupCoordinatorEmail)) {
        errors.push([
          `programs[${index}].backupCoordinatorEmail`,
          "The Backup Coordinator Email field is not a valid e-mail address.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorEmail`;
      }
    });

    if (!info.clinicalSites) {
      errors.push(["clinicalSites", "Clinical Sites is required."]);
      focusField = focusField || "clinicalSites";
    }

    info.programs.map((m, index) => {
      if (index == 0) return;
      if (!m.isTouched) return;

      if (!m.eduProgramName) {
        errors.push([
          `programs[${index}].eduProgramName`,
          "Educational Program Name is required.",
        ]);
        focusField = focusField || `programs[${index}].eduProgramName`;
      }
      if (!m.degreeAwarded) {
        errors.push([
          `programs[${index}].degreeAwarded`,
          "Degree Awarded is required.",
        ]);
        focusField = focusField || `programs[${index}].degreeAwarded`;
      }
      if (!m.coordinatorName) {
        errors.push([
          `programs[${index}].coordinatorName`,
          "Primary Coordinator Name is required.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorName`;
      }
      if (!m.coordinatorEmail) {
        errors.push([
          `programs[${index}].coordinatorEmail`,
          "Primary Coordinator Email is required.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorEmail`;
      } else if (!RG_Email.test(m.coordinatorEmail)) {
        errors.push([
          `programs[${index}].coordinatorEmail`,
          "The Primary Coordinator Email field is not a valid e-mail address.",
        ]);
        focusField = focusField || `programs[${index}].coordinatorEmail`;
      }

      if (!m.backupCoordinatorName) {
        errors.push([
          `programs[${index}].backupCoordinatorName`,
          "Backup Coordinator Name is required.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorName`;
      }
      if (!m.backupCoordinatorEmail) {
        errors.push([
          `programs[${index}].backupCoordinatorEmail`,
          "Backup Coordinator Email is required.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorEmail`;
      } else if (!RG_Email.test(m.backupCoordinatorEmail)) {
        errors.push([
          `programs[${index}].backupCoordinatorEmail`,
          "The Backup Coordinator Email field is not a valid e-mail address.",
        ]);
        focusField = focusField || `programs[${index}].backupCoordinatorEmail`;
      }
    });

    return { errors, focusField };
  };

  const getError = (field, isShowErrorWhenEdit) => {
    let error = errors?.find((m) => m[0] == field);

    if (!isShowErrorWhenEdit) return error && !info[field] ? error[1] : <br />;
    else return error ? error[1] : <br />;
  };

  const getErrorArray = (
    field,
    elementAt,
    elementAtField,
    isShowErrorWhenEdit
  ) => {
    let errorField = `${field}[${elementAt}].${elementAtField}`;

    let error = errors?.find((m) => m[0] == errorField);
    if (!isShowErrorWhenEdit)
      return error && !info[field][elementAt][elementAtField] ? (
        error[1]
      ) : (
        <br />
      );
    else return error ? error[1] : <br />;
  };

  const renderProgram = (elementAt, showInfo) => (
    <div className={styles.formSection}>
      <legend>Program Information</legend>
      {showInfo && (
        <p>
          CPNW accounts are organized according to degree/program. You must
          request an account for each degree/program. Just click the{" "}
          <span className={styles.addProgramSpan}>+Add Program</span> link at
          the bottom of the form to add additional account requests. You must
          identify an account coordinator and a backup coordinator for each
          program. Coordinators may manage more than one program. If you don't
          currently have a backup coordinator, you must specify your primary
          coordinator as the backup.
        </p>
      )}
      <div className={styles.formFill}>
        <span className={styles.required}>**All fields are required.</span>
        <div className={styles.formFields}>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].eduProgramName`] = el)
              }
              value={info.programs[elementAt].eduProgramName}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "eduProgramName"
                );
              }}
              placeholder={"Educational Program Name"}
              tip={"Enter your Program Name(e.g. Pharmacy)."}
            />
            <span className={styles.error}>
              {getErrorArray("programs", elementAt, "eduProgramName")}
            </span>
          </div>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].degreeAwarded`] = el)
              }
              value={info.programs[elementAt].degreeAwarded}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "degreeAwarded"
                );
              }}
              placeholder={"Degree Awarded"}
              tip={"Enter the Degree Awarded(e.g. PharmD)."}
            />
            <span className={styles.error}>
              {getErrorArray("programs", elementAt, "degreeAwarded")}
            </span>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].coordinatorName`] = el)
              }
              value={info.programs[elementAt].coordinatorName}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "coordinatorName"
                );
              }}
              placeholder={"Primary Coordinator Name"}
              tip={"Enter the Primary Coordinator Name."}
            />
            <span className={styles.error}>
              {getErrorArray("programs", elementAt, "coordinatorName")}
            </span>
          </div>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].coordinatorEmail`] = el)
              }
              value={info.programs[elementAt].coordinatorEmail}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "coordinatorEmail"
                );
                validateWhenEdit(
                  "programs",
                  e.target.value,
                  elementAt,
                  "coordinatorEmail"
                );
              }}
              placeholder={"Primary Coordinator Email"}
              tip={"Enter the Primary Coordinator Email."}
            />
            <span className={styles.error}>
              {getErrorArray("programs", elementAt, "coordinatorEmail", true)}
            </span>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].backupCoordinatorName`] =
                  el)
              }
              value={info.programs[elementAt].backupCoordinatorName}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "backupCoordinatorName"
                );
              }}
              placeholder={"Backup Coordinator Name"}
              tip={"Enter the Backup Coordinator Name."}
            />
            <span className={styles.error}>
              {getErrorArray("programs", elementAt, "backupCoordinatorName")}
            </span>
          </div>
          <div className={styles.fieldset}>
            <InputBox
              ref={(el) =>
                (refs.current[`programs[${elementAt}].backupCoordinatorEmail`] =
                  el)
              }
              value={info.programs[elementAt].backupCoordinatorEmail}
              setValue={(e) => {
                onChange(
                  "programs",
                  e.target.value,
                  elementAt,
                  "backupCoordinatorEmail"
                );
                validateWhenEdit(
                  "programs",
                  e.target.value,
                  elementAt,
                  "backupCoordinatorEmail"
                );
              }}
              placeholder={"Backup Coordinator Email"}
              tip={"Enter the Backup Coordinator Email."}
            />
            <span className={styles.error}>
              {getErrorArray(
                "programs",
                elementAt,
                "backupCoordinatorEmail",
                true
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.schools}>
      <h1>School Instructions</h1>
      <div>
        <div className={styles.item}>
          <InstructionItem
            header={"Request An Account"}
            info={
              "If your school or program has an affiliation agreement with a CPNW affiliated healthcare facility, you may request an account by completing the form below and clicking submit. An account will be created and CPNW Technical Support will contact you to schedule account management training. Please allow 48 hours to receive a response to your application. DO NOT REQUEST AN ACCOUNT UNTIL YOU HAVE A CONFIRMED PLACEMENT AT A CPNW PARTNER FACILITY."
            }
            step={1}
          />
        </div>
        <div className={styles.item}>
          <InstructionItem
            header={"Identify Account Managers"}
            info={
              "Schools are required to have two individuals assigned to serve as coordinators to oversee student accounts. Coordinators have two primary responsibilities: instruct students how to create and manage their own accounts, and to verify student completion of on-boarding requirements. Detailed information is provided during coordinator training."
            }
            step={2}
          />
        </div>
        <div className={styles.item}>
          <InstructionItem
            header={"Plan Ahead"}
            info={
              "Schools should have opened their account and completed training no later than 30 days before the start of student clinical placements. The account set up and training process can take up to a week. Training is scheduled in the order a request is received and waiting time is typically three business days."
            }
            step={3}
          />
        </div>
        <div className={styles.item}>
          <InstructionItem
            header={"Fees For Service"}
            info={
              "There is an annual user fee based on level of service. This fee is collected during the account creation process. Faculty who teach at a member clinical site are not currently assessed a user fee. As well, schools coordinators are not assessed a user or subscriber fee."
            }
            step={4}
          />
        </div>
      </div>
      <div>
        <h2>School Account Request</h2>
        <form className={styles.form} onSubmit={(e) => onSubmit(e)}>
          <input className={styles.submitHidden} type="submit" hidden />
          <div className={styles.formSection}>
            <legend>Higher Education Institution</legend>
            <p>
              Please complete the required information for your educational
              institution below. This information will be used for official
              correspondence from CPNW. The primary contact should be the
              individual responsible for oversight of the program accounts you
              are requesting.
            </p>
            <div className={styles.formFill}>
              <span className={styles.required}>
                **All fields are required.
              </span>
              <div className={styles.formFields}>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["eduFacilityName"] = el)}
                    placeholder={"Parent Educational Facility Name"}
                    tip={
                      "Enter your parent facility name(e.g.Seattle University)."
                    }
                    value={info.eduFacilityName}
                    setValue={(e) => {
                      onChange("eduFacilityName", e.target.value);
                    }}
                  />
                  <span className={styles.error}>
                    {getError("eduFacilityName")}
                  </span>
                </div>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["eduFacilityMailing"] = el)}
                    value={info.eduFacilityMailing}
                    setValue={(e) => {
                      onChange("eduFacilityMailing", e.target.value);
                    }}
                    placeholder={"Educational Facility Mailing Address"}
                    tip={"Enter your facility mailing address."}
                  />
                  <span className={styles.error}>
                    {getError("eduFacilityMailing")}
                  </span>
                </div>
              </div>
              <div className={styles.formFields}>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["city"] = el)}
                    value={info.city}
                    setValue={(e) => {
                      onChange("city", e.target.value);
                    }}
                    placeholder={"City"}
                    tip={"Enter your facility city."}
                  />
                  <span className={styles.error}>{getError("city")}</span>
                </div>
                <div
                  className={classNames([styles.formFields, styles.fieldset])}
                >
                  <div className={styles.fieldset}>
                    <InputBox
                      ref={(el) => (refs.current["state"] = el)}
                      value={info.state}
                      setValue={(e) => {
                        onChange("state", e.target.value);
                        validateWhenEdit("state", e.target.value);
                      }}
                      placeholder={"State"}
                      tip={"Enter your facility state."}
                    />
                    <span className={styles.error}>
                      {getError("state", true)}
                    </span>
                  </div>
                  <div className={styles.fieldset}>
                    <InputBox
                      ref={(el) => (refs.current["zipcode"] = el)}
                      value={info.zipcode}
                      setValue={(e) => {
                        onChange("zipcode", e.target.value);
                      }}
                      placeholder={"Zipcode"}
                      tip={"Enter your facility zipcode."}
                    />
                    <span className={styles.error}>{getError("zipcode")}</span>
                  </div>
                </div>
              </div>
              <div className={styles.formFields}>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["primaryContactName"] = el)}
                    value={info.primaryContactName}
                    setValue={(e) => {
                      onChange("primaryContactName", e.target.value);
                    }}
                    placeholder={"Primary Contact Name"}
                    tip={"Enter your Primary Contact Name."}
                  />
                  <span className={styles.error}>
                    {getError("primaryContactName")}
                  </span>
                </div>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["primaryContactEmail"] = el)}
                    value={info.primaryContactEmail}
                    setValue={(e) => {
                      onChange("primaryContactEmail", e.target.value);
                      validateWhenEdit("primaryContactEmail", e.target.value);
                    }}
                    placeholder={"Primary Contact Email"}
                    tip={"Enter your Primary Contact Email."}
                  />
                  <span className={styles.error}>
                    {getError("primaryContactEmail", true)}
                  </span>
                </div>
                <div className={styles.fieldset}>
                  <InputBox
                    ref={(el) => (refs.current["primaryContactPhone"] = el)}
                    value={info.primaryContactPhone}
                    setValue={(e) => {
                      onChange("primaryContactPhone", e.target.value);
                    }}
                    placeholder={"Primary Contact Phone"}
                    tip={"Enter your Primary Contact Phone."}
                  />
                  <span className={styles.error}>
                    {getError("primaryContactPhone")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div key={`programs_${0}`}>{renderProgram(0, true)}</div>
          <div className={styles.formSection}>
            <div>
              <p>
                Please list the CPNW member clinical sites where your students
                have <i>confirmed placements in the current academic year.</i>{" "}
                <span className={styles.required}>**Required</span>
              </p>
              <div>
                <TextArea
                  ref={(el) => (refs.current["clinicalSites"] = el)}
                  value={info.clinicalSites}
                  setValue={(e) => {
                    onChange("clinicalSites", e.target.value);
                  }}
                  tip={
                    "List CPNW clinical sites where your students are being placed."
                  }
                />
                <span className={styles.error}>
                  {getError("clinicalSites")}
                </span>
              </div>
            </div>
            <div>
              <p>
                Please use the text area below for any additional information or
                questions:
              </p>
              <TextArea
                value={info.additionalInformation}
                setValue={(e) => {
                  onChange("additionalInformation", e.target.value);
                }}
                tip={"Additional Information."}
              />
            </div>
          </div>
          {info.programs.length > 1
            ? info.programs.map(
                (extra, index) =>
                  index > 0 && (
                    <div key={`programs_${index}`}>
                      {renderProgram(index, false)}
                    </div>
                  )
              )
            : null}
          <div>
            <AddButton
              className={styles.addProgramBtn}
              label={"Add Program"}
              onClick={(e) => {
                e.preventDefault();
                onAddExtraPrograms();
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.submitBtn}
              text={"SUBMIT"}
              onClick={(e) => onSubmit(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const createDefaultInfo = () => {
  return {
    eduFacilityName: "",
    eduFacilityMailing: "",
    city: "",
    state: "",
    zipcode: "",
    primaryContactName: "",
    primaryContactEmail: "",
    primaryContactPhone: "",
    clinicalSites: "",
    additionalInformation: "",
    programs: [createDefaultProgram(true)],
  };
};
const createDefaultProgram = (isTouched) => {
  return {
    eduProgramName: "",
    degreeAwarded: "",
    coordinatorName: "",
    coordinatorEmail: "",
    backupCoordinatorName: "",
    backupCoordinatorEmail: "",

    //For render only
    isTouched: isTouched,
  };
};

const RG_Email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const normalizePhone = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export default Schools;
