import React from "react";
import styles from "./MiscellaneousSection.module.css"
import TermHeader from "../TermHeader";
import ListHeader from "../ListHeader";

const MiscellaneousSection = () => {

    return (
        <>
            <TermHeader header={"Miscellaneous"} />
            <ListHeader number={1} header={"Changes to Terms of Use and Privacy Policy"} />
            <p className={styles.subParaAlign}>CPNW reserves the right to change these Terms of Use and Privacy Policy and other applicable policies from time to time as our scope of services expands. We will make reasonable attempts to notify you of changes we think may be important to you by e-mail.</p>
            <p className={styles.subParaLeftAlign}>All changes will be effective when published unless a different effective date is specified. Your continued use of the Services after we have posted a change to these Terms of Use and Privacy Policy will be considered your consent to the change.</p>
            <ListHeader number={2} header={"Limitation of Liability"} />
            <p className={styles.subParaAlign}>To the greatest extent permitted by law, CPNW’s liability to any person for any claims, losses, damages, harm, or costs arising from or pertaining to the use, operation, or functioning of the Services, whether arising from or pertaining to breach of contract, tort (including negligence), warranty or any other legal theory, shall be limited to the person’s direct damages and shall not include any indirect, special, incidental, consequential, punitive or exemplary damages (including but not limited to damages related to delays, loss of data, interruption of service, loss of privacy, reputational or physical harm), even if CPNW has been advised of the possibility of such damages.</p>
            <ListHeader number={3} header={"Dispute Resolution"} />
            <p className={styles.subParaAlign}>These Terms of Use and Privacy Policy shall be interpreted consistently with Federal Trade Commission (“FTC”) standards for the protection of personally identifiable information. State law issues shall be exclusively interpreted according to the laws of the State of Washington, without regard to choice of law principles.</p>
            <p className={styles.subParaLeftAlign}>If any provision of these Terms of Use and Privacy Policy is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use and Privacy Policy, which shall remain in full force and effect. No waiver of any of these Terms of Use and Privacy Policy shall be deemed a further or continuing waiver of such term or any other term.</p>
            <p className={styles.subParaLeftAlign}>All claims or disputes arising from or pertaining to these Terms of Use and Privacy Policy shall be determined by final and binding arbitration, conducted in Seattle, Washington, in accordance with the Arbitration Rules then obtaining of the American Arbitration Association. Judgment upon any arbitration award may be entered in any court of competent jurisdiction.</p>
            <ListHeader number={4} header={"Contact Information"} />
            <p className={styles.subParaAlign}>For more information about these Terms of Use and Privacy Policy please contact <a className={styles.link} href="mailto:support@cpnw.org">support@cpnw.org</a></p>
        </>
    )
}

export default MiscellaneousSection