// "use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
// exports.DomainConf = void 0;
// /**
//  * Automatically picks the correct config based on the domain the app is currently served from.
//  *
//  * Environment-specific config properties are merged with defaults if defaults are provided.
//  */
export class DomainConf {
    constructor(options = {}) {
        var _a, _b;
        const _window = (_a = options.window) !== null && _a !== void 0 ? _a : (typeof window === "undefined" ? undefined : window);
        if (((_b = _window === null || _window === void 0 ? void 0 : _window.location) === null || _b === void 0 ? void 0 : _b.hostname) == null) {
            throw new Error(`window.location.hostname is undefined. You may be running outside of a browser.`);
        }
        this._domain = this._normalize(_window.location.hostname);
    }
    /**
     * Returns the config object for the current domain.
     */
    get current() {
        const { _domain, _defaults, _config } = this;
        if (_config == null) {
            throw new Error(`No config defined for the current domain: ${_domain}`);
        }
        return Object.assign({}, _defaults, _config);
    }
    /**
     * Defines default options. These are merged into the current config unless overridden in that config.
     *
     * @param config - Object of config values
     */
    defaults(config) {
        this._defaults = config;
        return this;
    }
    /**
     * Defines a config object for a domain.
     *
     * @param domain - Domain to load this config for, e.g. 'www.example.com'
     * @param config - Object of config values
     */
    forDomain(domain, config) {
        // Set the config if domain matches the current one.
        if (this._normalize(domain) === this._domain) {
            this._config = config;
        }
        return this;
    }
    _normalize(domain) {
        return domain.toLowerCase().trim();
    }
}