import React, { useState } from 'react';
import { AccountContainer } from "hooks/useAccount";
import styles from './ResetPasswordPendingPage.module.css';
import {Loading} from "@cpnw/ui";

import ResetPasswordForm from 'components/ResetPasswordForm';

export default function ResetPasswordPendingPage(){
    const { forgotPassword } = AccountContainer.useContainer();
    const [postLoading, setPostLoading] = useState(false);
    const [resetPending, setResetPending] = useState(false);

    const onResetPassword = async (data) => {
        let success = false;
        setPostLoading(true);
        
        try {
            success = await forgotPassword(data);
            
            if(success){
                setResetPending(true);
            }
        } catch {}
        finally{
            setPostLoading(false);
        }
    };
return (
    <>
        <section className={styles.center}>
            {!resetPending ? (
                <>
                    <ResetPasswordForm onResetPassword={(data) => onResetPassword(data)} isLoading={postLoading}/>
                    {postLoading && (
                        <div className={styles.loader}>
                            <Loading />
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.pendingReset}>
                    <span>Forgot password?</span>
                    <p>Please check your email to complete resetting your password.</p>
                </div>
            )}
        </section>
    </>
);
};

