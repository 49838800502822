import { useEffect } from "react";
import classNames from "classnames";
import styles from "./AboutUs.module.css";
import { CheckboxList, HeaderSection, DirectorHeader } from "@cpnw/ui";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={styles.aboutUs}>
      <HeaderSection header={"Partners for excellence in clinical education."} />
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}><p>Clinical Placements Northwest (CPNW) is a not-for-profit corporation working to facilitate clinical placement for the Health Professions student, to increase availability of student placement opportunities, and to improve communication among healthcare organizations and nursing education programs in Washington State and Northern Idaho. We presently have 53 member organizations; 19 healthcare partners, and 33 partners in education. CPNW collaborates with numerous education programs throughout the Pacific Northwest. Our education and healthcare partners are working together with a shared vision and common goal of creating a well-prepared and competent healthcare workforce. Learn about our extensive list of participating programs in the links to the left.</p></div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <p>
            <strong>Our Mission</strong> is to improve the health education and
            clinical experience, thereby increasing value to
            all stakeholders; improving quality of care;
            improving patient, student and preceptor
            satisfaction; and promoting system sustainability.
          </p>
          <p>
            <strong>Our Goal</strong> is to be the recognized leader in innovation in clinical placement of the health professions student.
          </p></div>
        <div className={classNames(styles.column)}>
          <strong> We intend </strong>to reach our goal and accomplish our mission by:
          <CheckboxList items={[
            { description: "Creating efficient & effective systems", isChecked: true, readOnly: true },
            { description: "Streamlining & standardizing processes across organizations", isChecked: true, readOnly: true },
            { description: "Ensuring compliance", isChecked: true, readOnly: true },
            { description: "Promoting relationships", isChecked: true, readOnly: true },
            { description: "Demonstrating resource savings", isChecked: true, readOnly: true },
            { description: "Sharing best practices for sustainable improvement", isChecked: true, readOnly: true },
          ]} />
        </div>
      </div>
      <HeaderSection header={"CPNW Board of Directors"} />
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column, styles.itemCenter)}>
          <div style={{ width: 83.33333 + '%' }}>
            <DirectorHeader name={"Ashley English"} position={"Executive Director"} /> <br />
            Clinical Placements Northwest <br />
            <a href="mailto:englisha@cpnw.org ">englisha@cpnw.org </a><br /><br />
          </div>
        </div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Karalynn LaValley"} position={"President"} /> <br />
          Associate Dean of Nursing Programs <br />
          Green River College <br /><br />
        </div>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Pam Orebaugh"} position={"At-large"} /> <br />
          Director of Clinical Education <br />
          Eastern Washington University <br /><br />
        </div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Camey Yeager"} position={"Secretary"} /> <br />
          Manager of Student Services and Employee <br />Health<br />
          Northwest Specialty Hospital <br /><br />
        </div>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Kellee Rickerl"} position={"At-large"} /> <br />
          Director of Clinical Education &
          Professor for Respiratory Care <br />
          Tacoma Community College <br /><br />
        </div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Shelly Caldejon"} position={"Vice President"} /> <br />
          Lead Professional Development Specialist<br />
          UW Medicine/Valley Medical Center <br /><br />
        </div>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Kate Prigge"} position={"At-large"} /> <br />
          Assistant to the Dean <br /> Pacific Lutheran university <br /><br />
        </div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Julie Baxter"} position={"At-Large"} /> <br />
          Division Clinical Program Manager, Academic Services<br />
          Providence-Swedish, North Division Education & Professional Practice Team <br /><br />
        </div>
        <div className={classNames(styles.column)}>
          <DirectorHeader name={"Stephanie Bradbury"} position={"Treasurer"} /> <br />
          Clinical Placement Coordinator <br /> North Idaho College <br /><br />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
