import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import Carousel from "../../components/Carousel";
import environment from "environment";
import { Auth } from "auth";
import { AccountContainer } from "hooks/useAccount";
import { CpnwContainer } from "hooks/useCpnw";
import {
  LoginModal,
  NavMenu,
  AdSpaceButton,
  ContactSection,
  Footer,
} from "@cpnw/ui";
import { StaticRoutes } from "pages/routes";
import styles from "./Layout.module.css";
import { UserRegState } from "pages/enums";

const PageLockRedirect = ({ children }) => {
  const { meData } = AccountContainer.useContainer();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (meData) {
      if (
        meData.regState == UserRegState.AwaitingEmailConfirmation &&
        path != StaticRoutes.AccountConfirmEmail &&
        path != StaticRoutes.ConfirmEmailPending 
      ) {
        history.push(StaticRoutes.AccountConfirmEmail);
      }
    }
  }, [location, meData]);

  return <>{children}</>;
};

export function Layout(props) {
  return (
    <Auth.Provider>
      <AccountContainer.Provider>
        <CpnwContainer.Provider>
          <PageLockRedirect>
            <LayoutContent {...props} />
          </PageLockRedirect>
        </CpnwContainer.Provider>
      </AccountContainer.Provider>
    </Auth.Provider>
  );
}

function LayoutContent({ children }) {
  const history = useHistory();
  const { isLoggedIn } = Auth.useContainer();
  const { login, logout, forgotPassword } = AccountContainer.useContainer();

  const isHomePage = window.location.pathname === "/";
  const isTermsAndPrivacy =
    window.location.pathname === StaticRoutes.TermsAndPrivacy;
  const isAccountVerifyCode =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.AccountVerifyCode.toLowerCase();
  const isAccountSendCode =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.AccountSendCode.toLowerCase();
  const isConfirmEmail =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.AccountConfirmEmail.toLowerCase();
  const isConfirmEmailPending =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.ConfirmEmailPending.toLowerCase();

  const refNavMenu = useRef();
  const refCarousel = useRef();

  const { height } = useWindowDimensions();
  const [isLogin, setIsLogin] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");

  useEffect(() => {
    if (isHomePage) {
      const handleScroll = (e) => {
        e.preventDefault();

        if (refNavMenu?.current && refCarousel?.current) {
          const overlap =
            refNavMenu.current.getBoundingClientRect().bottom -
            refCarousel.current.getBoundingClientRect().bottom;

          setScroll(overlap >= 0);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isHomePage]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const openUrl = (url) => {
    history.push(url);
  };
  const onSignIn = async (data) => {
    let success = false;
    setLoadingLogin(true);
    try {
      success = await login(data);
      if (success) {
        setIsLogin(false);
      }
      // window.location.href = window.location.href;
    } catch {}

    if (!success) setErrorLogin("Invalid login attempt.");
    else setErrorLogin(null);

    setLoadingLogin(false);
  };
  const onForgot = () => {
    window.location.href = StaticRoutes.AccountResetPasswordPending;
    setIsLogin(false);
  };

  const navMenus = useMemo(() => {
    if (isLoggedIn) {
      return [
        {
          label: "Logout",
          onClick: (e) => {
            logout();
          },
        },
        {
          label: "My Dashboard",
          onClick: (e) => {
            window.location.href = environment.dashboardAppUrl;
          },
        },
      ];
    }

    return [
      {
        label: "Login",
        onClick: (e) => {
          setIsLogin(true);
        },
      },
      {
        label: "Register",
        onClick: (e) => {
          openUrl(StaticRoutes.AccountRegister);
        },
      },
      {
        label: "My Dashboard",
        onClick: (e) => {
          openUrl(StaticRoutes.MyDashboard);
        },
      },
    ];
  }, [isLoggedIn]);

  const navDropdownItems = [
    {
      label: "About Us",
      onClick: (e) => {
        openUrl(StaticRoutes.AboutUs);
      },
      pageList: [
        {
          label: "Healthcare Partners",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsHealthcarePartners);
          },
        },
        {
          label: "Participating Programs",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsParticipatingPrograms);
          },
        },
        {
          label: "Nursing Placement",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsNursingPlacement);
          },
        },
      ],
    },
    {
      label: "Our Services",
      onClick: (e) => {
        openUrl(StaticRoutes.OurServices);
      },
    },
    {
      label: "FAQ",
      onClick: (e) => {
        openUrl(StaticRoutes.FAQ);
      },
    },
    {
      label: "Get Started",
      onClick: (e) => {
        openUrl(StaticRoutes.GetStarted);
      },
      pageList: [
        {
          label: "Schools",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedSchools);
          },
        },
        {
          label: "Students",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedStudents);
          },
        },
        {
          label: "Healthcare",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedHealthcare);
          },
        },
      ],
    },
  ];

  const formattedNavDropdownItems = navDropdownItems.map((item) => {
    let newItem = { ...item };
    delete newItem.pageList;
    return newItem;
  });

  const hamburgerItems = [...navMenus, ...formattedNavDropdownItems];

  return (
    <div className={styles.layout}>
      <LoginModal
        loading={loadingLogin}
        invalidLoginText={errorLogin}
        isOpen={isLogin}
        onClick={() => {
          setIsLogin(false);
        }}
        onSignIn={(data) => {
          onSignIn(data);
        }}
        onForgotPassword={() => {
          onForgot();
        }}
      />
      <NavMenu
        ref={refNavMenu}
        isStatic={isHomePage ? scroll : true}
        onClickLogo={(e) => openUrl(StaticRoutes.Root)}
        navItems={navMenus}
        navDropdownItems={navDropdownItems}
        hamburgerItems={hamburgerItems}
      />
      {!isTermsAndPrivacy &&
        !isAccountSendCode &&
        !isConfirmEmailPending &&
        !isConfirmEmail &&
        !isAccountVerifyCode && (
          <div
            className={classNames([styles.adSpaceButton], {
              [styles.homePage]: isHomePage,
            })}
          >
            <AdSpaceButton
              size={isHomePage ? "small" : "large"}
              onClick={() =>
                openInNewTab(
                  "https://cpnw.blob.core.windows.net/documents/AdRotator/AdRequestFlyer-Form.pdf"
                )
              }
            />
          </div>
        )}

      {isHomePage && (
        <div
          ref={refCarousel}
          style={{ height: `${height}px` }}
          className={classNames([styles.carousel], {
            [styles.homePage]: isHomePage,
          })}
        >
          <Carousel
            slides={[
              {
                header: "We Put Learning Into Practice",
                subHeader:
                  "Educators and clinicians partnering for a successful and sustainable workforce.",
                linkLabel: "Get Started",
                link: StaticRoutes.GetStarted,
                backgroundImage:
                  "https://cpnw-staging.azurewebsites.net/images/content/home/home-first.jpg",
              },
              {
                header: "Optimal Clinical Learning Environment",
                subHeader:
                  "Standardizing and simplifying the clinical experience process.",
                linkLabel: "Our Services",
                link: StaticRoutes.OurServices,
                backgroundImage:
                  "https://cpnw-staging.azurewebsites.net/images/content/home/home-pharm.jpg",
              },
              {
                header: "The right learning. The right place. The right time.",
                subHeader:
                  "Over 200 health professions programs partnering with Pacific Northwest hospitals.",
                linkLabel: "Our Partners",
                link: StaticRoutes.AboutUsHealthcarePartners,
                backgroundImage:
                  "https://cpnw-staging.azurewebsites.net/images/content/home/home-records.jpg",
              },
              {
                header: "Partnering for Excellence in Clinical Education",
                subHeader: "Serving students throughout the Pacific Northwest.",
                linkLabel: "Participating Programs",
                link: StaticRoutes.AboutUsParticipatingPrograms,
                backgroundImage:
                  "https://cpnw-staging.azurewebsites.net/images/content/home/home-pt.jpg",
              },
              {
                header: "Connecting Knowledge with Experience",
                subHeader:
                  "The link between education and professional healthcare practice.",
                linkLabel: "About Us",
                link: StaticRoutes.AboutUs,
                backgroundImage:
                  "https://cpnw-staging.azurewebsites.net/images/content/home/home-rt.jpg",
              },
            ]}
          />
        </div>
      )}
      <div
        className={classNames([styles.contentContainer], {
          [styles.homePage]: isHomePage,
        })}
        style={{
          marginTop: isHomePage
            ? `${height}px`
            : isTermsAndPrivacy ||
              isAccountSendCode ||
              isConfirmEmailPending ||
              isConfirmEmail ||
              isAccountVerifyCode
            ? 0
            : 10,
        }}
      >
        {children}
      </div>
      {!isTermsAndPrivacy &&
        !isConfirmEmail &&
        !isConfirmEmailPending &&
        !isAccountSendCode &&
        !isAccountVerifyCode && (
          <ContactSection onSubmit={() => console.log("submit")} />
        )}
      <Footer />
    </div>
  );
}

const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default Layout;
