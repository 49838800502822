import React from "react";
import styles from "./ListItem.module.css";
import PropTypes from "prop-types";

const ListItem = ({ listContent, isStrong }) => {
    return (
        <ul>
            {isStrong ?
                <li className={styles.listItemStyle}><strong>{listContent}</strong></li> :
                <li className={styles.listItemStyle}>{listContent}</li>
            }
        </ul>
    )
}

ListItem.propTypes = {
    listContent: PropTypes.string.isRequired,
    isStrong: PropTypes.bool
}

export default ListItem;