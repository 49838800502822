export const StaticRoutes = {
    Root: "/",

    GetStarted: "/GetStarted",
    GetStartedSchools: "/GetStarted/Schools",
    GetStartedStudents: "/GetStarted/Students",
    GetStartedHealthcare: "/GetStarted/Healthcare",

    AboutUs: "/AboutUs",
    AboutUsHealthcarePartners: "/AboutUs/HealthcarePartners",
    AboutUsParticipatingPrograms: "/AboutUs/ParticipatingPrograms",
    AboutUsNursingPlacement: "/AboutUs/NursingPlacement",

    PlacementsPage: "/Placements",

    AccountRegister: "/Account/Register",
    ConfirmEmailPending: "/Account/ConfirmEmailPending",
    AccountConfirmEmail: "/Account/ConfirmEmail",
    AccountLogin: "/Account/Login",
    AccountSendCode: "/Account/SendCode",
    AccountVerifyCode: "/Account/VerifyCode",
    AccountResetPassword: "/Account/ResetPassword",
    AccountResetPasswordPending: "/Account/ResetPasswordPending",
    AccountConsent: "/Consent",
    AccountRegistrationProfile: "/Account/RegistrationProfile",
    AccountArchived: "/Account/Archived",

    MyDashboard: "/Account/Login", // using same value as AccountLogin for now until authentication piece and CPNW Dashboard is fully set up

    OurServices: "/OurServices",

    FAQ: "/FAQ",

    TermsAndPrivacy: "/Home/TermsAndPrivacy",
}