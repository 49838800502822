import { useEffect } from "react";
import styles from "./Students.module.css";
import fa_user from "../../../../assets/fa-user-B7A987.svg";
import img_computer_screen from "../../../../assets/img-computer-screen.png";
import img_phone from "../../../../assets/img-phone.svg";
import { InstructionItem } from "@cpnw/ui";

const Students = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.students}>
      <h1>Registration is as easy as one, two, three.</h1>
      <div>
        <div className={styles.item}>
          <InstructionItem
            header={"It starts with a code from your school."}
            info={
              "Students and faculty follow the same process to create an account. It all starts with an access code provided by your school. To begin registration, click on the “Register” link at the top of this page and simply fill in the information requested on the form. The code is the first step on the registration page."
            }
            img={fa_user}
            step={1}
          />
        </div>
        <div className={styles.item}>
          <InstructionItem
            header={"Your account will be created."}
            info={
              "During registration you will create your username, password and user profile. Your information will be forwarded to your school’s CPNW coordinator for approval, which can take up to 24 hours. If you haven’t heard back within a day, contact the coordinator listed on the bottom of the email confirmation you received upon registering."
            }
            img={img_computer_screen}
            step={2}
          />
        </div>
        <div className={styles.item}>
          <InstructionItem
            header={"Sign into your account."}
            info={
              "When you sign into your account you will enter your password, then be asked for a authentication code that will be sent as a text to your phone or your email. This two-step verification is an extra layer of security to protect your personal information. A new authentication code will be generated just for you each time you log into the site."
            }
            img={img_phone}
            step={3}
          />
        </div>
      </div>
    </div>
  );
};

export default Students;
