import React from "react";
import PropTypes from "prop-types";
import styles from "./PlacementItem.module.css";

const PlacementItem = ({ key, label, src }) => {
  return (
    <li key={key}>
      {src ? (
        <a href={src} target="_blank">
          {label}
        </a>
      ) : (
        <a>{label}</a>
      )}
    </li>
  );
};

PlacementItem.propTypes = {
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default PlacementItem;
