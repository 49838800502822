import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import { StaticRoutes } from "pages/routes";

const PageTitle = () => {
    const [_title, _setTitle] = useState("")
    useEffect(()=>{
        var pageTitle = ""
        var pathname = window.location.pathname

        if(pathname == StaticRoutes.AboutUs){
            pageTitle = "About Us"
        }
        else if(pathname == StaticRoutes.AboutUsHealthcarePartners){
            pageTitle = "Healthcare Partners"
        }
        else if(pathname == StaticRoutes.AboutUsNursingPlacement){
            pageTitle = "Nursing Placement"
        }
        else if(pathname == StaticRoutes.AboutUsParticipatingPrograms){
            pageTitle = "Participating Programs"
        }
        else if(pathname == StaticRoutes.AccountLogin){
            pageTitle = "Log In"
        }
        else if(pathname == StaticRoutes.AccountRegister){
            pageTitle = "Register"
        }
        else if(pathname == StaticRoutes.FAQ){
            pageTitle = "FAQ"
        }
        else if(pathname == StaticRoutes.GetStarted){
            pageTitle = "Get Started"
        }
        else if(pathname == StaticRoutes.GetStartedHealthcare){
            pageTitle = "Healthcare"
        }
        else if(pathname == StaticRoutes.GetStartedSchools){
            pageTitle = "Schools"
        }
        else if(pathname == StaticRoutes.GetStartedStudents){
            pageTitle = "Students"
        }
        else if(pathname == StaticRoutes.MyDashboard){
            pageTitle = "My Dashboard"
        }
        else if(pathname == StaticRoutes.OurServices){
            pageTitle = "Our Services"
        }

        _setTitle(pageTitle)
    },[window.location.pathname])

    return (
        <Helmet>
            <title>{_title ? `${_title} - ` : ''}Clinical Placements Northwest (CPNW)</title>
        </Helmet>
    )
}

export default PageTitle;

PageTitle.propTypes = {
    title: PropTypes.string
}