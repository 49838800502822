import React from "react";
import PropTypes from "prop-types"
import styles from "./TermHeader.module.css"

const TermHeader = ({ header }) => {
    return (
        <h4 className={styles.headerStyle}><strong>{header}</strong></h4>
    );
};

TermHeader.propTypes = {
    header: PropTypes.string.isRequired
};

export default TermHeader;