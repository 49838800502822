import { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ResetPasswordForm.module.css";
import { InputBox, Button } from "@cpnw/ui";
import classNames from "classnames";

/**
 * Interactive component.
 * Form used to reset password on dashboard.
 */
const ResetPasswordForm = ({ onResetPassword, isLoading }) => {
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [firstSubmit, setFirstSubmit] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setFirstSubmit(true);

    const error = validateEmail(email);

    setError(error);

    if (error) {
      return;
    }

    if (onResetPassword && typeof onResetPassword == "function") {
      onResetPassword({
        email: email,
      });
      setEmail("");
      setError(null);
      setFirstSubmit(false);
    }
  };

  const validateEmail = (email) => {
    let error = null;
    if (!email || email.trim() == "") {
      error = "Email is required";
    } else if (!RG_Email.test(email)) {
      error = "Email is not valid";
    }

    return error;
  };

  return (
    <div className={styles.resetPasswordForm}>
      <span>Forgot password?</span>
      <p>
        Please enter your email below and we'll send you a link to reset your
        password
      </p>
      <form onSubmit={(e) => onSubmit(e)}>
        <input className={styles.submitHidden} type="submit" hidden />
        <InputBox
          ref={inputRef}
          value={email}
          setValue={(e) => {
            setEmail(e.target.value);
            firstSubmit && setError(validateEmail(e.target.value));
          }}
          placeholder={"Email *"}
          required={!!error}
          requiredErrorMessage={error}
        />
        <Button
          className={classNames(styles.submitBtn, !isLoading && styles.hover)}
          text={"RESET PASSWORD"}
          onClick={(e) => onSubmit(e)}
          disabled={isLoading}
        />
      </form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  /**
   * Function called when Reset Password is clicked
   */
  onResetPassword: PropTypes.func,
  isLoading: PropTypes.bool,
};

const RG_Email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default ResetPasswordForm;
