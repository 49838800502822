import React, { useState, useEffect } from "react";
import environment from "environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Auth } from "auth";
import { StaticRoutes } from "pages/routes";
import { CpnwContainer } from "hooks/useCpnw";
import { Button, InputBox } from "@cpnw/ui";
import styles from "./VerifyCodePage.module.css";

export function VerifyCodePage() {
  const history = useHistory();
  const { multiFactorToken, setToken } = Auth.useContainer();
  const { accountVerifyCodePost } = CpnwContainer.useContainer();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (!multiFactorToken) window.location.href = StaticRoutes.AccountLogin;
  }, []);

  const onVerifyCode = async () => {
    if (code && code.trim().length > 0) {
      setLoading(true);
      setError(false);

      let result = null;
      try {
        result = await accountVerifyCodePost(code);
      } catch (error) {}

      if (result && result.success) {
        if (result.next == "ConfirmEmail") {
          setToken(result.token);
          localStorage.setItem("cpnw-token", result.token);
          history.push(StaticRoutes.AccountConfirmEmail);
        }
        else if (result.next == "Consent")
          window.location.href = `${environment.dashboardAppUrl}${StaticRoutes.AccountConsent}`;
        else if (result.next == "RegistrationProfile")
          window.location.href = `${environment.dashboardAppUrl}${StaticRoutes.AccountConsent}`;
        else window.location.href = environment.dashboardAppUrl;
      } else {
        setError(true);
        setLoading(false);
      }
    }
  };

  const onSetCode = (value) => {
    let newCode = code;
    if (value.length <= 6 && !isNaN(Number(value))) newCode = value;
    setCode(newCode);
  };

  const onResendCode = (e) => {
    e.preventDefault();

    history.push(StaticRoutes.AccountSendCode);
  };

  return (
    <div className={styles.container}>
      <h2>Enter Authentication Code</h2>
      {error && (
        <div className={styles.error}>
          <h3>Invalid login attempt.</h3>
          <h3>
            Make sure you are using the most recent code that has been sent to
            you.
          </h3>
        </div>
      )}
      <label>Please Enter Your Authentication Code.</label>
      <InputBox
        value={code}
        setValue={(e) => onSetCode(e.target.value)}
        disabled={loading}
      />
      <div>
        <Button
          className={styles.submit}
          text={loading ? "Verifying ..." : "Verify"}
          onClick={() => onVerifyCode()}
          disabled={!code || code.trim().length == 0 || loading}
        />
        <span className={styles.linkResendCode} onClick={(e) => onResendCode(e)}>Resend Code</span>
      </div>
    </div>
  );
}

export default VerifyCodePage;
