import { React, useRef } from "react";
import DownloadTermsSection from "components/DownloadTermsSection";
import UseSection from "../../components/UseSection";
import PrivacyPolicySection from "../../components/PrivacyPolicySection";
import MiscellaneousSection from "components/MiscellaneousSection/MiscellaneousSection";

const TermsAndPrivacyPage = () => {
    const refNavMenu = useRef();
    const openUrl = (url) => {
        window.location.pathname = url;
    };

    return (
        <div>
            <DownloadTermsSection />
            <UseSection />
            <PrivacyPolicySection />
            <MiscellaneousSection />
        </div>
    )
}

export default TermsAndPrivacyPage;