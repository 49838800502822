import React, { useState } from "react";
import styles from "./ResetPasswordPage.module.css";
import { CpnwContainer } from "hooks/useCpnw";
import { StaticRoutes } from "../routes";
import ResetPasswordForm from "components/ResetPasswordFormWithCode";
import useSearchParams from "utils/useSearchParams";
import { HeaderSection } from "@cpnw/ui";

export function ResetPasswordPage() {
  const { accountResetPasswordPost } = CpnwContainer.useContainer();
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const [successPage, setSuccessPage] = useState(false);
  const onResetPassword = async (data) => {
    const payload = { code: params.get("code"), ...data };
    let result = null;
    try {
      setLoading(true);
      result = await accountResetPasswordPost(payload);
    } catch (e) {}
    if (result && result.message == "ResetPasswordConfirmation") {
      setError(null);
      setSuccessPage(true);
    } else {
      setError(result.message);
    }
    setLoading(false);
  };

  return (
    <section className={styles.ResetPasswordPage}>
      {!successPage && (
        <>
          <div className={styles.container}>
            <ResetPasswordForm
              tokenError={error}
              loading={loading}
              onResetPassword={onResetPassword}
            />
          </div>
        </>
      )}
      {successPage && (
        <div>
          <HeaderSection header={"Password Reset Confirmed."} />
          <p>
            Your password has been reset. Please{" "}
            <a className={styles.a} href={StaticRoutes.AccountLogin}>
              click here to log in.
            </a>
          </p>
        </div>
      )}
    </section>
  );
}

export default ResetPasswordPage;
