import React from "react";
import {useState, useEffect} from "react";

import styles from "./AboutUsHealthcarePartnersPage.module.css";
import { InfoSection, PartnerList } from "@cpnw/ui";
import { CpnwContainer } from "hooks/useCpnw";

export function AboutUsHealthcarePartnersPage() {
  const {healthcarePartnersData, refetchHealthcarePartners} = CpnwContainer.useContainer()
  //null on page load to prevent unneccesary render of empty component or error via required prop
  const [listOfPartners, setListOfPartners] = useState(null);

  useEffect(()=>{
    refetchHealthcarePartners()
  },[])

  // API Call on mount
  useEffect(() => {
    if(healthcarePartnersData && healthcarePartnersData.length){
      const updatedData = healthcarePartnersData.map((item) => ({
        // label = facility
        label: item.facilityName,
        // src = url
        src: item.url
      }));

      setListOfPartners(updatedData);
      return
    }
    setListOfPartners(null)
  }, [healthcarePartnersData])

      /*
        response from api should be an array of objects like so
            {
                "facilityName": "",
                "url": null
            },
      */
      /*
        array passed to partnersList should be an array of objects like so
            {
                "label": "",
                "src": null
            },
      */

  return (
    <section className={styles.healthcare_partners}>
      <InfoSection
        header={"Healthcare Partners"}
        paragraphs={[
          "Clinical Placements Northwest is a unique partnership with education institutions and healthcare systems. Students of all health professions are provided onboarding services based on a standardized set of requirements agreed to by all member clinical locations. Browse the list below to view the participating healthcare organizations.",
        ]}
      />
      <div className={styles.partners_list_wrapper}>
        
        {listOfPartners && <PartnerList partners={listOfPartners} />}

      </div>
    </section>
  );
}

export default AboutUsHealthcarePartnersPage;
