import React from "react";
import styles from "./RequestExampleSection.module.css";
import InputExample from "../InputExample";

const RequestExampleSection = ({ }) => {

    return (
        <>
            <h2 className={styles.reqExh2}>School Account Request</h2>
            
                <div className={styles.text}>
                    <fieldset className={styles.fieldsetSection}>
                        <legend className={styles.fieldsetLegend}>Higher Education Institution</legend>
                        <p>
                            Please complete the required information for your educational institution below. This
                            information will be used for official correspondence from CPNW.
                            The primary contact should be the individual responsible for oversight of the
                            program accounts you are requesting.<br />

                        </p>
                        <small className={styles.smallTextColor}> **All fields are required.</small>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <InputExample name={"Parent Educational Facility Name"} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <InputExample name={"Educational Facility Mailing Address"} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <InputExample name={"City"} />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <InputExample name={"State"} />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <InputExample name={"Zipcode"} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <InputExample name={"Primary Contact Name"} />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <InputExample name={"Primary Contact Email"} />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <InputExample name={"Primary Contact Phone"} />
                            </div>
                        </div>
                    </fieldset>
                        <hr className={styles.hrStyle}/>
                </div>
            
        </>
    )
}

export default RequestExampleSection