import React from "react";
import styles from "./ConfirmEmailPendingPage.module.css";
import { Button } from "@cpnw/ui";
import { AccountContainer } from "hooks/useAccount";

export function ConfirmEmailPendingPage() {
  
  return (
    <div className={styles.container}>
      <h1>Next Step</h1>
      <h5>
        To continue the registration process please check your email account for
        a confirmation message.
      </h5>
      <h5> Check your junk mail folder, if you do not see the email.</h5>
    </div>
  );
}

export default ConfirmEmailPendingPage;
