import styles from "./AboutUsPage.module.css";
import { PageNav } from "@cpnw/ui";
import { StaticRoutes } from "../routes";

const AboutUsPage = ({ children }) => {
  return (
    <div className={styles.aboutUsPage}>
      <div className={styles.contentContainer}>
        <div className={styles.nav}>
        <PageNav
            header={"About Us"}
            headerTo={StaticRoutes.AboutUs}
            links={[
              {
                name: "Healthcare Partners",
                src: StaticRoutes.AboutUsHealthcarePartners,
              },
              {
                name: "Participating Programs",
                src: StaticRoutes.AboutUsParticipatingPrograms,
              },
              {
                name: "Nursing Placement",
                src: StaticRoutes.AboutUsNursingPlacement,
              },
            ]}
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

export default AboutUsPage;
