import { DomainConf } from "./DomainConfig";

/**
 * Define configurations here to be loaded based on the domain
 * the app is being served from.
 */
const env = new DomainConf()
  .forDomain("localhost", {
    homeUrl: "https://dev.cpnw.org",
    apiBase: "https://dev-api.cpnw.org/v1",
    // apiBase: "http://localhost:7071/api",
    dashboardAppUrl: "https://dev-dashboard.cpnw.org",
    studentAppUrl: "https://dev-students.cpnw.org",
    facultyAppUrl: "https://dev-falcuty.cpnw.org",
    educationAppUrl: "https://dev-education.cpnw.org",
    healthcareAppUrl: "https://dev-healthcare.cpnw.org",
    localDevMode: true, // Set to true to enable local login for developers.
  })
  .forDomain("dev.cpnw.org", {
    homeUrl: "https://dev.cpnw.org",
    apiBase: "https://dev-api.cpnw.org/v1",
    dashboardAppUrl: "https://dev-dashboard.cpnw.org",
    studentAppUrl: "https://dev-students.cpnw.org",
    facultyAppUrl: "https://dev-falcuty.cpnw.org",
    educationAppUrl: "https://dev-education.cpnw.org",
    healthcareAppUrl: "https://dev-healthcare.cpnw.org",
  })
  .forDomain("staging-ca-settings.wwdb.com", {
    homeUrl: "https://dev.cpnw.org",
    apiBase: "https://dev-api.cpnw.org/v1",
    dashboardAppUrl: "https://dev-dashboard.cpnw.org",
    studentAppUrl: "https://dev-students.cpnw.org",
    facultyAppUrl: "https://dev-falcuty.cpnw.org",
    educationAppUrl: "https://dev-education.cpnw.org",
    healthcareAppUrl: "https://dev-healthcare.cpnw.org",
  })
  .forDomain("beta.cpnw.org", {
    homeUrl: "https://beta.cpnw.org",
    apiBase: "https://api.cpnw.org/v1",
    dashboardAppUrl: "https://dashboard.cpnw.org",
    studentAppUrl: "https://students.cpnw.org",
    facultyAppUrl: "https://faculty.cpnw.org",
    educationAppUrl: "https://education.cpnw.org",
    healthcareAppUrl: "https://healthcare.cpnw.org",
  })
  .forDomain("cpnw.org", {
    homeUrl: "https://cpnw.org",
    apiBase: "https://api.cpnw.org/v1",
    dashboardAppUrl: "https://dashboard.cpnw.org",
    studentAppUrl: "https://students.cpnw.org",
    facultyAppUrl: "https://faculty.cpnw.org",
    educationAppUrl: "https://education.cpnw.org",
    healthcareAppUrl: "https://healthcare.cpnw.org",
  });

// Export the selected configuration from this file. You can import this wherever you need to use it.
export default env.current;
