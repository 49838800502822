import React, { useState, useRef, useMemo, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./RegisterForm.module.css";
import { InputBox, SelectBox, Button } from "@cpnw/ui";
import Preloader from "../../images/preloader.gif";

const RegisterForm = ({
  loading,
  smsProviders,
  facilityAccess,
  onVerifyAccessCode,
  onSubmit,
  errorResponse,
}) => {
  const accessCodeRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const phoneNumberRef = useRef();

  const [errors, setErrors] = useState({});
  const [internalLoading, setInternalLoading] = useState(null);
  const [internalFacilityAccess, setInternalFacilityAccess] = useState(null);
  const [formData, setFormData] = useState({
    accessCode: "",
    email: "",
    password: "",
    confirmPassword: "",
    mfaPreference: "",
    selectedSMSCarrier: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (errorResponse?.facilityAccess || errorResponse?.email) {
      let newErrors = {};

      if (errorResponse.facilityAccess) {
        newErrors.facilityAccessResponse = errorResponse.facilityAccess;
        accessCodeRef.current.focus();
      }

      if (errorResponse.email) {
        newErrors.emailResponse = errorResponse.email;
        emailRef.current.focus();
      }

      setErrors({ ...errors, ...newErrors });
    } else {
      delete errors.facilityAccessResponse;
      delete errors.emailResponse;

      setErrors({ ...errors });
    }
  }, [errorResponse]);

  useEffect(() => {
    setInternalFacilityAccess(facilityAccess);
  }, [facilityAccess]);

  useEffect(() => {
    setInternalLoading(loading);
  }, [loading]);

  const validateForm = () => {
    let newErrors = {
      ...validateAccessCode(),
      ...validateEmail(),
      ...validatePassword(),
      ...validateConfirmPassword(),
      ...validateMfaPreference(),
      ...validatePhoneNumber(),
    };
    return newErrors;
  };

  const validateAccessCode = () => {
    delete errors.accessCode;
    delete errors.facilityAccessResponse;

    if (
      !formData.accessCode.trim() ||
      !/^\d+-\d{2}$/.test(formData.accessCode)
    ) {
      errors.accessCode = "Invalid Code";
    }

    return errors;
  };

  const validateEmail = () => {
    delete errors.email;
    delete errors.emailResponse;

    if (!formData.email?.trim()) {
      errors.email = "Email is required";
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  const validatePassword = () => {
    delete errors.password;

    if (!formData.password?.trim()) {
      errors.password = "The Password field is required.";
    } else if (
      !/^(?=.*[A-Z])(?=.*[!@#$&*_?])(?=.*[0-9])(?=.*[a-z]).{10,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must contain at least 10 characters including at least: an uppercase character, a lowercase character, a number, and a special character.";
    }
    return errors;
  };

  const validateConfirmPassword = () => {
    delete errors.confirmPassword;

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword =
        "The password and confirmation password do not match.";
    }
    return errors;
  };

  const validateMfaPreference = () => {
    delete errors.mfaPreference;
    delete errors.mfaPreferenceAlert;

    if (!formData.mfaPreference?.trim()) {
      errors.mfaPreference = "Delivery Preference Required";
    }
    if (
      formData.mfaPreference == "Text Message" &&
      (!formData.selectedSMSCarrier || !formData.phoneNumber)
    ) {
      errors.mfaPreferenceAlert =
        "Text Message requires Cell Provider and Phone #";
    }
    return errors;
  };

  const validatePhoneNumber = () => {
    delete errors.phoneNumber;
    return errors;
  };

  function formatString(inputString) {
    if (!inputString) return ""; // Handle empty or undefined input
    return inputString
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first character of each word
  }

  const phoneFormatter = (value) => {
    // Strip all characters from the input except digits
    let formattedInput = value.replace(/\D/g, "");
    // Trim the remaining input to ten characters, to preserve phone number format
    formattedInput = formattedInput.substring(0, 10);
    // Based upon the length of the string, add formatting as necessary
    const size = formattedInput.length;
    if (size === 0) {
      formattedInput = formattedInput;
    } else if (size < 4) {
      formattedInput = `(${formattedInput}`;
    } else if (size < 7) {
      formattedInput = `(${formattedInput.substring(
        0,
        3
      )}) ${formattedInput.substring(3, 6)}`;
    } else {
      formattedInput = `(${formattedInput.substring(
        0,
        3
      )}) ${formattedInput.substring(3, 6)}-${formattedInput.substring(6, 10)}`;
    }

    return formattedInput;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setInternalLoading(true);

    setTimeout(() => {
      const newErrors = validateForm();
      if (Object.keys(newErrors).length === 0) onSubmit(formData);
      else {
        setInternalLoading(false);
        setErrors(newErrors);
      }
    }, 300);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.registerComponent}>
      <h3>Register</h3>
      <div className={styles.facilityAccess}>
        <p>
          {internalLoading && !internalFacilityAccess ? (
            <img src={Preloader} height={35} width={35} />
          ) : internalFacilityAccess ? (
            <span>
              You are registering for{" "}
              {formatString(internalFacilityAccess.facility)} <br />
              Program: {formatString(internalFacilityAccess.businessUnit)} (
              {internalFacilityAccess.busAbbrev})<br />
              as a {formatString(internalFacilityAccess.accessType)} user.
            </span>
          ) : (
            <span className={styles.jsonError}>
              {errors.facilityAccessResponse}
            </span>
          )}
        </p>
        {internalFacilityAccess &&
          internalFacilityAccess.selfPay &&
          [
            {
              TypeContactIDpk: 27,
              Type: "FACULTY",
            },
            {
              TypeContactIDpk: 58,
              Type: "EDUCATION",
            },
            {
              TypeContactIDpk: 87,
              Type: "HEALTHCARE",
            },
          ].every(
            (m) => !formData.accessCode.endsWith(`-${m.TypeContactIDpk}`)
          ) && (
            <p id="SelfPayMessage">
              After your account is approved, you will be asked to pay a
              subscription fee of{" "}
              <span id="SelfPayPrice">
                ${internalFacilityAccess.selfPayPrice}
              </span>
              before full access will be granted.
            </p>
          )}
      </div>
      <div>
        <InputBox
          id="accessCode"
          placeholder={"Access Code *"}
          label={"Enter Access Code"}
          tip={"Enter access code provided by your facility."}
          value={formData.accessCode}
          autoComplete={"off"}
          setValue={(e) => {
            formData.accessCode = e.target.value;
            const newErrors = validateAccessCode();
            setErrors({ ...errors, ...newErrors });
          }}
          onBlur={(e) => {
            if (!errors.accessCode) {
              setInternalFacilityAccess(null);
              onVerifyAccessCode(formData.accessCode);
            }
            setErrors({ ...errors });
          }}
          ref={accessCodeRef}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.accessCode && <span>{errors.accessCode}</span>}
          </span>
        </small>
      </div>
      <div>
        <InputBox
          id="email"
          placeholder={"Email *"}
          label={"Email Address"}
          tip={"Enter your preferred email. This will be your username."}
          value={formData.email}
          setValue={(e) => {
            formData.email = e.target.value;
            let newErrors = validateEmail();
            setErrors({ ...newErrors });
          }}
          autoComplete={"username"}
          ref={emailRef}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.email && <span>{errors.email}</span>}
            {errors.emailResponse && <span>{errors.emailResponse}</span>}
          </span>
        </small>
      </div>
      <div>
        <InputBox
          id="password"
          placeholder={"Create Password *"}
          label={"Password"}
          tip={
            "At least 10 characters including: upper and lowercase letters, a number and a special character."
          }
          value={formData.password}
          type={"password"}
          setValue={(e) => {
            formData.password = e.target.value;
            const newErrors = validatePassword();
            setErrors({ ...errors, ...newErrors });
          }}
          autoComplete={"new-password"}
          onBlur={(e) => {}}
          ref={passwordRef}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.password && <span>{errors.password}</span>}
          </span>
        </small>
      </div>
      <div>
        <InputBox
          id="confirmPassword"
          placeholder={"Confirm Password *"}
          label={"Confirm Password"}
          value={formData.confirmPassword}
          type={"password"}
          autoComplete={"new-password"}
          setValue={(e) => {
            formData.confirmPassword = e.target.value;
            const newErrors = validateConfirmPassword();
            setErrors({ ...errors, ...newErrors });
          }}
          onBlur={(e) => {
            const newErrors = validateConfirmPassword();
            setErrors({ ...errors, ...newErrors });
          }}
          ref={confirmPasswordRef}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
          </span>
        </small>
      </div>
      <div>
        <SelectBox
          id="mfaPreference"
          label={"Authentication Code Delivery Preference"}
          placeholder={"Please Select"}
          value={formData.mfaPreference}
          onChange={(e) => {
            formData.mfaPreference = e;
            const newErrors = validateMfaPreference();
            setErrors({ ...errors, ...newErrors });
          }}
          options={["Email", "Text Message"]}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.mfaPreference && <span>{errors.mfaPreference}</span>}
          </span>
        </small>
        {errors.mfaPreferenceAlert && (
          <div
            className={classNames(styles.alertBox, styles.alert, styles.radius)}
            id="MFAalert"
            style={
              errors.mfaPreferenceAlert
                ? { display: "block" }
                : { display: "none" }
            }
          >
            {errors.mfaPreferenceAlert}
          </div>
        )}
      </div>
      <div>
        <SelectBox
          id="selectedSMSCarrier"
          label={"Your Cellular Provider"}
          placeholder={"-No Cell Phone-"}
          tip={"Select your cellular provider."}
          value={formData.selectedSMSCarrier}
          onChange={(e) => {
            formData.selectedSMSCarrier = e;
            const newErrors = validateMfaPreference();
            setErrors({ ...errors, ...newErrors });
          }}
          options={smsProviders}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.selectedSMSCarrier && (
              <span>{errors.selectedSMSCarrier}</span>
            )}
          </span>
        </small>
      </div>
      <div>
        <InputBox
          id="phoneNumber"
          placeholder={"Text Phone (xxx)xxx-xxxx *"}
          label={"Text-enabled Phone"}
          tip={"Enter your text-enabled phone number."}
          value={formData.phoneNumber}
          autoComplete={"off"}
          setValue={(e) => {
            formData.phoneNumber = phoneFormatter(e.target.value);
            setErrors({ ...errors });
          }}
          ref={phoneNumberRef}
        />
        <small className={styles.error}>
          <span className={styles.fieldValidationError}>
            {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
          </span>
        </small>
      </div>
      <div className={styles.formCaption}>
        <p>
          By clicking Create Account you indicate you have read and agree to the{" "}
          <a href="/Home/TermsAndPrivacy" target="_blank">
            CPNW Terms of Use and Privacy Policy
          </a>
          .
        </p>
      </div>
      <Button
        type="submit"
        id="submit"
        onClick={(e) => handleSubmit(e)}
        text={
          internalLoading && internalFacilityAccess
            ? "Creating Account..."
            : "Create Account"
        }
        className={classNames(
          styles.button,
          (internalLoading || !internalFacilityAccess) && styles.disabled
        )}
        disabled={internalLoading || !internalFacilityAccess}
      />
    </form>
  );
};

RegisterForm.propTypes = {
  loading: PropTypes.bool,
  smsProviders: PropTypes.arrayOf(PropTypes.string),
  facilityAccess: PropTypes.object,
  onVerifyAccessCode: PropTypes.func,
  onSubmit: PropTypes.func,
  errorResponse: PropTypes.shape({
    facilityAccess: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default RegisterForm;
