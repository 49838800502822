import "./index.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FreshDeskWidget from "widgets/FreshDeskWidget";
import Layout from "pages/Layout";
import HomePage from "pages/HomePage";
import GetStartedPage from "pages/GetStartedPage";
import GetStarted from "pages/GetStartedPage/pages/GetStarted";
import Schools from "pages/GetStartedPage/pages/Schools";
import Students from "pages/GetStartedPage/pages/Students";
import Healthcare from "pages/GetStartedPage/pages/Healthcare";
import AboutUsPage from "pages/AboutUsPage";
import AboutUs from "pages/AboutUsPage/pages/AboutUs";
import AboutUsHealthcarePartnersPage from "pages/AboutUsPage/pages/AboutUsHealthcarePartnersPage";
import NursingPlacementPage from "pages/AboutUsPage/pages/NursingPlacementPage";
import DashboardPage from "pages/DashboardPage";
import AccountLogin from "pages/DashboardPage/pages/AccountLogin";
import { StaticRoutes } from "pages/routes";
import PageTitle from "components/PageTitle";
import OurServicePage from "pages/OurServicePage";
import PlacementsPage from "pages/PlacementsPage";
import FAQPage from "pages/FAQPage";
import ParticipatingProgramsPage from "pages/AboutUsPage/pages/ParticipatingProgramsPage";
import TermsAndPrivacyPage from "pages/TermsAndPrivacyPage";
import RegisterPage from "pages/RegisterPage";
import ConfirmEmailPendingPage from "pages/ConfirmEmailPendingPage";
import ConfirmedEmailPage from "pages/ConfirmedEmailPage";
import SendCodePage from "pages/SendCodePage";
import VerifyCodePage from "pages/VerifyCodePage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import ResetPasswordPendingPage from "pages/ResetPasswordPendingPage";
import ArchivedAccountPage from "pages/ArchivedAccountPage";

const NotFoundRedirect = () => {
  return (
    <div style={{ display: "flex", margin: "100px auto" }}>PAGE NOT FOUND</div>
  );
};

function App() {
  return (
    <>
      <FreshDeskWidget />
      <PageTitle />
      <Router>
        <Switch>
          <Route exact path="/">
            <Layout>
              <HomePage />
            </Layout>
          </Route>
          <Route exact path={StaticRoutes.FAQ}>
            <Layout>
              <FAQPage />
            </Layout>
          </Route>
          <Route exact path={StaticRoutes.TermsAndPrivacy}>
            <Layout>
              <TermsAndPrivacyPage />
            </Layout>
          </Route>
          <Route exact path={StaticRoutes.PlacementsPage}>
            <Layout>
              <PlacementsPage />
            </Layout>
          </Route>
          <Route>
            <Layout>
              <Switch>
                <Route path={StaticRoutes.GetStarted}>
                  <GetStartedPage>
                    <Switch>
                      <Route
                        path={StaticRoutes.GetStartedSchools}
                        component={Schools}
                      />
                      <Route
                        path={StaticRoutes.GetStartedStudents}
                        component={Students}
                      />
                      <Route
                        path={StaticRoutes.GetStartedHealthcare}
                        component={Healthcare}
                      />
                      <GetStarted />
                    </Switch>
                  </GetStartedPage>
                </Route>
                <Route path={StaticRoutes.AboutUs}>
                  <AboutUsPage>
                    <Switch>
                      <Route
                        path={StaticRoutes.AboutUsHealthcarePartners}
                        component={AboutUsHealthcarePartnersPage}
                      />
                      <Route
                        path={StaticRoutes.AboutUsParticipatingPrograms}
                        component={ParticipatingProgramsPage}
                      />
                      <Route
                        path={StaticRoutes.AboutUsNursingPlacement}
                        component={NursingPlacementPage}
                      />
                      <AboutUs />
                    </Switch>
                  </AboutUsPage>
                </Route>
                <Route path={StaticRoutes.AccountLogin}>
                  <DashboardPage>
                    <AccountLogin />
                  </DashboardPage>
                </Route>
                <Route path={StaticRoutes.AccountRegister}>
                  <RegisterPage />
                </Route>
                <Route path={StaticRoutes.ConfirmEmailPending}>
                  <ConfirmEmailPendingPage />
                </Route>
                <Route path={StaticRoutes.AccountConfirmEmail}>
                  <ConfirmedEmailPage />
                </Route>
                <Route path={StaticRoutes.AccountSendCode}>
                  <SendCodePage />
                </Route>
                <Route path={StaticRoutes.AccountVerifyCode}>
                  <VerifyCodePage />
                </Route>
                <Route path={StaticRoutes.AccountResetPassword}>
                  <ResetPasswordPage />
                </Route>
                <Route path={StaticRoutes.AccountResetPasswordPending}>
                  <ResetPasswordPendingPage />
                </Route>
                <Route path={StaticRoutes.AccountArchived}>
                  <ArchivedAccountPage />
                </Route>
                <Route
                  path={StaticRoutes.OurServices}
                  component={OurServicePage}
                />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
