import styles from "./OurServicePage.module.css";
import passportImg from "../../images/passport.jpg";
import { faPiggyBank, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import faDesktop from "../../assets/fa-desktop-AA9B73.svg";
import faLifeRing from "../../assets/fa-lifering-E00000.svg";
import { PageHeader, InfoSection, ServiceSection } from "@cpnw/ui";

const OurServicePage = () => {
  return (
    <div className={styles.aboutUsPage}>
      <div className={styles.contentContainer}>
        <div className={styles.nav}>
          <PageHeader name="Our Services" />
        </div>
        <div className={styles.content}>
          <InfoSection
            header="The right learning. The right time. The right place."
            paragraphs={[
              [
                "CPNW offers innovative onboarding tools to ensure a smooth transition into the clinical phase of your career training. This secure easy-to-use web application provides a clinical passport and e-learning modules to prepare for your next steps toward your professional career.",
                <strong key={"text-highlight"} className={styles.highlightText}>
                  {" "}
                  It's the right learning, at the right time, and the right
                  place. Get started today!{" "}
                </strong>,
              ],
            ]}
          />
          <div className={styles.groupServiceSection}>
            <ServiceSection
              imageSrc={passportImg}
              header="Clinical Passport"
              subHeader="Health + Safety Requirements"
              info="All users have a standard set of requirements that must be met prior to their clinical learning experience. These requirements are contained within the CPNW Clinical Passport. The requirements cover immunity status for vaccine-preventable diseases, background checks, pre-clinical training, license and insurance information."
            />
            <ServiceSection
              imageSrc={faDesktop}
              header="eLearning Modules"
              subHeader="Improving Your Service"
              info="As you complete the required interactive training on this site, your progress is tracked real-time and recorded in your account. Schools and healthcare partners can access your pre-clinical preparation records, providing a paperless transition into your clinical education experience."
            />
          </div>
          <div className={styles.groupServiceSection}>
            <ServiceSection
              imageSrc={faCheckSquare}
              isIcon
              iconColor="#007e8d"
              header="OIG/SAM Checks"
              subHeader="An Added Value"
              info="Excluded provider checks are performed bi-monthly on the 1st and 15th of each month for all users in the clinical setting. Your annual subscription covers the cost of this service. The results are made available on a need-to-know basis through our HIPAA & FERPA compliant secure interface."
            />
            <ServiceSection
              imageSrc={faLifeRing}
              header="Site Support"
              subHeader="We're here to help!"
              info="Questions? Visit the Frequently Asked Question (FAQ) tab or use the web based support features. The technical support tab to your left provides interactive access to our Knowledge Base. Live technical support is available weekdays as well."
            />
          </div>
          <div className={styles.groupServiceSection}>
            <ServiceSection
              imageSrc={faPiggyBank}
              isIcon
              iconColor="#00818f"
              header="Fee Schedule"
              subHeader="Services + Invoicing"
              headerText="Student Self-Pay Refund Policy"
              headerLink="https://cpnw.blob.core.windows.net/documents/docDownloads/financialDocs/Self%20Pay%20Refund%20Policy.pdf"
              info="CPNW’s fee structure allows us to provide a variety of services to health professions students, education programs and healthcare organizations."
              onDetails={() => {
                window.open(
                  "https://cpnw.blob.core.windows.net/public-documents/FeeSchedule.pdf?638465836153581314",
                  "_none"
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServicePage;
