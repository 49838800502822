import React from "react";
import classNames from "classnames";
import styles from "./FAQPage.module.css";
import { FAQSection } from "@cpnw/ui";

export function FAQPage() {
  const studentsFAQs = [
    { label: "Who is eligible to use CPNW?", info: "All students who have a comfirmed placement at one of our", link: "https://cpnw.org/HealthcarePartners", linkLabel: "member hospitals." },
    { label: "How do I obtain an account with CPNW?", info: "Before you can register to use the CPNW web application, an account must be established by your school. Once that is complete, your school coordinator will provide you with an access code which you will then use to register at", link: "https://cpnw.org/Account/Register", linkLabel: "cpnw.org/account/register." },
    { label: "How do I submit my Clinical Passport documents?", info: "It is the sole responsibility of your school to collect each student’s clinical passport documents. Submit your Clinical Passport to your program's clinical coordinator. A copy of the Clinical Passport can be found under the Clinical Prerequisites link on your dashboard." },
  ];

  const schoolsFAQs = [
    { label: "Who do I contact for placement?", info: "CPNW primarily provides placement services to member nursing programs. We also work to facilitate placement for non-member programs who have active affiliation agreements with our member healthcare partners." },
    { label: "How does our school create a CPNW account?", info: "If your program places students at a member facility, you must request a CPNW account. The application form can be found on the Get Started page of this website. <a href='https://cpnw-staging.azurewebsites.net/Schools'>Click Here</a> to go directly to the application page."},
    { label: "Where do schools submit Clinical Passport documents?", info: "It is the sole responsibility of the school coordinator to collect each student’s clinical passport & verify for accuracy (per contractual agreement). Schools are required to maintain these documents and submit according to clinical placements contracts." },
    { label: "If an Out-of-State Nursing Program desires placements in Washington State and has not previously placed within the State, who should they contact?", info: "For more information please visit:", link: "https://www.doh.wa.gov/portals/1/documents/6000/proceduree06.01.pdf", linkLabel: "https://www.doh.wa.gov/portals/1/documents/6000/proceduree06.01.pdf" },
  ];

  const healthcareFAQs = [
    { label: "How does a healthcare organization become a member?", info: 'Please contact us. We would love to talk with you about membership. Just use the "Get In Touch" form below to express your interest.' },
    { label: "How is the consortium funded?", info: "Annual fees are assessed to both education and healthcare members." },
    { label: "Does CPNW facilitate placement in the ambulatory setting?", info: "Facilitation of placement is primarily focused on the acute care setting for member nursing education programs. Our healthcare partners may utilize CPNW onboarding services for students accommodated within those ambulatory settings." },
    { label: "What services does CPNW offer for the creative management of clinical experiences?", info: "Collaboration is a key value of CPNW. Members meet on a regular basis to discuss and resolve placement challenges and to strategically plan for best approaches to meet the clinical learning needs of all students." },
    { label: "Is information being gathered to look at training practices for best outcomes?", info: "CPNW has launched their first pilot survey of student, faculty and staff of the clinical learning environment. Results are now being analyzed and will soon be available to all members." },
  ];

  const technicalFAQs = [
    { label: "I'm having technical difficulties how do I get help?", info: "On the left of your screen, you should see a tab labeled “Technical Support.” From this tab, you will gain access to our Knowledge Base to help find a solution. If you are still having difficulty, submit a help ticket. Technical Support is staffed on weekdays during normal business hours (PST). Requests are answered in the order they are received and may take up to 24 hours for a response." },
  ];

  return <section className={styles.faqPage}>
    <h1>Frequently Asked Questions</h1>
    <div className={classNames(styles.row)}>
      <div className={classNames(styles.column)}><FAQSection header={"Students"} faqs={studentsFAQs} /></div>
    </div>
    <div className={classNames(styles.row)}>
      <hr />
    </div>
    <div className={classNames(styles.row)}>
      <div className={classNames(styles.column)}><FAQSection header={"Schools"} faqs={schoolsFAQs} /></div>
    </div>
    <div className={classNames(styles.row)}>
      <hr />
    </div>
    <div className={classNames(styles.row)}>
      <div className={classNames(styles.column)}><FAQSection header={"Healthcare"} faqs={healthcareFAQs} /></div>
    </div>
    <div className={classNames(styles.row)}>
      <hr />
    </div>
    <div className={classNames(styles.row)}>
      <div className={classNames(styles.column)}><FAQSection header={"Technical"} faqs={technicalFAQs} /></div>
    </div>

  </section>;
}

export default FAQPage;
